import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth0User, StoreRole, StoreUserRole, SubscriptionRole, SubscriptionUserRole } from '../graph/schema-dotnet';
import { RouterLink } from '@angular/router';
import { MatChipSet, MatChip, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'admin-users-role-management',
    templateUrl: './users-roles-management.component.html',
    styleUrls: ['./users-roles-management.component.scss'],
    imports: [RouterLink, MatChipSet, MatChip, MatChipRemove, MatIcon, MatButton]
})
export class UsersRolesManagementComponent<T extends { roles: TRole[], user: Auth0User }, TRole> {
    @Input() users: T[] = [];
    @Input() availableRoles: TRole[] = [];
    @Input() canManagePermissions: boolean;
    @Input() loading: boolean;
    @Output() roleRemoved: EventEmitter<{ email: string, role: TRole }> = new EventEmitter<{ email: string; role: TRole }>();
    @Output() addUserRole: EventEmitter<{ user: T }> = new EventEmitter<any>();
    @Output() addUser: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    canAddRole(authorizedUser: T): boolean {
        if (!this.canManagePermissions)
            return false;

        for (let availableRole of this.availableRoles) {
            if (!authorizedUser.roles.includes(availableRole))
                return true;
        }
        return false;
    }
}
