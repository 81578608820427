import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    DeleteProjectInput,
    DownloadProjectVersionInput,
    Maybe,
    MediaclipMutationDeleteProjectArgs,
    MediaclipMutationDownloadProjectVersionArgs,
    MediaclipMutationInvalidateProjectStorageExternalPhotosCacheArgs,
    MediaclipMutationUpdateProjectVersionContentArgs,
    Project,
    ProjectVersion,
    UpdateProjectVersionContentInput
} from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getProject(projectId: string): Observable<Maybe<Project> | undefined> {
        return this.graphDotnetService.query(`
                query getProject($projectId: UUID!) {
                    project(id: $projectId) {
                        id
                        storeId
                        dateCreatedUtc
                        store {
                            id
                            subscriptionId
                        }
                        user {
                            id
                            storeUserId
                        }
                        currentVersionId
                        currentVersion {
                            id
                            thumbnailUrl
                        }
                        versions {
                            id
                            thumbnailUrl
                            lastModifiedUtc
                        }
                        properties {
                            name
                            value
                        }
                        module
                        externalProductId
                        product {
                            id
                            label
                            thumbnail
                        }
                        theme {
                            id
                            label
                            thumbnail
                        }
                        title
                        sourceSharedProjectId
                        isDeleted
                        variableData{
                            items
                            variableDataResourceUrl
                            uploadedFilename
                        }
                    }
                }
            `,
            { projectId }
        ).pipe(map((result) => result.project));
    }

    getProjectVersion(projectId: string, projectVersionId: number): Observable<Maybe<ProjectVersion> | undefined> {
        return this.graphDotnetService.query(`
                query getProject($projectId: UUID!, $projectVersionId: Int!) {
                    project(id: $projectId) {
                        version(id: $projectVersionId) {
                            id
                            xml
                            lastModifiedUtc
                            photos {
                                __typename
                                ... on MissingPhoto {
                                  urn
                                }
                                ... on Photo {
                                  urn
                                  dateUploadedUtc
                                  dateTakenUtc
                                  width
                                  height
                                  thumbnail
                                  print
                                }
                            }
                        }
                    }
                }
            `,
            { projectId, projectVersionId }
        ).pipe(map((result) => result.project?.version));
    }

    deleteProject(input: DeleteProjectInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteProjectArgs>(`
                mutation deleteProject($input: DeleteProjectInput!) {
                    deleteProject(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.deleteProject));
    }

    updateProjectContent(input: UpdateProjectVersionContentInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateProjectVersionContentArgs>(`
                mutation updateProjectVersionContent($input: UpdateProjectVersionContentInput!) {
                    updateProjectVersionContent(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.deleteProject));
    }

    downloadProjectVersion(input: DownloadProjectVersionInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDownloadProjectVersionArgs>(`
                mutation downloadProjectVersion($input: DownloadProjectVersionInput!) {
                    downloadProjectVersion(input: $input)
                }
            `, { input }
        );
    }

    invalidateProjectStorageExternalPhotosCache(projectVersionId: number) {
        return this.graphDotnetService.mutate<MediaclipMutationInvalidateProjectStorageExternalPhotosCacheArgs>(`
                mutation invalidateProjectStorageExternalPhotosCache($projectVersionId: Int!) {
                    invalidateProjectStorageExternalPhotosCache(projectVersionId: $projectVersionId) {
                        count
                    }
                }
            `, { projectVersionId }
        ).pipe(map(x => x.invalidateProjectStorageExternalPhotosCache));
    }
}
