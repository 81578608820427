import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { StockPhotoSourceConfig } from "app/shared/graph";
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { StockPhotoSourcesService } from './stock-photo-sources.service';

export interface StockPhotoSourceAddDialogData {
    alreadyMapped: string[];
}
export interface StockPhotoSourceAddDialogResult {
    photoSourceId?: string;
}

@Component({
    templateUrl: './stock-photo-source-add-dialog.component.html',
    styleUrls: ['./stock-photo-source-add-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatDialogActions, MatButton, MatDialogClose]
})
export class StockPhotoSourceAddDialogComponent implements OnInit {
    data = inject<StockPhotoSourceAddDialogData>(MAT_DIALOG_DATA);
    dialogRef = inject<MatDialogRef<StockPhotoSourceAddDialogComponent>>(MatDialogRef);
    private readonly stockPhotoSourceService = inject(StockPhotoSourcesService);

    result: StockPhotoSourceAddDialogResult = {
        photoSourceId: undefined,
    };
    stockPhotoSources: StockPhotoSourceConfig[] = [];

    valid() {
        this.dialogRef.close(this.result);
    }

    ngOnInit(): void {
        this.stockPhotoSourceService.list().subscribe(value => {
            this.stockPhotoSources = value.filter(s => this.data.alreadyMapped.indexOf(s.id) === -1);
        });
    }
}
