import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ViewContextService, ViewContextStore } from '../../../navigation/view-context.service';
import { MatCard, MatCardActions, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { SelectableStoreElementComponent } from './selectable-store-element.component';
import { ShortStoreEnvPipe } from '../../../shared/utilities/short-store-env.pipe';

@Component({
    templateUrl: './store-selector-page.component.html',
    styleUrls: ['./store-selector-page.component.scss'],
    imports: [MatCard, MatCardTitle, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, MatAutocompleteTrigger, MatAutocomplete, MatOption, NgClass, MatCardActions, MatButton, SelectableStoreElementComponent, ShortStoreEnvPipe]
})
export class StoreSelectorPageComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly viewContextService = inject(ViewContextService);

    availableContexts: ViewContextStore[];
    filteredContexts: ViewContextStore[];
    contextFilterText: string = '';
    selectedStore?: ViewContextStore;

    ngOnInit(): void {
        this.viewContextService.availableContexts$.subscribe(contexts => {
            this.availableContexts = [...contexts.filter(x => x.type === 'store')] as ViewContextStore[];
            this.updateFilter();
        });
    }

    updateFilter() {
        let filterText = this.contextFilterText.toLowerCase();
        this.filteredContexts = this.availableContexts.filter(c => {
            if (!filterText) {
                return true;
            }
            return c.storeId.toLowerCase().includes(filterText) || c.storeLabel.toLowerCase().includes(filterText);
        });
    }

    selectStore(store: ViewContextStore) {
        if (!store) {
            return;
        }
        const redirectSubPath = localStorage.getItem('routeRedirectAfterStoreSelect') || 'dashboards/overview';
        localStorage.removeItem('routeRedirectAfterStoreSelect');
        this.router.navigateByUrl(`stores/${encodeURIComponent(store.storeId)}/${redirectSubPath}`);
    }

    updateSelectedStore(storeId: string) {
        this.selectedStore = this.availableContexts.find(x => x.storeId == storeId);
    }
}
