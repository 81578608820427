<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Library: ' + libraryOwner"
    [icon]="'assignment'"
>
    @if (library) {
        <div class="content">
            @if (pageStatus === "saveError" || (pageStatus === "loadError" && pageError)) {
                <admin-error [error]="pageError"></admin-error>
            }
            <div class="header">
                <p>
                    <a
                        target="_blank"
                        class="external"
                        href="https://git.mediacliphub.com/{{ gitlabGroup }}/{{ libraryOwner }}/-/commits"
                    >
                        Open GitLab
                        <mat-icon>open_in_new</mat-icon>
                    </a>
                </p>
            </div>
            <mat-accordion>
                @for (branch of library.branches; track branch; let last = $last; let first = $first) {
                    <mat-expansion-panel [expanded]="first">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Branch: {{ branch.name }}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="branch">
                            @if (branch.metadata.version) {
                                <div class="info">
                                    Current version:
                                    <span>
                                        <code adminCopyToClipboard [textToCopy]="branch.metadata.version">{{
                                                branch.metadata.version | truncate: 8 : false : ""
                                            }}</code>
                                    </span>
                                    @if (getBranchNames(library, branch.metadata.version); as gitBranches) {
                                        <mat-chip-set>
                                            @for (gitBranch of gitBranches; track gitBranch) {
                                                <mat-chip>{{ gitBranch }}</mat-chip>
                                            }
                                        </mat-chip-set>
                                    }
                                    <a
                                        target="_blank"
                                        class="external"
                                        href="https://git.mediacliphub.com/{{ gitlabGroup }}/{{
                                            libraryOwner
                                        }}/-/commits/{{ branch.metadata.version }}"
                                    >
                                        Commit history
                                        <mat-icon>open_in_new</mat-icon>
                                    </a>
                                </div>
                            }
                            @if (!branch.metadata.version) {
                                <div class="info">
                                    <mat-icon>info</mat-icon>
                                    This branch has not been deployed yet.
                                </div>
                            }
                            @if (canUpdateLibrary(library, branch)) {
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title> Update library</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form #form="ngForm" class="update-branch-form">
                                            <div class="target-version">
                                                <mat-form-field class="target-version">
                                                    <mat-label>Target version</mat-label>
                                                    <input
                                                        matInput
                                                        [(ngModel)]="branchForms[branch.name].targetVersion"
                                                        name="targetVersion"
                                                        placeholder="master"
                                                        [matAutocomplete]="gitBranches"
                                                        required
                                                    />
                                                    <mat-hint
                                                    >Should be a commit hash (40 characters) or a branch
                                                        name
                                                    </mat-hint
                                                    >
                                                </mat-form-field>
                                                <mat-autocomplete #gitBranches="matAutocomplete">
                                                    @for (gitBranch of library.gitBranches; track gitBranch) {
                                                        <mat-option [value]="gitBranch.name">
                                                            {{ gitBranch.name }} -
                                                            {{ gitBranch.commitHash.substring(0, 8) }}
                                                        </mat-option>
                                                    }
                                                </mat-autocomplete>
                                            </div>
                                            @if (!branch.metadata.doNotValidate) {
                                                <div class="validate-checkbox">
                                                    <mat-checkbox
                                                        name="validateLibrary"
                                                        [(ngModel)]="branchForms[branch.name].validateLibrary"
                                                    >
                                                        Validate library
                                                    </mat-checkbox>
                                                </div>
                                            }
                                            @if (branchForms[branch.name].validateLibrary) {
                                                <div class="validate-options">
                                                    <mat-form-field>
                                                        <mat-label>Default package (for validation)</mat-label>
                                                        <input
                                                            matInput
                                                            [(ngModel)]="branchForms[branch.name].defaultPackage"
                                                            name="defaultPackage"
                                                            [placeholder]="libraryOwner + '/default'"
                                                        />
                                                    </mat-form-field>
                                                    <mat-form-field>
                                                        <mat-label>Culture (for validation)</mat-label>
                                                        <input
                                                            matInput
                                                            [(ngModel)]="branchForms[branch.name].culture"
                                                            name="culture"
                                                            placeholder="en-CA"
                                                        />
                                                    </mat-form-field>
                                                </div>
                                            }
                                            <div class="update-button">
                                                <button
                                                    mat-stroked-button
                                                    color="primary"
                                                    [disabled]="form.invalid || pageStatus === 'saving'"
                                                    (click)="startUpdateLibraryTo(branch, branchForms[branch.name])"
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            }
                            @if (branch.metadata.progress) {
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title>Update logs</mat-card-title>
                                        @if (branch.metadata.progress?.lastUpdateDateUtc) {
                                            <mat-card-subtitle>
                                                Last update:
                                                {{ branch.metadata.progress.lastUpdateDateUtc | date: "medium" }} ({{
                                                    branch.metadata.progress.lastUpdateDateUtc | fromNow
                                                }})
                                            </mat-card-subtitle>
                                        }
                                    </mat-card-header>
                                    <mat-card-content>
                                        <div class="progress">
                                            @if (branch.metadata.progress.max && !branch.metadata.progress.completed) {
                                                <div>
                                                    <mat-progress-bar
                                                        [value]="
                                                            (branch.metadata.progress.value * 100) /
                                                            branch.metadata.progress.max
                                                        "
                                                        mode="determinate"
                                                    ></mat-progress-bar>
                                                    <p class="caption">
                                                        {{ branch.metadata.progress.value }} /
                                                        {{ branch.metadata.progress.max }} -
                                                        {{ branch.metadata.progress.stage }}...
                                                    </p>
                                                </div>
                                            }
                                            @if (!branch.metadata.progress.max && !branch.metadata.progress.completed) {
                                                <div>
                                                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                                                </div>
                                            }
                                            @if (branch.metadata.progress.completed &&
                                            branch.metadata.progress.errorMessage) {
                                                <div class="error">
                                                    <p class="error-notice">
                                                        <mat-icon color="warn">warning</mat-icon>
                                                        Last update failed
                                                    </p>
                                                    <pre>{{ branch.metadata.progress.errorMessage }}</pre>
                                                </div>
                                            }
                                            @if (branch.metadata.progress.hubValidation) {
                                                <div class="validation">
                                                    <h4>
                                                        <mat-icon>report</mat-icon>
                                                        Hub Validation Errors & Warnings
                                                    </h4>
                                                    @if (hasHubValidationError(branch)) {
                                                        <div>
                                                            @for (log of getHubValidationErrors(branch); track log) {
                                                                <div class="log-log">
                                                                    <div>
                                                                        {{ log.level }}
                                                                    </div>
                                                                    <div class="message">
                                                                        {{ log.message }}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    } @else {
                                                        No errors
                                                    }
                                                </div>
                                            }
                                            @if (branch.metadata.progress.validation) {
                                                <div class="validation">
                                                    <h4>
                                                        <mat-icon>report</mat-icon>
                                                        Validation Errors & Warnings
                                                    </h4>
                                                    @if (hasValidationError(branch)) {
                                                        <div>
                                                            @for (validation of getValidationError(branch);
                                                                track validation) {
                                                                <div>
                                                                    {{ validation.level }} -
                                                                    <span class="resource-url">{{
                                                                            validation.url
                                                                        }}</span>
                                                                </div>
                                                                <div class="message">
                                                                    {{ validation.message }}
                                                                </div>
                                                            }
                                                        </div>
                                                    } @else {
                                                        No errors
                                                    }
                                                </div>
                                            }
                                            <div class="logs">
                                                <h4>
                                                    <mat-icon>view_timeline</mat-icon>
                                                    Logs
                                                </h4>
                                                @for (log of branch.metadata.progress.logs; track log) {
                                                    <div class="log">
                                                        <div class="duration">{{ formatDuration(log.duration) }}</div>
                                                        <div class="stage">{{ log.stage }}</div>
                                                    </div>
                                                }
                                                @if (!branch.metadata.progress.completed) {
                                                    <div class="log">{{ branch.metadata.progress.stage }} ...</div>
                                                }
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            }
                            @if (canUnlockLibrary(library, branch)) {
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title>Unlock library</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p>
                                            If the library update is stuck for more than 10 minutes (no change in the
                                            progress at all) then you can try to unlock the library and start a new
                                            upgrade
                                        </p>
                                        <button
                                            mat-stroked-button
                                            [disabled]="pageStatus === 'saving'"
                                            (click)="unlockLibrary(library, branch)"
                                        >
                                            Unlock library
                                        </button>
                                    </mat-card-content>
                                </mat-card>
                            }
                            @if (canManageLibraryMetadata(library, branch)) {
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title> Library configuration</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <form #form="ngForm" class="library-config-form">
                                            <div>
                                                <mat-checkbox
                                                    name="doNotValidate"
                                                    [(ngModel)]="branchMetadataForms[branch.name].doNotValidate"
                                                >
                                                    Do not validate
                                                </mat-checkbox>
                                            </div>
                                            <mat-form-field class="target-version">
                                                <mat-label>Validation default package</mat-label>
                                                <input
                                                    matInput
                                                    [(ngModel)]="
                                                        branchMetadataForms[branch.name].validationDefaultPackage
                                                    "
                                                    name="validationDefaultPackage"
                                                />
                                            </mat-form-field>
                                            <mat-form-field class="target-version">
                                                <mat-label>Validation culture</mat-label>
                                                <input
                                                    matInput
                                                    [(ngModel)]="branchMetadataForms[branch.name].validationCulture"
                                                    name="validationCulture"
                                                    placeholder="en-CA"
                                                />
                                            </mat-form-field>
                                            <admin-save-button
                                                useContent="true"
                                                [pageStatus]="pageStatus"
                                                [disabled]="form.invalid"
                                                (save)="
                                                    updateLibraryMetadata(
                                                        library,
                                                        branch,
                                                        branchMetadataForms[branch.name]
                                                    )
                                                "
                                            >
                                                Save
                                            </admin-save-button>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            }
                            @if (canManageLibraryMetadata(library, branch)) {
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title> Scheduled updates</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p>
                                            Configure when the library needs to be updated automatically. For example,
                                            this feature allows you to deploy the library every day at 9:00 AM.
                                        </p>
                                        <form #form="ngForm" class="schedules-form">
                                            <mat-form-field class="target-version">
                                                <mat-label>Branch</mat-label>
                                                <input
                                                    matInput
                                                    [(ngModel)]="scheduledMetadataForms[branch.name].gitBranch"
                                                    [required]="
                                                        scheduledMetadataForms[branch.name].scheduledUpdates.length > 0
                                                    "
                                                    name="gitBranch"
                                                    [matAutocomplete]="gitBranches"
                                                    placeholder="master"
                                                />
                                            </mat-form-field>
                                            <mat-autocomplete #gitBranches="matAutocomplete">
                                                @for (gitBranch of library.gitBranches; track gitBranch) {
                                                    <mat-option [value]="gitBranch.name">
                                                        {{ gitBranch.name }}
                                                    </mat-option>
                                                }
                                            </mat-autocomplete>
                                            @for (schedule of scheduledMetadataForms[branch.name].scheduledUpdates;
                                                track schedule;
                                                let i = $index) {
                                                <div class="schedule">
                                                    <mat-form-field>
                                                        <mat-label>Time</mat-label>
                                                        <mat-select
                                                            [(ngModel)]="schedule.hourUtc"
                                                            [name]="'hourUtc' + i"
                                                            [disabled]="pageStatus === 'saving'"
                                                            required
                                                        >
                                                            @for (hour of hours; track hour) {
                                                                <mat-option [value]="hour">
                                                                    {{ hour }}:00 UTC
                                                                </mat-option>
                                                            }
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field class="days">
                                                        <mat-label>Days</mat-label>
                                                        <mat-select
                                                            [(ngModel)]="schedule.weekDays"
                                                            [multiple]="true"
                                                            [name]="'weekDays' + i"
                                                            [disabled]="pageStatus === 'saving'"
                                                            required
                                                        >
                                                            @for (weekDay of weekDays; track weekDay) {
                                                                <mat-option [value]="weekDay">
                                                                    {{ weekDay }}
                                                                </mat-option>
                                                            }
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <button
                                                        mat-icon-button
                                                        [disabled]="pageStatus === 'saving'"
                                                        (click)="removeSchedule(branch, i)"
                                                    >
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            }
                                            <button
                                                mat-stroked-button
                                                (click)="addSchedule(branch)"
                                                [disabled]="pageStatus === 'saving'"
                                            >
                                                Add
                                            </button>
                                            <admin-save-button
                                                useContent="true"
                                                [pageStatus]="pageStatus"
                                                [disabled]="form.invalid"
                                                (save)="
                                                    saveSchedule(library, branch, scheduledMetadataForms[branch.name])
                                                "
                                            >
                                                Save
                                            </admin-save-button>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                            }
                        </div>
                    </mat-expansion-panel>
                }
            </mat-accordion>
            @if (canManageLibraries(library)) {
                <button mat-stroked-button (click)="openDeployNewBranch(library)">Deploy new branch</button>
            }
        </div>
    }
</admin-page-simple-content>
