import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { GeneratedImageDefinition, GlobalPermission, SubscriptionPermission } from 'app/shared/graph';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { AdminComponentBase } from "../../../layout/content/admin-component-base";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { GeneratedImagesService } from "./generated-images.service";
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatPrefix, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';

enum Scope {
    global,
    subscription
}

@Component({
    selector: 'admin-generated-images-page',
    templateUrl: './generated-images-page.component.html',
    styleUrls: ['./generated-images-page.component.scss'],
    imports: [PageSimpleContentComponent, MatFormField, MatIcon, MatPrefix, MatLabel, MatInput, FormsModule, MatButton, ErrorComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, RouterLink, CopyToClipboardComponent, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow]
})
export class GeneratedImagesPageContainerComponent extends AdminComponentBase implements OnInit {
    private readonly authorizationService = inject(AuthorizationService);
    private readonly generatedImagesService = inject(GeneratedImagesService);
    private readonly dialog = inject(MatDialog);
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);


    @ViewChild('generatedImageDefinitionsTable', { static: false })
    generatedImageDefinitionsTable: MatTable<GeneratedImageDefinition>;
    generatedImageDefinitions: GeneratedImageDefinition[] = [];
    displayedGeneratedImageDefinitions: GeneratedImageDefinition[] = [];
    displayedColumns: string[] = [
        'displayName',
        'alias',
        'description',
        'actions'
    ];
    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;
    private subscriptionId: string | undefined;
    private scope: Scope;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;

            this.route.params.subscribe(params => {
                if (params.subscriptionId) {
                    this.subscriptionId = params.subscriptionId;
                    this.scope = Scope.subscription;
                } else {
                    this.subscriptionId = undefined;
                    this.scope = Scope.global;
                }

                this.load();
            })
        }));
    }

    private load() {
        if (this.scope == Scope.subscription) {
            this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listForSubscription(this.subscriptionId!), (generatedImageDefinitions) => {
                this.generatedImageDefinitions = generatedImageDefinitions;
                this.updateDisplayedGeneratedImageDefinitions();
            });
        } else {
            this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listForGlobal(), (generatedImageDefinitions) => {
                this.generatedImageDefinitions = generatedImageDefinitions;
                this.updateDisplayedGeneratedImageDefinitions();
            });
        }
    }

    updateFilter() {
        this.updateDisplayedGeneratedImageDefinitions();
    }

    private updateDisplayedGeneratedImageDefinitions() {
        this.displayedGeneratedImageDefinitions = this.generatedImageDefinitions
            .filter(s => this.isGeneratedImageDefinitionVisible(s))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.generatedImageDefinitionsTable.renderRows();
    }

    private isGeneratedImageDefinitionVisible(f: GeneratedImageDefinition) {
        if (this.filter.name) {
            if (f.displayName.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            } else if (f.defaultAlias.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return true;
            }
            return false;
        }
        return true;
    }

    generateImageDefinitionId(i: number, generatedImageDefinition: GeneratedImageDefinition) {
        return generatedImageDefinition.id;
    }

    addGeneratedImage() {
        if (this.scope == Scope.subscription) {
            this.router.navigate(['/subscriptions', this.subscriptionId, 'generated-images', 'create']);
        } else {
            this.router.navigate(['/generated-images/create']);
        }

    }

    canManage(): boolean {
        if (this.scope == Scope.subscription) {
            return this.authorizationContext.hasGlobalPermissions(SubscriptionPermission.ManageGeneratedImages);
        } else {
            return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageGeneratedImages);
        }
    }

    $generatedImageDefinition(source: any): GeneratedImageDefinition {
        return source as GeneratedImageDefinition;
    }
}
