import { Observable, Subscription } from 'rxjs';
import { PageStatus } from './page-status';

export abstract class AdminComponentBase {
    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;
    private rxJsSubscriptions: Subscription = new Subscription();

    protected subscribeWithGenericLoadingErrorHandling<T>(observable: Observable<T>, success?: (r: T) => void) {
        this.pageStatus = PageStatus.loading;
        this.rxJsSubscriptions.add(observable.subscribe({
            next: (result: T) => {
                try {
                    success?.call(this, result);
                    this.setPageStatus(PageStatus.loaded);
                }
                catch (e) {
                    this.setLoadingError(e);
                }
            },
            error: this.setLoadingError.bind(this)
        }));
    }

    protected subscribeWithGenericSavinErrorHandling<T>(observable: Observable<T>,success?: (r: T) => void) {
        this.setPageStatus(PageStatus.saving);
        this.rxJsSubscriptions.add(observable.subscribe({
            next: (result: T) => {
                try {
                    this.setPageStatus(PageStatus.loaded);
                    success?.call(this, result);
                }
                catch (e) {
                    this.setSavingError(e);
                }
            },
            error: this.setSavingError.bind(this)
        }));
    }

    protected registerSubscription(sub: Subscription) {
        this.rxJsSubscriptions.add(sub);
    }

    protected unsubscribeSubscriptions() {
        this.rxJsSubscriptions.unsubscribe()
    }

    protected setPageStatus(status: PageStatus) {
        this.pageError = undefined;
        this.pageStatus = status;
    }

    protected setLoadingError(err: Error) {
        this.pageError = err;
        this.pageStatus = PageStatus.loadError;
    }

    protected setSavingError(err: Error) {
        this.pageError = err;
        this.pageStatus = PageStatus.saveError;
    }
}
