import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ConsolidateUser, ConsolidateUserInput, DeleteUserInput, GraphDotnetService, Maybe, User } from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    protected readonly graphDotnetService = inject(GraphDotnetService);


    getUser(userId: string): Observable<Maybe<User> | undefined> {
        return this.graphDotnetService.query(`
                query getUser($userId: UUID!) {
                    user(id: $userId) {
                        id
                        storeUserId
                        isGuest
                        aliases
                        consolidatedId
                        isDeleted
                        storeId
                        store {
                            id
                            subscriptionId
                        }
                        projects {
                            id
                            isDeleted
                            dateCreatedUtc
                            lastAccessDateUtc
                            currentVersion {
                                id
                                lastModifiedUtc
                                thumbnailUrl
                            }
                            product {
                                id
                            }
                            theme {
                                id
                            }
                        }
                    }
                }
            `, { userId }
        ).pipe(map(x => x.user));
    }

    deleteUser(input: DeleteUserInput) {
        return this.graphDotnetService.mutate(`mutation deleteUser($input: DeleteUserInput!){
                deleteUser(input: $input)
            }`, { input }
        ).pipe(map(x => x.deleteUser));
    }

    consolidateUser(input: ConsolidateUserInput): Observable<ConsolidateUser> {
        return this.graphDotnetService.mutate(`mutation consolidateUser($input: ConsolidateUserInput!){
                consolidateUser(input: $input) {
                    identifiedHubUserId
                }
            }`, { input }
        ).pipe(map(x => x.consolidateUser));
    }
}
