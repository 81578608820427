import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { Fulfiller, FulfillerClient } from 'app/shared/graph';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatError, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

export interface EditFulfillerDialogResult {
    client?: FulfillerClient;
    appKey?: string;
    url?: string;
    libraryPackage?: string;
    fulfillerAppId?: string;
    mappingScript?: string;
}

@Component({
    templateUrl: './edit-fulfiller-dialog.component.html',
    styleUrls: ['../../../shared/utilities/full-screen-dialog.scss', './edit-fulfiller-dialog.component.scss'],
    imports: [MatToolbar, MatIconButton, MatDialogClose, MatIcon, MatButton, FormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatError, SecretInputToggleComponent, MatSuffix, MatHint, CodemirrorModule]
})
export class EditFulfillerDialogComponent {
    data = inject<Fulfiller>(MAT_DIALOG_DATA);
    dialogRef = inject<MatDialogRef<EditFulfillerDialogResult>>(MatDialogRef);


    fulfillerInfo: EditFulfillerDialogResult;

    clients = Object.values(FulfillerClient);

    constructor() {
        const data = this.data;

        this.fulfillerInfo = {
            appKey: '',
            url: data.url,
            client: data.client,
            fulfillerAppId: data.fulfillerAppId,
            libraryPackage: data.libraryPackage,
            mappingScript: data.mappingScript?.script ?? ''
        } as EditFulfillerDialogResult;
    }

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
