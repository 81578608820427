import { Component, Input } from '@angular/core';
import { OrderLineStatusFlags, OrderLineStatusValues } from 'app/shared/graph';
import { StatusColorComponentDirective } from '../../../shared/utilities/status-color.component';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'admin-line-status',
    templateUrl: './line-status.component.html',
    styleUrls: ['./line-status.component.scss'],
    imports: [StatusColorComponentDirective, MatIcon, MatTooltip]
})
export class LineStatusComponent {
    @Input()
    status: OrderLineStatusValues;

    @Input()
    flags?: OrderLineStatusFlags | null;

    constructor() {
    }
}
