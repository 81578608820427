<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Library: Authorized Users'"
    [icon]="'perm_identity'"
>
    @if (libraryWithAuthorizedUsers) {
        <div class="content">
            @if (!canManageUsers()) {
                <p>
                    <em
                    >Please contact <a href="mailto:support@mediacliphub.com">support&#64;mediacliphub.com</a> to
                        add or remove authorized users.</em
                    >
                </p>
            }
            <div>
                <h2>
                    Users with access to the library <code>{{ libraryWithAuthorizedUsers.owner }}</code>
                </h2>
                <admin-users-role-management
                    [users]="libraryWithAuthorizedUsers.authorizedUsers"
                    [availableRoles]="libraryRoles"
                    [loading]="pageStatus !== 'loaded'"
                    [canManagePermissions]="canManageUsers()"
                    (addUser)="openAddLibraryRole(libraryWithAuthorizedUsers)"
                    (addUserRole)="openAddLibraryRole(libraryWithAuthorizedUsers, $event.user)"
                    (roleRemoved)="removeLibraryRole(libraryWithAuthorizedUsers, $event.email, $event.role)"
                >
                </admin-users-role-management>
            </div>
            <div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
            </div>
        </div>
    }
</admin-page-simple-content>
