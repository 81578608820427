import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewContextStore } from '../../../navigation/view-context.service';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatRipple } from '@angular/material/core';
import { MatChip, MatChipSet } from '@angular/material/chips';
import { NgClass } from '@angular/common';
import { ShortStoreEnvPipe } from '../../../shared/utilities/short-store-env.pipe';

@Component({
    selector: 'admin-selectable-store-element',
    templateUrl: './selectable-store-element.component.html',
    styleUrls: ['./selectable-store-element.component.scss'],
    imports: [MatCard, MatRipple, MatCardHeader, MatCardTitle, MatChipSet, MatChip, NgClass, MatCardSubtitle, MatCardContent, ShortStoreEnvPipe]
})
export class SelectableStoreElementComponent {
    @Input() store: ViewContextStore;
    @Input() lastAccessed?: number;
    @Output() selectStore: EventEmitter<ViewContextStore> = new EventEmitter<ViewContextStore>();

    constructor() {
    }
}
