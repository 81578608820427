import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/dialog/confirm-dialog.component';
import { PhotoUpscalerType, PhotoUpscalingConfiguration } from 'app/shared/graph';
import { AdminComponentBase, PageSimpleContentComponent } from '../../../layout/content';
import { ActivatedRoute } from '@angular/router';
import { StorePhotosUpscalingService } from './store-photos-upscaling.service';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    selector: 'admin-store-photos-upscaling',
    templateUrl: './store-photos-upscaling-page.component.html',
    styleUrls: ['./store-photos-upscaling-page.component.scss'],
    imports: [PageSimpleContentComponent, MatIcon, MatButton, FormsModule, MatCheckbox, MatFormField, MatLabel, MatSelect, MatOption, MatInput, SecretInputToggleComponent, MatSuffix, MatHint, SaveButtonComponent, ErrorComponent]
})
export class StorePhotosUpscalingPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly storePhotosUpscalingService = inject(StorePhotosUpscalingService);
    private readonly matDialog = inject(MatDialog);

    private readonly defaultConfig: PhotoUpscalingConfiguration = {
        enabled: false,
        type: PhotoUpscalerType.Picsart
    };
    private storeId: string;
    public apiKey: string | undefined;

    public enableUpscalingByProduct: boolean = true;
    public hasConfigDefined: boolean;
    public currentUpscalingConfig: PhotoUpscalingConfiguration = this.defaultConfig;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(async params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storePhotosUpscalingService.getConfig(this.storeId), (upscalingConfig) => {
                this.hasConfigDefined = !!upscalingConfig;
                if (upscalingConfig) {
                    this.computeMinDPIState(upscalingConfig);
                    this.currentUpscalingConfig = upscalingConfig;
                }
            });
        }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    computeMinDPIState(upscalingConfig: PhotoUpscalingConfiguration) {
        if (upscalingConfig.minDpiUpscaleConfiguration == -1) {
            upscalingConfig.minDpiUpscaleConfiguration = 300;
            this.enableUpscalingByProduct = true;
        } else if (upscalingConfig.minDpiUpscaleConfiguration != null) {
            if (upscalingConfig.minDpiUpscaleConfiguration == 0) {
                upscalingConfig.minDpiUpscaleConfiguration = 300;
            }
            this.enableUpscalingByProduct = false;
        } else {
            upscalingConfig.minDpiUpscaleConfiguration = 300;
            this.enableUpscalingByProduct = false;
        }
    }

    deleteConfig() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Delete configuration',
                message: `Do you really want to delete that configuration ?`,
                options: {
                    yes: {
                        label: 'Delete',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storePhotosUpscalingService.deleteConfiguration({ storeId: this.storeId }), () => {
                this.hasConfigDefined = false;
                this.apiKey = undefined;
                this.currentUpscalingConfig = this.defaultConfig;
            });
        });
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.storePhotosUpscalingService.updateConfiguration({
            ...this.currentUpscalingConfig,
            enableMinDpiUpscale: !this.enableUpscalingByProduct,
            upscaleOnlyProduct: this.enableUpscalingByProduct,
            storeId: this.storeId,
            apiKey: this.apiKey ? this.apiKey : undefined
        }), () => {
            this.hasConfigDefined = true;
        });
    }

    protected readonly UpscalerType = PhotoUpscalerType;

    selectedUpscaleOption($event: MatCheckboxChange) {
        this.enableUpscalingByProduct = $event.checked;
    }
}
