import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CreateStoreFulfillerInput,
    FulfillerLog,
    GraphDotnetService,
    Maybe,
    MediaclipMutationCreateStoreFulfillerArgs,
    MediaclipMutationUpdateStoreFulfillerArgs,
    Store,
    StoreFulfiller,
    UpdateStoreFulfillerInput
} from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class StoreFulfillersService {
    private readonly graphDotnetService = inject(GraphDotnetService);

    getStoreFulfiller(storeId: string, fulfillerId: string): Observable<Maybe<StoreFulfiller>> {
        return this.graphDotnetService.query(`
                query getStoreFulfiller($storeId: String!, $fulfillerId: String!) {
                    store(id: $storeId) {
                        fulfiller(id: $fulfillerId) {
                            store {
                                id
                            }
                            fulfiller {
                                id
                                form {
                                    documentationLink
                                    fields {
                                        key
                                        label
                                        type
                                        required
                                        description
                                        sampleValue
                                    }
                                }
                            }
                            isDefault
                            configs {
                                name
                                value
                            }
                        }
                    }
                }
            `,
            { storeId, fulfillerId }
        ).pipe(map(m => m.store.fulfiller || null));
    }

    getStoreFulfillers(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(`
                query getStoreFulfillers($storeId: String!) {
                    store(id: $storeId) {
                        id
                        subscriptionId
                        fulfillers {
                            fulfillerId
                            isDefault
                        }
                    }
                }
            `,
            { storeId }
        ).pipe(map(x => x.store));
    }

    updateStoreFulfiller(input: UpdateStoreFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreFulfillerArgs>(`
                mutation updateStoreFulfiller($input: UpdateStoreFulfillerInput!) {
                    updateStoreFulfiller(input: $input)
                }
            `,
            { input }
        ).pipe(map(e => e.updateStoreFulfiller));
    }

    createStoreFulfiller(input: CreateStoreFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateStoreFulfillerArgs>(`
                mutation createStoreFulfiller($input: CreateStoreFulfillerInput!) {
                    createStoreFulfiller(input: $input)
                }
            `,
            { input }
        ).pipe(map(e => e.createStoreFulfiller));
    }

    getStoreFulfillerLogs(storeId: string, fulfillerId: string): Observable<FulfillerLog[]> {
        return this.graphDotnetService.query(`
                    query getFulfillerLogs($storeId: String!, $fulfillerId: String!) {
                        fulfillerLogs(storeId: $storeId, fulfillerId: $fulfillerId) {
                            dateCreatedUtc
                            fulfiller {
                                id
                            }
                            store {
                                id
                            }
                            orderId
                            type
                            data
                        }
                    }
                `,
            { storeId, fulfillerId }
        ).pipe(map((q) => q.fulfillerLogs));
    }
}
