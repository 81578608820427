import { ChartData } from 'chart.js';
import { VolumeTimelineReportRow } from 'app/shared/graph';

export const convert = (rows: VolumeTimelineReportRow[]): ChartData<'line'> => {
    if (!rows) {
        return { datasets: [] };
    }

    const labels = rows
        .map((r) => new Date(r.timestampUtc))
        .map((d, i) => (i === 0 || d.getDate() === 1 ? d.toLocaleDateString(undefined, { day: 'numeric', month: 'short' }) : d.toLocaleDateString(undefined, { day: 'numeric' })));

    return {
        datasets: [
            {
                label: 'Orders',
                data: rows.map((r) => r.orderCount),
                fill: 'start',
                borderColor: '#42a5f5cc',
                backgroundColor: '#42a5f5cc',
                pointBackgroundColor: '#1e88e5cc',
                pointHoverBackgroundColor: '#1e88e5cc',
                pointBorderColor: '#ffffffcc',
                pointHoverBorderColor: '#ffffffcc'
            },
            {
                label: 'Projects',
                data: rows.map((r) => r.projectCount),
                fill: 'start',
                borderColor: '#42a5f566',
                backgroundColor: '#42a5f566',
                pointBackgroundColor: '#1e88e566',
                pointHoverBackgroundColor: '#1e88e566',
                pointBorderColor: '#ffffff66',
                pointHoverBorderColor: '#ffffff66'
            },
        ],
        labels,
    };
};
