<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Anomalies'"
    [icon]="'report_problem'"
>
    <div class="content">
        <div>
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>
        @if (selection.isEmpty()) {
            <mat-toolbar>
                <button mat-button color="primary" (click)="reload()" [disabled]="pageStatus === 'saving'">
                    RELOAD
                </button>
                <button mat-button color="primary" (click)="retryAll()" [disabled]="pageStatus === 'saving'">
                    RETRY ALL
                </button>
            </mat-toolbar>
        }
        @if (!selection.isEmpty()) {
            <mat-toolbar class="selected-toolbar">
                {{ selection.selected.length }} item(s) selected
                <button mat-icon-button [matMenuTriggerFor]="menuSelection">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-toolbar>
        }
        <mat-menu #menuSelection="matMenu">
            <button mat-menu-item (click)="retrySelection(this.selection.selected)">
                <mat-icon>refresh</mat-icon>
                Retry
            </button>
            <button mat-menu-item (click)="openChangeStatus(this.selection.selected)">
                <mat-icon>call_split</mat-icon>
                Change status
            </button>
        </mat-menu>
        <table
            class="anomalies-table"
            matSort
            [matSortActive]="currentSort?.active"
            [matSortDirection]="currentSort?.direction"
            (matSortChange)="sortData($event)"
            mat-table
            [dataSource]="sortedAnomalies"
        >
            <mat-header-row
                *matHeaderRowDef="['select', 'store', 'order', 'status', 'dateCreatedUtc']"
            ></mat-header-row>
            <mat-row
                *matRowDef="let _; columns: ['select', 'store', 'order', 'status', 'dateCreatedUtc']"
            ></mat-row>

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (change)="$event ? toggleAllRows(sortedAnomalies.length, sortedAnomalies) : null"
                        [checked]="selection.hasValue() && isAllSelected(sortedAnomalies.length)"
                        [disabled]="pageStatus === 'saving'"
                        [indeterminate]="selection.hasValue() && !isAllSelected(sortedAnomalies.length)"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let anomaly" (click)="selectRow($event, anomaly, sortedAnomalies)">
                    <mat-checkbox
                        (mousedown)="$event.shiftKey ? $event.preventDefault() : null"
                        (click)="
                            $event.shiftKey ? selectRow($event, anomaly, sortedAnomalies) : $event.stopPropagation()
                        "
                        (change)="selectSingleRow(anomaly)"
                        [disabled]="pageStatus === 'saving'"
                        [checked]="selection.isSelected(anomaly)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="store">
                <mat-header-cell *matHeaderCellDef mat-sort-header="storeId" sortActionDescription="Sort by store">
                    Store
                </mat-header-cell>
                <mat-cell *matCellDef="let anomaly">
                    <a
                        [routerLink]="
                            '/stores/' +
                            $orderLine(anomaly).store.id +
                            '/orders/' +
                            $orderLine(anomaly).order.storeOrderId +
                            '/lines/' +
                            $orderLine(anomaly).storeOrderLineNumber
                        "
                    >
                        {{ $orderLine(anomaly).store.id }}
                    </a>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="order">
                <mat-header-cell *matHeaderCellDef>Order Id</mat-header-cell>
                <mat-cell *matCellDef="let anomaly">
                    <div class="order-cell">
                        <div>
                            Hub Order Id: <code adminCopyToClipboard>{{ $orderLine(anomaly).order.id }}</code>
                        </div>
                        <div>
                            Store Order Id:
                            <code adminCopyToClipboard>{{ $orderLine(anomaly).order.storeOrderId }}</code>
                        </div>
                        <div>
                            Line Id: <code adminCopyToClipboard>{{ $orderLine(anomaly).id }}</code>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sort by status">
                    Status
                </mat-header-cell>
                <mat-cell *matCellDef="let anomaly">
                    <admin-line-status
                        [status]="$orderLine(anomaly).status.value"
                        [flags]="$orderLine(anomaly).status.flags"
                    ></admin-line-status>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateCreatedUtc">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="dateCreatedUtc"
                    sortActionDescription="Sort by creation date"
                >Created
                </mat-header-cell>
                <mat-cell *matCellDef="let anomaly" class="date-cell">
                    <div class="full-date">{{ $orderLine(anomaly).dateCreatedUtc | date: "mediumDate" }}</div>
                    <div class="mat-caption">{{ $orderLine(anomaly).dateCreatedUtc | fromNow }}</div>
                </mat-cell>
            </ng-container>
        </table>
    </div>
</admin-page-simple-content>
