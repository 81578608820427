import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
    ArchivedOrderLineLog,
    AuthenticatedUser,
    GlobalPermission,
    Maybe,
    OrderLine,
    OrderLineLogUnion,
    OrderLineStatusFlags,
    OrderLineStatusValues,
    OrderLineUnion,
    OrderUnion,
    StorePermission
} from 'app/shared/graph';
import { AuthenticationService, AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ChangeStatusDialogComponent, ChangeStatusDialogData } from './change-status-dialog.component';
import { DisplayCodeDialogComponent } from './display-code-dialog.component';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { ViewContextService } from '../../../navigation/view-context.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatIcon } from '@angular/material/icon';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { DatePipe } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { LineStatusComponent } from './line-status.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CurrencyComponent } from '../../../shared/utilities/currency.component';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { LinkifyPipe } from '../../../shared/utilities/linkify.pipe';
import { RemoveHtmlPipe } from '../../../shared/utilities/remove-html.pipe';
import { OrdersService } from './orders.service';

@Component({
    templateUrl: './order-page.component.html',
    styleUrls: ['./order-page.component.scss'],
    imports: [PageSimpleContentComponent, MatIcon, CopyToClipboardComponent, MatButton, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatDivider, MatProgressSpinner, MatFormField, MatSelect, MatSelectTrigger, MatOption, LineStatusComponent, RouterLink, MatAnchor, ErrorComponent, MatSuffix, CurrencyComponent, MatTable, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatTooltip, MatProgressBar, DatePipe, FromNowPipe, LinkifyPipe, RemoveHtmlPipe]
})
export class OrderPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly authenticationService = inject(AuthenticationService);
    private readonly authService = inject(AuthorizationService);
    private readonly ordersService = inject(OrdersService);
    private readonly dialog = inject(MatDialog);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly viewContextService = inject(ViewContextService);
    private readonly snackBar = inject(MatSnackBar);

    subscription: Subscription = new Subscription();
    authContext: AuthorizationContext;

    order?: OrderUnion;
    selectedLine?: OrderLineUnion;
    lines: OrderLineUnion[] = [];
    logs?: OrderLineLogUnion[] | ArchivedOrderLineLog[];

    loadingLine = false;

    user$: Observable<AuthenticatedUser>;
    loadingLogs = false;
    loadingLogError?: Error;
    loadingLogsSubscription?: Subscription;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            if (params.orderId) {
                this.subscribeWithGenericLoadingErrorHandling(this.ordersService.getOrder(params.orderId), (order) => {
                    if (order) {
                        this.viewContextService.selectStore(order.store.id);
                        this.router.navigate(['/stores', order.store.id, 'orders', order.storeOrderId], {
                            replaceUrl: true
                        });
                    }
                });
            } else {
                let storeLineNumber = (+params.storeLineNumber) || undefined;
                let storeOrderId = params.storeOrderId;
                let storeId = params.storeId;
                if (this.order && this.order.store.id == storeId && this.order.storeOrderId == storeOrderId) {
                    this.selectLine(storeLineNumber);
                    return;
                }
                this.subscribeWithGenericLoadingErrorHandling(this.ordersService.getStoreOrder(storeId, storeOrderId), (order) => {
                    this.setOrder(order, storeLineNumber);
                });
            }
        }));
        this.user$ = this.authenticationService.authenticatedUser$;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.loadingLogsSubscription?.unsubscribe();
    }

    hasPermission(order: OrderUnion, permission: StorePermission | string) {
        return this.authContext.hasStorePermissions(order.store.id, order.store.subscriptionId, permission as StorePermission);
    }

    hasAllowSetOrderToAnyStatus() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.AllowSetOrderToAnyStatus);
    }

    setOrder(order: Maybe<OrderUnion> | undefined, selectedStoreLineNumber?: number) {
        this.order = order ?? undefined;
        if (order) {
            this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
            this.lines = order.lines;
            this.selectLine(selectedStoreLineNumber);
        }
    }

    selectLine(storeLineNumber?: number) {
        if (!this.order) {
            return;
        }

        let line = this.lines.find(x => x.storeOrderLineNumber === storeLineNumber);
        if (!line) {
            line = this.lines[0];
        }
        if (line.id === this.selectedLine?.id) {
            return;
        }

        this.loadingLine = true;
        this.ordersService.getLine(line.id).subscribe({
            next: line => {
                this.loadingLine = false;
                this.selectedLine = line!;
            },
            error: err => {
                this.loadingLine = false;
                this.setLoadingError(err);
            }
        });

        this.router.navigate(['/stores', this.order.store.id, 'orders', this.order.storeOrderId, 'lines', line.storeOrderLineNumber], {
            replaceUrl: storeLineNumber === undefined
        });
        this.logs = undefined;
        this.loadingLogsSubscription?.unsubscribe();
        this.loadingLogsSubscription = undefined;
        this.loadingLogs = false;
    }

    isOrderRetryable(order: OrderUnion): boolean {
        if (order.__typename !== 'Order') {
            return false;
        }
        const retryableStatuses = [OrderLineStatusValues.Error, OrderLineStatusValues.RenderingCompleted, OrderLineStatusValues.RenderingDelayed];
        return order.lines
            .filter((line) => line)
            .some((line) => line.status && retryableStatuses.indexOf(line.status.value) > -1);
    }

    isRenderInProgress(order: OrderUnion): boolean {
        if (order.__typename !== 'Order') {
            return false;
        }
        return order.lines.filter(
            (line) =>
                line.status &&
                (line.status.value === OrderLineStatusValues.RenderingInProgress ||
                    line.status.value === OrderLineStatusValues.SentToRendering)
        ).length > 0;
    }

    getLineSequenceNumber(lines: OrderLine[], current: OrderLine) {
        return lines.findIndex((line) => line.id === current.id) + 1;
    }

    refresh(order: OrderUnion, lineId: string): void {
        this.loadingLine = true;
        this.subscribeWithGenericLoadingErrorHandling(forkJoin([
            this.ordersService.getStoreOrder(order.store.id, order.storeOrderId),
            this.ordersService.getLine(lineId)
        ]), ([order, line]) => {
            this.loadingLine = false;
            this.order = order ?? undefined;
            if (order) {
                this.lines = order.lines;
            } else {
                this.lines = [];
            }
            if (line) {
                this.selectedLine = line;
            } else {
                this.selectedLine = undefined;
            }
        });
        if (this.logs !== undefined) {
            this.loadLogs(lineId);
        }
    }

    retry(orderId: string): void {
        this.subscribeWithGenericSavinErrorHandling(this.ordersService.retryFailedOrderLines(orderId), () => {
            if (this.selectedLine) {
                this.refresh(this.order!, this.selectedLine.id);
            }
        });
    }

    updateLineStatus(line: OrderLine, isSuperAdmin: boolean): void {
        let email = '?';
        this.user$
            .pipe(take(1))
            .subscribe((user) => (email = user.email || '?'))
            .unsubscribe();

        const dialogRef = this.dialog.open<ChangeStatusDialogComponent, ChangeStatusDialogData>(
            ChangeStatusDialogComponent,
            {
                maxWidth: '400px',
                data: {
                    lineId: line.id,
                    status: line.status.value,
                    details: `Changed by ${email}`,
                    actionRequired: false,
                    isSuperAdmin
                }
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.ordersService.setOrderLineStatus(
                result.lineId,
                result.status,
                result.details,
                result.actionRequired ? OrderLineStatusFlags.StoreActionRequired : OrderLineStatusFlags.None
            ), () => {
                this.refresh(this.order!, result.lineId);
            });
        });
    }

    canViewOriginalCheckoutJson(order: OrderUnion): boolean {
        return order.__typename === 'Order' && !!order.originalCheckoutJson && this.authContext.hasStorePermissions(order.store.id, order.store.subscriptionId, StorePermission.ManageOrders);
    }

    canManageOrderData(order: OrderUnion): boolean {
        return order.__typename === 'Order' && !!order.rawData.value && this.authContext.hasStorePermissions(order.store.id, order.store.subscriptionId, StorePermission.ManageOrderData);
    }

    startDesigner(line: OrderLine) {
        this.router.navigate(['lines', line.fulfillerHubLineNumber, 'edit'], { relativeTo: this.route.parent });
    }

    openApplicationInsightsForLog(url: string) {
        window.open(url);
    }

    showOriginalCheckoutJson(order?: OrderUnion | null) {
        if (order?.__typename !== 'Order') {
            return;
        }
        if (!order.originalCheckoutJson) {
            return;
        }
        this.dialog.open(DisplayCodeDialogComponent, {
            width: '80vw',
            height: '80vh',
            data: { text: JSON.stringify(JSON.parse(order.originalCheckoutJson), null, 4), mimeType: 'application/json' }
        });
    }

    showRawCheckoutJson(order?: OrderUnion | null) {
        if (order?.__typename !== 'Order') {
            return;
        }
        if (!order.rawData.value) {
            return;
        }
        let dialogRef = this.dialog.open(DisplayCodeDialogComponent, {
            width: '80vw',
            height: '80vh',
            data: { text: JSON.stringify(JSON.parse(order.rawData.value), null, 4), mimeType: 'application/json', editable: true }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.ordersService.updateOrderRawData({
                orderId: order.id,
                orderData: result.text
            }).subscribe({
                next: () => {
                    this.snackBar.open('Order raw data updated', undefined, { duration: 4000 });
                    if (this.selectedLine) {
                        this.refresh(order, this.selectedLine.id);
                    }
                },
                error: e => {
                    console.error(e);
                    this.snackBar.open('Failed to update raw data');
                }
            });
        });
    }

    showBackupRawCheckoutJson(order?: OrderUnion | null) {
        if (order?.__typename !== 'Order') {
            return;
        }
        if (!order.rawData.backupValue) {
            return;
        }
        this.dialog.open(DisplayCodeDialogComponent, {
            width: '80vw',
            height: '80vh',
            data: { text: JSON.stringify(JSON.parse(order.rawData.backupValue), null, 4), mimeType: 'application/json' }
        });
    }

    loadLogs(lineId: string) {
        this.loadingLogs = true;
        this.loadingLogError = undefined;
        this.loadingLogsSubscription = this.ordersService.getLineLogs(lineId).subscribe({
            next: logs => {
                this.loadingLogs = false;
                this.logs = logs;
            },
            error: err => {
                this.loadingLogs = false;
                this.loadingLogError = err;
            }
        });
    }

    canForceRerender(order: OrderUnion, line: OrderLineUnion) {
        if (line.__typename === 'ArchivedOrderLine') {
            return false;
        }
        return this.hasPermission(order, StorePermission.RerenderOrders) && (
            line.status?.value === 'AvailableForDownload'
            || line.status?.value === 'SentToFulfillment'
            || line.status?.value === 'WaitingForFulfillment'
            || line.status?.value === 'Shipped'
            || line.status?.value === 'Printed'
        );
    }

    forceRerender(order: OrderUnion, lineId: string): void {
        this.confirmDialogService.confirm('Force re-render', 'Rerender and resubmit the order to the fulfiller ?').subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.ordersService.forceRerenderOrderLine(lineId), () => {
                this.refresh(order, lineId);
            });
        });
    }

    canViewApplicationInsights() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.ReadApplicationInsightsLogs);
    }
}

