<admin-page-simple-content
    id="store-shared-project"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Shared Project: ' + sharedProjectId"
    [icon]="'save'"
>
    @if (pageStatus === "saveError" && pageError) {
        <admin-error [error]="pageError"></admin-error>
    }
    @if (sharedProject) {
        <div class="content">
            <div>
                <h2>Actions</h2>
                <div class="actions">
                    @if (canReadProject(sharedProject) && !sharedProject.projectStorageId) {
                        <button mat-stroked-button [routerLink]="['/projects', sharedProject.projectId]">
                            <mat-icon>code</mat-icon>
                            Open project
                        </button>
                    }
                    @if (canReadProject(sharedProject) && sharedProject.projectStorageId) {
                        <button
                            mat-stroked-button
                            [routerLink]="[
                                '/projects',
                                sharedProject.projectId,
                                'versions',
                                sharedProject.projectStorageId,
                            ]"
                        >
                            <mat-icon>code</mat-icon>
                            Open project
                        </button>
                    }
                    @if (!hasExpired() && canManageProject(sharedProject)) {
                        <button
                            mat-stroked-button
                            (click)="openRevokeSharing(sharedProject)"
                            [disabled]="pageStatus === 'saving'"
                            color="warn"
                        >
                            <mat-icon>delete</mat-icon>
                            Revoke
                        </button>
                    }
                </div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
                <h2>Information</h2>
                <table>
                    <tr>
                        <td>Project ID</td>
                        <td>
                            <code adminCopyToClipboard>{{ sharedProject.projectId }}</code>
                        </td>
                    </tr>
                    <tr>
                        <td>Project Version ID</td>
                        <td>
                            <code adminCopyToClipboard>{{ sharedProject.projectStorageId }}</code>
                        </td>
                    </tr>
                    <tr>
                        <td>Store ID</td>
                        <td>
                            <code adminCopyToClipboard>{{ sharedProject.project.storeId }}</code>
                        </td>
                    </tr>
                    <tr>
                        <td>Sharing Mode</td>
                        <td>
                            <code>{{ sharedProject.sharingMode }}</code>
                        </td>
                    </tr>
                    <tr>
                        <td>Sharing Date</td>
                        <td>
                            <code adminCopyToClipboard>{{ sharedProject.shareDate | date: "medium" }}</code>
                        </td>
                    </tr>
                    <tr>
                        <td>Expiration</td>
                        <td class="expiration">
                            @if (sharedProject.expiration) {
                                <div>
                                    <code adminCopyToClipboard>{{ sharedProject.expiration | date: "medium" }}</code>
                                    @if (hasExpired()) {
                                        <span class="expiration-warning"
                                        ><mat-icon color="warn">warning</mat-icon> The sharing has expired</span
                                        >
                                    }
                                </div>
                            }
                            @if (!sharedProject.expiration) {
                                <div>
                                    <span>No expiration</span>
                                </div>
                            }
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    }
</admin-page-simple-content>
