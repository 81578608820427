import { Component, inject } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface LinkFulfillerAppDialogResult {
    appId: string;
}

@Component({
    templateUrl: './link-fulfiller-app-dialog.component.html',
    styleUrls: ['./link-fulfiller-app-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class LinkFulfillerAppDialogComponent {
    dialogRef = inject<MatDialogRef<LinkFulfillerAppDialogResult>>(MatDialogRef);

    fulfillerInfo: LinkFulfillerAppDialogResult = {
        appId: ''
    };

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
