import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Library,
    MediaclipMutationReleaseLibraryLockArgs,
    MediaclipMutationStartUpdateLibraryArgs,
    MediaclipMutationUpdateLibraryMetadataArgs,
    MediaclipMutationUpdateLibraryUpdateSchedulesArgs,
    ReleaseLibraryLockInput,
    StartUpdateLibrary,
    StartUpdateLibraryInput,
    UpdateLibraryMetadataInput,
    UpdateLibraryScheduleUpdatesInput
} from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class LibraryUpdateService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getLibraries(): Observable<Library[]> {
        return this.graphDotnetService.query(`query {
        libraries {
            owner
            branches {
              name
              metadata {
                version
              }
            }
          }
        }`).pipe(map(q => q.libraries));
    }

    getLibraryStatus(owner: string): Observable<Library> {
        return this.graphDotnetService.query(`
        query loadLibrary($owner: String!) {
            library(owner: $owner) {
                owner
                gitBranches {
                    name
                    commitHash
                }
                branches {
                  name
                  scheduledUpdates {
                    gitBranch
                    schedules {
                      hourUtc
                      weekDays
                    }
                  }
                  metadata {
                    version
                    doNotValidate
                    validationDefaultPackage
                    validationCulture
                    progress {
                      progressSignalRUrl
                      updateId
                      stage
                      lastUpdateDateUtc
                      completed
                      max
                      value
                      errorMessage
                      totalDuration
                      logs {
                        stage
                        duration
                      }
                      hubValidation {
                        logs {
                          level
                          message
                        }
                      }
                      validation {
                        entries {
                          level
                          category
                          package
                          url
                          message
                        }
                      }
                    }
                  }
                }
              }
            }`, { owner })
            .pipe(map(q => q.library));
    }

    startUpdateLibrary(input: StartUpdateLibraryInput): Observable<StartUpdateLibrary> {
        return this.graphDotnetService.mutate<MediaclipMutationStartUpdateLibraryArgs>(`
          mutation startUpdateLibrary($input: StartUpdateLibraryInput!) {
            startUpdateLibrary(input: $input) {
              updateId
              message
              progressPollingEndpointUrl
              progressSignalRUrl
            }
          }`, { input }
        ).pipe(map(x => x.startUpdateLibrary));
    }

    releaseLibraryLock(input: ReleaseLibraryLockInput): Observable<StartUpdateLibrary> {
        return this.graphDotnetService.mutate<MediaclipMutationReleaseLibraryLockArgs>(`
          mutation releaseLibraryLock($input: ReleaseLibraryLockInput!) {
            releaseLibraryLock(input: $input)
          }`, { input }
        ).pipe(map(x => x.releaseLibraryLock));
    }

    updateLibraryMetadata(input: UpdateLibraryMetadataInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateLibraryMetadataArgs>(`
          mutation updateLibraryMetadata($input: UpdateLibraryMetadataInput!) {
            updateLibraryMetadata(input: $input)
          }`, { input }
        ).pipe(map(x => x.updateLibraryMetadata));
    }

    updateLibraryUpdateSchedules(input: UpdateLibraryScheduleUpdatesInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateLibraryUpdateSchedulesArgs>(`
          mutation updateLibraryUpdateSchedules($input: UpdateLibraryScheduleUpdatesInput!) {
            updateLibraryUpdateSchedules(input: $input)
          }`, { input }
        ).pipe(map(x => x.updateLibraryUpdateSchedules));
    }
}
