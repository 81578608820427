import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WidgetUsageTimelineComponent } from './widget-usage-timeline.component';

@Component({
    templateUrl: './dashboards-overview-page.component.html',
    styleUrls: ['./dashboards-overview-page.component.scss'],
    imports: [WidgetUsageTimelineComponent]
})
export class DashboardsOverviewPageContainerComponent {
    private readonly route = inject(ActivatedRoute);

    storeId: string;

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
    }
}
