import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FormsModule, NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { FulfillerService } from './fulfiller.service';

export interface AddStoreFulfillerDialogResult {
    fulfillerId: string;
}

export interface AddStoreFulfillerDialogData {
    usedFulfillerId: string[];
}

@Component({
    selector: 'admin-add-store-fulfiller-dialog',
    templateUrl: './add-store-fulfiller-dialog.component.html',
    styleUrls: ['./add-store-fulfiller-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatAutocompleteTrigger, MatAutocomplete, MatOption, MatDialogActions, MatButton, MatDialogClose, AsyncPipe]
})
export class AddStoreFulfillerDialogComponent implements OnInit {
    private readonly dialogRef = inject<MatDialogRef<AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogResult>>(MatDialogRef);
    private readonly fulfillerService = inject(FulfillerService);
    readonly data = inject<AddStoreFulfillerDialogData>(MAT_DIALOG_DATA);

    fulfillerId?: string;
    fulfillerIds: string[] = [];
    @ViewChild('form')
    form: NgForm;

    filteredFulfillerIds: BehaviorSubject<string[]> = new BehaviorSubject([]);

    ngOnInit(): void {
        this.fulfillerService.list().subscribe(s => {
            this.fulfillerIds = s.map(s => s.id).filter(fulfillerId => !this.data.usedFulfillerId.includes(fulfillerId))
                .sort((a, b) => a.localeCompare(b));
            this.form.controls['fulfillerId'].valueChanges.subscribe(filter => {
                this.filteredFulfillerIds.next(this.fulfillerIds.filter(x => x.toLowerCase().includes(filter.toLowerCase())));
            });
        });
    }

    valid() {
        if (!this.fulfillerId) {
            return;
        }

        this.dialogRef.close({
            fulfillerId: this.fulfillerId
        });
    }

    isValueValid(): boolean {
        if (!this.fulfillerId) {
            return false;
        }
        return this.fulfillerIds.includes(this.fulfillerId);
    }
}
