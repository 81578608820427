import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { GlobalPermission, Library, LibraryRole, LibraryUserRole } from 'app/shared/graph';
import { AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult } from 'app/shared/permission/add-authorized-user-dialog.component';
import { PageStatus } from '../../../layout/content/page-status';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { UsersRolesManagementComponent } from 'app/shared/permission/users-roles-management.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { LibraryPermissionService } from './library-permission.service';

@Component({
    templateUrl: './library-authorized-user.component.html',
    styleUrls: ['./library-authorized-user.component.scss'],
    imports: [PageSimpleContentComponent, UsersRolesManagementComponent, ErrorComponent]
})
export class LibraryAuthorizedUserComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly route = inject(ActivatedRoute);
    private readonly libraryPermissionService = inject(LibraryPermissionService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly dialog = inject(MatDialog);

    libraryOwner: string;
    libraryRoles: LibraryRole[] = [];
    libraryWithAuthorizedUsers?: Library;
    authContext: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.libraryOwner = params.libraryOwner;
                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAvailableRoles(),
                    this.libraryPermissionService.getAuthorizedUsers(this.libraryOwner)
                ]), ([availableRoles, libraryWithAuthorizedUsers]) => {
                    this.libraryRoles = availableRoles.libraryRoles;
                    this.libraryWithAuthorizedUsers = libraryWithAuthorizedUsers;
                });
            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageUsers() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    openAddLibraryRole(library: Library, user?: LibraryUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<LibraryRole>, AddAuthorizedUserDialogData<LibraryRole>, AddAuthorizedUserDialogResult<LibraryRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.libraryRoles.filter(role => !user || !user.roles.includes(role)),
                    label: library.owner
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRoleOnLibrary({
                email: result.email,
                libraryAccess: { id: library.owner, roles: [result.role] }
            }), user => {
                let existingUser = library.authorizedUsers.find(e => e.user?.email === user.email);
                if (existingUser) {
                    existingUser.roles.push(result.role);
                } else {
                    library.authorizedUsers.push({
                        user,
                        roles: [result.role]
                    });
                }
            });
        });
    }

    removeLibraryRole(library: Library, email: string, role: LibraryRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.pageStatus = PageStatus.saving;
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                libraries: [{ id: library.owner, roles: [role] }]
            }), () => {
                let user = library.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }
}
