import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PendingProjectExpirationConfig, ProjectExpirationConfig, Subscription, SubscriptionPermission } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { SubscriptionsService } from './subscriptions.service';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { MatDialog } from '@angular/material/dialog';
import {
    UpdateSubscriptionProjectExpirationsDialogComponent,
    UpdateSubscriptionProjectExpirationsDialogData,
    UpdateSubscriptionProjectExpirationsDialogResult
} from './update-subscription-project-expirations-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ErrorComponent } from 'app/shared/components/error.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'admin-subscription-cleanup-config',
    templateUrl: './subscription-cleanup-config-page.component.html',
    styleUrls: ['./subscription-cleanup-config-page.component.scss'],
    imports: [PageSimpleContentComponent, MatButton, MatIcon, ErrorComponent, DatePipe]
})
export class SubscriptionCleanupConfigPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly subscriptionsService = inject(SubscriptionsService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly dialog = inject(MatDialog);

    private subscriptionId: any;

    subscriptionWithCleanup: Subscription;
    authContext: AuthorizationContext;
    currentProjectExpirations: ProjectExpirationConfig | undefined;
    pendingProjectExpirations: PendingProjectExpirationConfig | undefined;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.load();
            }));
    }

    load() {
        this.subscribeWithGenericLoadingErrorHandling(this.subscriptionsService.loadWithCleanup(this.subscriptionId), (subscriptionWithCleanup) => {
            this.subscriptionWithCleanup = subscriptionWithCleanup;
            this.currentProjectExpirations = this.subscriptionWithCleanup.cleanup.projectExpirations || {};
            this.pendingProjectExpirations = this.subscriptionWithCleanup.cleanup.pendingProjectExpirations || {};
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    cancelPendingProjectExpiration() {
        this.confirmDialogService
            .confirm(
                'Confirmation',
                'Do you want to cancel this configuration request?'
            )
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(
                    this.subscriptionsService.cancelSubscriptionPendingProjectExpirations({
                        subscriptionId: this.subscriptionWithCleanup.id
                    }),
                    () => {
                        this.load();
                    });
            });
    }

    editExpirations() {
        let dialogRef = this.dialog.open<UpdateSubscriptionProjectExpirationsDialogComponent, UpdateSubscriptionProjectExpirationsDialogData, UpdateSubscriptionProjectExpirationsDialogResult>(
            UpdateSubscriptionProjectExpirationsDialogComponent,
            {
                data: <UpdateSubscriptionProjectExpirationsDialogData>{
                    photobook: this.currentProjectExpirations?.photobookDelayInDays,
                    gifting: this.currentProjectExpirations?.giftingDelayInDays,
                    print: this.currentProjectExpirations?.printDelayInDays
                }
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionsService.updateSubscriptionCleanupDelay({
                        subscriptionId: this.subscriptionWithCleanup.id,
                        photobookProjectCleanupDelayInDays: result.photobook,
                        giftingProjectCleanupDelayInDays: result.gifting,
                        printProjectCleanupDelayInDays: result.print
                    }
                ),
                () => {
                    this.load();
                }
            );
        });
    }

    canManageSubscription(): boolean {
        return this.authContext.hasSubscriptionPermissions(this.subscriptionWithCleanup.id, SubscriptionPermission.ManageSubscriptionMetadata);
    }
}
