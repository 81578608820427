import { Route } from '@angular/router';

import { AnomaliesPageContainerComponent } from './pages/store-support/order/anomalies-page.component';
import { StoreAuthorizedUsersPageContainerComponent } from './pages/store-management/permission/store-authorized-users-page.component';
import { DashboardsOverviewPageContainerComponent } from './pages/store-support/dashboard/dashboards-overview-page.component';
import { Error404PageContainerComponent } from './pages/global/404/error-404-page.component';
import { FulfillerPageContainerComponent } from './pages/superadmin/fulfiller/fulfiller-page.component';
import { FulfillersPageContainerComponent } from './pages/superadmin/fulfiller/fulfillers-page.component';
import { HomePageContainerComponent } from './pages/global/home/home-page.component';
import { MediaclipDesignerComponent } from './pages/store-support/project/mediaclip-designer.component';
import { OrderPageContainerComponent } from './pages/store-support/order/order-page.component';
import { OrdersPageContainerComponent } from './pages/store-support/order/orders-page.component';
import { ExternalPhotoSourcesPageComponent } from './pages/store-management/photo-source/external-photo-sources-page.component';
import { ProductPageContainerComponent } from './pages/store-support/product/product-page.component';
import { ProductsPageContainerComponent } from './pages/store-support/product/products-page.component';
import { ProjectPageContainerComponent } from './pages/store-support/project/project-page.component';
import { SharedProjectPageContainerComponent } from './pages/store-support/project/shared-project-page.component';
import { RootPageContainerComponent } from './layout/root-page.component';
import { SearchPageContainerComponent } from './pages/global/search/search-page.component';
import { SignInPageContainerComponent } from './pages/global/login/signin-page.component';
import { signInPageGuard } from './pages/global/login/signin-page.guard';
import { StoreDesignerCustomCssComponent } from './pages/store-management/designer/store-designer-custom-css.component';
import { StoreDesignerCustomJavascriptComponent } from './pages/store-management/designer/store-designer-custom-javascript.component';
import { StoreDesignerLogoComponent } from './pages/store-management/designer/store-designer-logo.component';
import {
    StoreDesignerVersionConfigurationComponent as StoreDesignerReleaseChannelConfigurationComponent
} from './pages/store-management/designer/store-designer-release-channel-configuration.component';
import { StoreEndpointsPageContainerComponent } from './pages/store-management/setting/store-endpoints-page.component';
import { StoreFulfillerLogsPageContainerComponent } from './pages/store-management/fulfiller/store-fulfiller-logs-page.component';
import { StoreFulfillerPageContainerComponent } from './pages/store-management/fulfiller/store-fulfiller-page.component';
import { StoreFulfillersPageContainerComponent } from './pages/store-management/fulfiller/store-fulfillers-page.component';
import { StoreLogsPageContainerComponent } from './pages/store-management/log/store-logs-page.component';
import { StorePhotoFilterConfigurationsComponent } from './pages/store-management/filter/store-photo-filter-configurations.component';
import { StoreSecretsPageContainerComponent } from './pages/store-management/secret/store-secrets-page.component';
import { StoreSelectorPageComponent } from './pages/global/resource-selector/store-selector-page.component';
import { SubscriptionSelectorPageComponent } from './pages/global/resource-selector/subscription-selector-page.component';
import { StoreSettingsPageContainerComponent } from './pages/store-management/setting/store-settings-page.component';
import { StoreStatusPageContainerComponent } from './pages/store-management/mediaclip-managment/store-status-page.component';
import { SubscriptionsPageContainerComponent } from './pages/superadmin/subscription/subscriptions-page.component';
import { UserPageContainerComponent } from './pages/store-support/user/user-page.component';
import { LibrariesPageComponent } from './pages/library-management/update/libraries-page.component';
import { RecentOrderPageComponent } from './pages/store-support/order/recent-order-page.component';
import { GenericExternalPhotoSourceEditPageComponent } from './pages/store-management/photo-source/generic-external-photo-source-edit-page.component';
import { BuiltInExternalPhotoSourceEditPageComponent } from './pages/store-management/photo-source/built-in-external-photo-source-edit-page.component';
import { SubscriptionPageContainerComponent } from './pages/subscription-management/settings/subscription-page.component';
import { StoreDesignerCustomLocalesComponentComponent } from './pages/store-management/designer/store-designer-custom-locales-component.component';
import { viewContextGuard } from './navigation/view-context-guard';
import { StockPhotoSourcesPageContainerComponent } from './pages/superadmin/stock-photo/stock-photo-sources-page.component';
import { LibraryPageComponent } from './pages/library-management/update/library-page.component';
import { LibraryAuthorizedUserComponent } from './pages/library-management/permission/library-authorized-user.component';
import { FulfillerAuthorizedUserComponent } from './pages/superadmin/fulfiller/fulfiller-authorized-user.component';
import { FulfillerSecretsPageComponent } from './pages/superadmin/fulfiller/fulfiller-secrets-page.component';
import { CreateOrderComponent } from './pages/store-support/order/create-order.component';
import { SubscriptionAuthorizedUserPageComponent } from './pages/subscription-management/permission/subscription-authorized-user-page.component';
import { SubscriptionBillingPageComponent } from './pages/subscription-management/billing/subscription-billing-page.component';
import { SubscriptionCleanupConfigPageComponent } from './pages/subscription-management/settings/subscription-cleanup-config-page.component';
import { AdminUsersPageComponent } from './pages/superadmin/user/admin-users-page.component';
import { AdminUserPageComponent } from './pages/superadmin/user/admin-user-page.component';
import { StockPhotoSourcePageComponent } from './pages/superadmin/stock-photo/stock-photo-source-page.component';
import { PhotoPageComponent } from './pages/store-support/photo/photo-page.component';
import { GeneratedImagesPageContainerComponent } from './pages/store-management/generated-image/generated-images-page.component';
import { GeneratedImagePageComponent } from './pages/store-management/generated-image/generated-image-page.component';
import { GeneratedImageStoreAssociationsPageComponent } from './pages/store-management/generated-image/generated-image-store-associations-page.component';
import { GeneratedImageStoreAssociationPageComponent } from './pages/store-management/generated-image/generated-image-store-association-page.component';
import { ManageDesignerReleaseChannelVersionPageContainerComponent } from './pages/superadmin/designer-channel/manage-designer-release-channel-version.component';
import { StorePhotosUpscalingPageContainerComponent } from './pages/store-management/upscaling/store-photos-upscaling-page.component';
import { StoreGeneratedTextPageComponent } from './pages/store-management/generated-image/store-generated-text-page.component';
import { StoreFilterCategoriesPageComponent } from './pages/store-management/filter/store-filter-categories-page.component';
import { DirectPhotoSourceEditPageComponent } from './pages/store-management/photo-source/direct-photo-source-edit-page.component';
import { RequestLoggerPageComponent } from './pages/superadmin/request-logger/request-logger-page.component';
import { StoreRemoteFilterPageComponent } from './pages/store-management/filter/store-remote-filter-page.component';

export const routes: Route[] = [
    {
        path: '',
        component: RootPageContainerComponent
    },
    {
        path: 'signin-auth0',
        canActivate: [signInPageGuard],
        component: SignInPageContainerComponent
    },
    {
        path: 'home',
        component: HomePageContainerComponent
    },
    {
        path: 'search',
        component: SearchPageContainerComponent
    },
    {
        path: 'orders/:orderId',
        component: OrderPageContainerComponent
    },
    {
        path: 'projects/:projectId',
        component: ProjectPageContainerComponent
    },
    {
        path: 'shared-projects/:sharedProjectId',
        component: SharedProjectPageContainerComponent
    },
    {
        path: 'projects/:projectId/edit',
        component: MediaclipDesignerComponent
    },
    {
        path: 'projects/:projectId/versions/:projectVersionId',
        component: ProjectPageContainerComponent
    },
    {
        path: 'users/:userId',
        component: UserPageContainerComponent
    },
    {
        path: 'select-store',
        component: StoreSelectorPageComponent
    },
    {
        path: 'select-subscription',
        component: SubscriptionSelectorPageComponent
    },
    {
        path: 'stores/:storeId',
        canActivate: [viewContextGuard],
        children: [
            {
                path: 'dashboards/overview',
                component: DashboardsOverviewPageContainerComponent
            },
            {
                path: 'logs/fulfillers/:fulfillerId',
                component: StoreFulfillerLogsPageContainerComponent
            },
            {
                path: 'logs',
                component: StoreLogsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/logs'
                }
            },
            {
                path: 'lists/action-required',
                component: OrdersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/lists/action-required'
                }
            },
            {
                path: 'create-order',
                component: CreateOrderComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/create-order'
                }
            },
            {
                path: 'lists/recent',
                component: RecentOrderPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/lists/recent'
                }
            },
            {
                path: 'orders/:storeOrderId',
                children: [
                    {
                        path: '',
                        component: OrderPageContainerComponent
                    },
                    {
                        path: 'lines/:storeLineNumber/edit',
                        component: MediaclipDesignerComponent
                    },
                    {
                        path: 'lines/:storeLineNumber',
                        component: OrderPageContainerComponent
                    }
                ]
            },
            {
                path: 'library/products',
                component: ProductsPageContainerComponent
            },
            {
                path: 'library/product',
                component: ProductPageContainerComponent
            },
            {
                path: 'settings/endpoints',
                component: StoreEndpointsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/endpoints'
                }
            },
            {
                path: 'settings/general',
                component: StoreSettingsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/general'
                }
            },
            {
                path: 'settings/status',
                component: StoreStatusPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/status'
                }
            },
            {
                path: 'settings/external-photo-sources',
                component: ExternalPhotoSourcesPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/photos-upscaling',
                component: StorePhotosUpscalingPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/photos-upscaling'
                }
            },
            {
                path: 'settings/generated-text',
                component: StoreGeneratedTextPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/generated-text'
                }
            },
            {
                path: 'settings/external-photo-sources/generic/create/:photoSourceId',
                component: GenericExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/generic/edit/:photoSourceId',
                component: GenericExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/direct/create/:photoSourceId',
                component: DirectPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/direct/edit/:photoSourceId',
                component: DirectPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/built-in/create/:photoSourceId',
                component: BuiltInExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'create',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'settings/external-photo-sources/built-in/edit/:photoSourceId',
                component: BuiltInExternalPhotoSourceEditPageComponent,
                data: {
                    mode: 'edit',
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/external-photo-sources'
                }
            },
            {
                path: 'designer/release-channel',
                component: StoreDesignerReleaseChannelConfigurationComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/release-channel'
                }
            },
            {
                path: 'designer/generated-images',
                children: [
                    {
                        path: '',
                        component: GeneratedImageStoreAssociationsPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images'
                        }
                    },
                    {
                        path: 'create',
                        component: GeneratedImageStoreAssociationPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images/create'
                        }
                    },
                    {
                        path: ':associationId',
                        component: GeneratedImageStoreAssociationPageComponent,
                        data: {
                            viewContextType: 'store',
                            viewContextChangeUrl: 'stores/:storeId/designer/generated-images'
                        }
                    }
                ]
            },
            {
                path: 'designer/custom-javascript',
                component: StoreDesignerCustomJavascriptComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-javascript'
                }
            },
            {
                path: 'designer/custom-locales',
                component: StoreDesignerCustomLocalesComponentComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-locales'
                }
            },
            {
                path: 'designer/custom-css',
                component: StoreDesignerCustomCssComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/custom-css'
                }
            },
            {
                path: 'designer/logo',
                component: StoreDesignerLogoComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/logo'
                }
            },
            {
                path: 'designer/photo-filters',
                component: StorePhotoFilterConfigurationsComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/photo-filters'
                }
            },
            {
                path: 'designer/remote-filters',
                component: StoreRemoteFilterPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/remote-filters'
                }
            },
            {
                path: 'designer/filter-categories',
                component: StoreFilterCategoriesPageComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/designer/filter-categories'
                }
            },
            {
                path: 'settings/authorized-users',
                component: StoreAuthorizedUsersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/authorized-users'
                }
            },
            {
                path: 'settings/fulfillers',
                component: StoreFulfillersPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/fulfillers'
                }
            },
            {
                path: 'settings/fulfillers/:fulfillerId',
                component: StoreFulfillerPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/fulfillers'
                }
            },
            {
                path: 'settings/secrets',
                component: StoreSecretsPageContainerComponent,
                data: {
                    viewContextType: 'store',
                    viewContextChangeUrl: 'stores/:storeId/settings/secrets'
                }
            }
        ]
    },
    {
        path: 'anomalies',
        component: AnomaliesPageContainerComponent
    },
    {
        path: 'manage-designer-release-channel-version',
        component: ManageDesignerReleaseChannelVersionPageContainerComponent
    },
    {
        path: 'subscriptions',
        component: SubscriptionsPageContainerComponent
    },
    {
        path: 'subscriptions/:subscriptionId',
        canActivate: [viewContextGuard],
        children: [
            {
                path: '',
                component: SubscriptionPageContainerComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId'
                }
            },
            {
                path: 'authorizedUsers',
                component: SubscriptionAuthorizedUserPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/authorizedUsers'
                }
            },
            {
                path: 'billing',
                component: SubscriptionBillingPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/billing'
                }
            },
            {
                path: 'cleanup',
                component: SubscriptionCleanupConfigPageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/cleanup'
                }
            },
            {
                path: 'generated-images',
                component: GeneratedImagesPageContainerComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images'
                }
            },
            {
                path: 'generated-images/create',
                component: GeneratedImagePageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images'
                }
            },
            {
                path: 'generated-images/:generatedImageId',
                component: GeneratedImagePageComponent,
                data: {
                    viewContextType: 'subscription',
                    viewContextChangeUrl: 'subscriptions/:subscriptionId/generated-images/:generatedImageId'
                }
            }
        ]
    },
    {
        path: 'fulfillers',
        component: FulfillersPageContainerComponent
    },
    {
        path: 'libraries',
        component: LibrariesPageComponent
    },
    {
        path: 'libraries/:libraryOwner',
        canActivate: [viewContextGuard],
        component: LibraryPageComponent,
        data: {
            viewContextType: 'library',
            viewContextChangeUrl: 'libraries/:libraryOwner'
        }
    },
    {
        path: 'libraries/:libraryOwner/authorizedUsers',
        canActivate: [viewContextGuard],
        component: LibraryAuthorizedUserComponent,
        data: {
            viewContextType: 'library',
            viewContextChangeUrl: 'libraries/:libraryOwner/authorizedUsers'
        }
    },
    {
        path: 'photos/:photoUrn',
        component: PhotoPageComponent
    },
    {
        path: 'fulfillers/:fulfillerId',
        canActivate: [viewContextGuard],
        component: FulfillerPageContainerComponent
    },
    {
        path: 'fulfillers/:fulfillerId/authorizedUsers',
        canActivate: [viewContextGuard],
        component: FulfillerAuthorizedUserComponent,
        data: {
            viewContextType: 'fulfiller',
            viewContextChangeUrl: 'fulfillers/:fulfillerId/authorizedUsers'
        }
    },
    {
        path: 'fulfillers/:fulfillerId/secrets',
        canActivate: [viewContextGuard],
        component: FulfillerSecretsPageComponent,
        data: {
            viewContextType: 'fulfiller',
            viewContextChangeUrl: 'fulfillers/:fulfillerId/secrets'
        }
    },
    {
        path: 'stock-photo-sources',
        component: StockPhotoSourcesPageContainerComponent
    },
    {
        path: 'request-logger',
        component: RequestLoggerPageComponent
    },
    {
        path: 'stock-photo-sources/:stockPhotoSourceId',
        component: StockPhotoSourcePageComponent
    },
    {
        path: 'generated-images',
        component: GeneratedImagesPageContainerComponent
    },
    {
        path: 'generated-images/create',
        component: GeneratedImagePageComponent
    },
    {
        path: 'generated-images/:generatedImageId',
        component: GeneratedImagePageComponent
    },
    {
        path: 'admin-users',
        children: [
            {
                path: '',
                component: AdminUsersPageComponent
            },
            {
                path: ':userEmail',
                component: AdminUserPageComponent
            }
        ]
    },
    {
        path: '**',
        component: Error404PageContainerComponent
    }
];
