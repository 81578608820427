import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageStatus } from '../../../layout/content/page-status';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { PhotosService } from './photos.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    templateUrl: './photo-page.component.html',
    styleUrls: ['./photo-page.component.scss'],
    imports: [PageSimpleContentComponent, CopyToClipboardComponent, SaveButtonComponent, ErrorComponent]
})
export class PhotoPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly photosService = inject(PhotosService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    photoUrn: string;
    authContext: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.photoUrn = params.photoUrn;
            })
        );
        this.setPageStatus(PageStatus.loaded);
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    reEncodeImage(urn: string) {
        this.confirmDialogService
            .confirm('Re-Encode Photo', 'Do you really want to re-encode this photo?',
                'This should only be used if this photo cannot be rendered by the production server', {
                    yes: {
                        warn: true,
                        label: 'Re-Encode'
                    },
                    no: {
                        label: 'Cancel'
                    }
                })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.photosService.reEncodePhoto(urn));
            });
    }

    canManagePhoto() {
        // FIXME: When we'll load photo info we can check this
        // this.authContext.hasStorePermissions(, , StorePermission.ManagePhotos);
        return true;
    }
}
