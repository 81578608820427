import { Component, Input, ViewChild, ElementRef, inject } from '@angular/core';
import { FileEditorInformation, GeneratedImageDefinition } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageStatus } from '../../../layout/content/page-status';
import { GeneratedImagesService } from './generated-images.service';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { MatNavList, MatListItem } from '@angular/material/list';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'admin-generated-image-assets-editor',
    templateUrl: './generated-image-assets-editor.component.html',
    styleUrls: ['./generated-image-assets-editor.component.scss'],
    imports: [ErrorComponent, MatButton, MatIcon, SaveButtonComponent, MatNavList, MatListItem, CodemirrorModule, FormsModule]
})
export class GeneratedImageAssetsEditorComponent extends AdminComponentBase {
    private readonly generatedImagesService = inject(GeneratedImagesService);
    private readonly promptDialogService = inject(PromptDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    @Input()
    generatedImageDefinition: GeneratedImageDefinition;

    @ViewChild('singleFileUploader') singleFileUploader:ElementRef;
    @ViewChild('multipleFilesUploader') multipleFilesUploader:ElementRef;

    selectedFile?: FileEditorInformation;
    savedFileContents: Record<string, string> = {};
    fileContents: Record<string, string> = {};
    fileMimeTypes: Record<string, string> = {};
    acceptedUploadFileTypes: string = [
        'application/json',
        'application/javascript',
        'text/*',
        'image/*',
    ].join(',')

    constructor() {
        super();
        this.pageStatus = PageStatus.loaded;
    }

    selectFile(file?: FileEditorInformation) {
        this.selectedFile = file;
        if (!file) {
            return;
        }
        if (!(file.filename in this.fileContents)) {
            this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.getEditorFile(file.url), ({ content, mimeType }) => {
                this.fileContents[file.filename] = content;
                this.savedFileContents[file.filename] = content;
                this.fileMimeTypes[file.filename] = mimeType || 'application/octet-stream';
            });
        }
    }

    isTextEditableFile(file: FileEditorInformation) {
        return file.mimeType.startsWith('text/') || file.mimeType.startsWith('application/json') || file.mimeType.startsWith('application/javascript');
    }

    isImage(file: FileEditorInformation) {
        return file.mimeType.startsWith('image/');
    }

    isFileNotSaved(): boolean {
        for (let key of Object.keys(this.fileContents)) {
            if (this.fileContents[key] !== this.savedFileContents[key])
                return true;
        }
        return false;
    }

    save(file: FileEditorInformation) {
        this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.uploadGeneratedImageEditorFile({
            content: this.fileContents[file.filename],
            filename: file.filename,
            definitionId: this.generatedImageDefinition.id
        }), () => {
            this.savedFileContents[file.filename] = this.fileContents[file.filename];
        });
    }

    saveAll() {
        this.saveAllRecursive();
    }

    saveAllRecursive() {
        const firstModifiedFile = this.generatedImageDefinition.fileEditorInformation.find(f => this.isFileModified(f));
        if (!firstModifiedFile)
            return;
        this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.uploadGeneratedImageEditorFile({
            content: this.fileContents[firstModifiedFile.filename],
            filename: firstModifiedFile.filename,
            definitionId: this.generatedImageDefinition.id
        }), () => {
            this.savedFileContents[firstModifiedFile.filename] = this.fileContents[firstModifiedFile.filename];
            this.saveAllRecursive();
        });
    }

    async uploadFromFiles(files: FileList) {
        await this.uploadFileRecursively(files, 0)
        this.multipleFilesUploader.nativeElement.value = null;
    }

    async uploadFileRecursively(files: FileList, fileIndex: number) {
        if (files.length <= fileIndex) {
            return;
        }

        const file = files[fileIndex];
        const contentBase64 = await blobToBase64(file);
        this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.uploadGeneratedImageEditorFile({
            contentBase64: contentBase64,
            definitionId: this.generatedImageDefinition.id,
            filename: file.name
        }), async (uploadedFile) => {
            this.updateFileAfterUpload(uploadedFile);
            await this.uploadFileRecursively(files, fileIndex + 1);
        });
    }

    async uploadSelectedFileContent(filename: string, files: FileList) {
        if (files.length < 1) {
            return;
        }
        let file = files[0];
        const contentBase64 = await blobToBase64(file);
        this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.uploadGeneratedImageEditorFile({
            contentBase64: contentBase64,
            definitionId: this.generatedImageDefinition.id,
            filename: filename
        }), async (uploadedFile) => {
            this.updateFileAfterUpload(uploadedFile);
            this.singleFileUploader.nativeElement.value = null;
        });
    }

    private updateFileAfterUpload(uploadedFile: FileEditorInformation) {
        let index = this.generatedImageDefinition.fileEditorInformation.findIndex(x => x.filename === uploadedFile.filename);
        if (index === -1) {
            this.generatedImageDefinition.fileEditorInformation.push(uploadedFile);
        } else {
            this.generatedImageDefinition.fileEditorInformation[index] = uploadedFile;
            if (this.fileContents[uploadedFile.filename]) {
                delete this.fileContents[uploadedFile.filename];
                delete this.savedFileContents[uploadedFile.filename];
                delete this.fileMimeTypes[uploadedFile.filename];
            }
        }

        if (this.selectedFile?.filename === uploadedFile.filename) {
            this.selectFile(uploadedFile);
        }
    }

    addNewFile() {
        let defaultValue = 'index.html';
        if (this.generatedImageDefinition.fileEditorInformation.find(x => x.filename === defaultValue))
            defaultValue = '';
        this.promptDialogService.prompt('Add new file', undefined, { fieldLabel: 'Filename', defaultValue: defaultValue }).subscribe((filename) => {
            if (!filename) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.uploadGeneratedImageEditorFile({
                content: ' ',
                definitionId: this.generatedImageDefinition.id,
                filename: filename
            }), (newFile) => {
                this.generatedImageDefinition.fileEditorInformation.push(newFile);
                this.selectFile(newFile);
            });
        })

    }

    deleteFile(file: FileEditorInformation) {
        this.confirmDialogService.confirm(
            'Delete file',
            `Do you want to delete the file <code>${file.filename}</code>?`,
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                },
                no: {
                    label: 'Cancel'
                }
            }
        )
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.deleteGeneratedImageEditorFile({
                    definitionId: this.generatedImageDefinition.id,
                    filename: file.filename
                }), () => {
                    this.generatedImageDefinition.fileEditorInformation = this.generatedImageDefinition.fileEditorInformation.filter(x => x.filename !== file.filename);
                    this.selectFile(this.generatedImageDefinition.fileEditorInformation[0]);
                });
            })
    }

    isFileModified(file: FileEditorInformation) : boolean {
        return this.savedFileContents[file.filename] !== this.fileContents[file.filename];
    }
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const raw = (reader.result as string);
            const separator = raw.indexOf(',');
            resolve(raw.substring(separator === -1 ? 0 : separator + 1) as string);
        };
        reader.readAsDataURL(blob);
    });
}
