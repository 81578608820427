import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { PageStatus } from '../../layout/content';

interface SaveButtonState {
    showSpinner: boolean;
    showCheck: boolean;
    disabled: boolean;
    color: ThemePalette;
    text: string;
    mayUseContent?: boolean;
}

const states: { [name: string]: SaveButtonState } = {
    disabled: {
        showSpinner: false,
        showCheck: false,
        disabled: true,
        color: 'primary',
        text: 'Loading'
    },
    saving: {
        showSpinner: true,
        showCheck: false,
        disabled: true,
        color: 'primary',
        text: 'Saving'
    },
    ready: {
        showSpinner: false,
        showCheck: false,
        disabled: false,
        color: 'primary',
        text: 'Save',
        mayUseContent: true
    },
    error: {
        showSpinner: false,
        showCheck: false,
        disabled: false,
        color: 'warn',
        text: 'Error! Try again?'
    },
    success: {
        showSpinner: false,
        showCheck: true,
        disabled: false,
        color: 'primary',
        text: 'Saved!'
    }
};

@Component({
    selector: 'admin-save-button',
    templateUrl: './save-button.component.html',
    styleUrls: ['./save-button.component.scss'],
    imports: [MatButton, NgClass, MatProgressSpinner, MatIcon]
})
export class SaveButtonComponent implements OnChanges {
    private readonly ngZone = inject(NgZone);
    private ref = inject(ChangeDetectorRef);

    @Input() pageStatus?: PageStatus;
    @Input() disabled: boolean | undefined;
    @Input() useContent?: boolean;

    @Output() save = new EventEmitter<undefined>();

    private pending: boolean;
    state: SaveButtonState = states.disabled;
    private timerId: any;

    onClick(): void {
        this.pending = true;
        this.save.next(undefined);
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.state = this.getUpdatedState();
    }

    private getUpdatedState(): SaveButtonState {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        if (this.pageStatus) {
            return this.getStatusFromPageStatus();
        }

        return states.disabled;
    }

    private getStatusFromPageStatus() {
        switch (this.pageStatus) {
            case PageStatus.loaded:
                if (this.pending) {
                    this.pending = false;
                    this.timerId = setTimeout(() => {
                        this.ngZone.run(() => {
                            this.state = states.ready;
                            this.ref.detectChanges();
                        });
                    }, 2000);
                    return states.success;
                } else {
                    return states.ready;
                }
            case PageStatus.saving:
                return states.saving;
            case PageStatus.saveError:
                return states.error;
            default:
                return states.disabled;
        }
    }
}
