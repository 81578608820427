<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Stock Photo Source: ' + stockPhotoSource?.id"
    [icon]="'image'"
>
    @if (stockPhotoSource) {
        <div class="content">
            <form #form="ngForm">
                <mat-form-field>
                    <mat-label>Display name</mat-label>
                    <input matInput [(ngModel)]="stockPhotoSource.label" name="label" type="text" autocomplete="off" />
                    <mat-hint>This label will be displayed in the designer if not empty</mat-hint>
                </mat-form-field>
                <div class="config-checkbox">
                    <mat-checkbox [(ngModel)]="stockPhotoSource.visible" name="visible"> Visible</mat-checkbox>
                    <span class="mat-caption"
                    >Show this source in the Designer when enable (allow addToCartAndSkipDesigner flow)</span
                    >
                </div>
                <div class="config-checkbox">
                    <mat-checkbox [(ngModel)]="stockPhotoSource.disabled" name="disabled"> Disabled</mat-checkbox>
                    <span class="mat-caption">Whether this source is disabled and can be used</span>
                </div>
                <div class="thumbnail-field">
                    <mat-form-field>
                        <mat-label>Thumbnail Url</mat-label>
                        <input
                            matInput
                            [(ngModel)]="stockPhotoSource.thumbnailUrl"
                            name="thumbnailUrl"
                            placeholder="https://"
                            [pattern]="urlPattern"
                            type="url"
                            autocomplete="off"
                        />
                        <mat-error>{{ invalidUrlMessage }}</mat-error>
                        <mat-hint> The url of the thumbnail used for the root ablbum in the designer</mat-hint>
                    </mat-form-field>
                    <div class="image-preview">
                        @if (stockPhotoSource.thumbnailUrl) {
                            <img [src]="stockPhotoSource.thumbnailUrl" />
                        }
                    </div>
                </div>
                <div class="endpoint-with-headers">
                    <mat-form-field>
                        <mat-label>Photos Endpoint</mat-label>
                        <input
                            matInput
                            [(ngModel)]="stockPhotoSource.photosEndpoint.url"
                            name="photosEndpointUrl"
                            placeholder="https://"
                            [pattern]="urlPattern"
                            type="url"
                            required
                            autocomplete="off"
                        />
                        <mat-error>{{ invalidUrlMessage }}</mat-error>
                        <mat-hint>
                            <a
                                href="https://doc.mediaclip.ca/hub/photo-sources/stock/photos-endpoint/"
                                class="external"
                                rel="external"
                                target="_blank"
                            >More info...
                                <mat-icon>open_in_new</mat-icon>
                            </a
                            >
                        </mat-hint>
                    </mat-form-field>
                    <div class="headers-container">
                        @for (header of stockPhotoSource.photosEndpoint.headers; track header; let i = $index) {
                            <div class="header-container">
                                <mat-form-field>
                                    <mat-label>Header Name</mat-label>
                                    <input
                                        matInput
                                        [(ngModel)]="header.name"
                                        [name]="'photosEndpointEndpointName' + i"
                                        required
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Header Value</mat-label>
                                    <input
                                        #secretInput
                                        matInput
                                        [(ngModel)]="header.value"
                                        [name]="'photosEndpointEndpointValue' + i"
                                        required
                                        autocomplete="off"
                                    />
                                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                                </mat-form-field>
                                <button
                                    mat-icon-button
                                    (click)="removeHeader(stockPhotoSource.photosEndpoint.headers, i)"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        }
                        <button
                            mat-stroked-button
                            (click)="addHeader(stockPhotoSource.photosEndpoint.headers)"
                            class="add-header"
                        >
                            <mat-icon>add</mat-icon>
                            Add header
                        </button>
                    </div>
                </div>
                <div class="endpoint-with-headers">
                    <mat-form-field>
                        <mat-label>Albums Endpoint</mat-label>
                        <input
                            matInput
                            [(ngModel)]="stockPhotoSource.albumsEndpoint.url"
                            name="albumsEndpointUrl"
                            placeholder="https://"
                            [pattern]="urlPattern"
                            type="url"
                            autocomplete="off"
                        />
                        <mat-error>{{ invalidUrlMessage }}</mat-error>
                        <mat-hint>
                            <a
                                href="https://doc.mediaclip.ca/hub/photo-sources/stock/album-endpoint/"
                                class="external"
                                rel="external"
                                target="_blank"
                            >More info...
                                <mat-icon>open_in_new</mat-icon>
                            </a>
                        </mat-hint>
                    </mat-form-field>
                    <div class="headers-container">
                        @for (header of stockPhotoSource.albumsEndpoint.headers; track header; let i = $index) {
                            <div class="header-container">
                                <mat-form-field>
                                    <mat-label>Header Name</mat-label>
                                    <input
                                        appearance="fill"
                                        matInput
                                        [(ngModel)]="header.name"
                                        [name]="'albumsEndpointEndpointName' + i"
                                        required
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Header Value</mat-label>
                                    <input
                                        appearance="fill"
                                        matInput
                                        #secretInput
                                        [(ngModel)]="header.value"
                                        [name]="'albumsEndpointEndpointValue' + i"
                                        required
                                        autocomplete="off"
                                    />
                                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                                </mat-form-field>
                                <div class="actions">
                                    <button
                                        mat-icon-button
                                        (click)="removeHeader(stockPhotoSource.albumsEndpoint.headers, i)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        }
                        <button
                            mat-stroked-button
                            (click)="addHeader(stockPhotoSource.albumsEndpoint.headers)"
                            class="add-header"
                        >
                            <mat-icon>add</mat-icon>
                            Add header
                        </button>
                    </div>
                </div>
                @if (canManageStockPhotoSource()) {
                    <admin-save-button (save)="save()" [disabled]="!form.valid" [pageStatus]="pageStatus">
                    </admin-save-button>
                }
            </form>
            <div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
            </div>
        </div>
    }
</admin-page-simple-content>
