import { Directive, ElementRef, OnDestroy, OnInit, inject } from '@angular/core';

@Directive({ selector: '[adminAutoTrim]' })
export class AutoTrimDirective implements OnInit, OnDestroy {
    private elementRef = inject(ElementRef);


    ngOnInit(): void {
        this.elementRef.nativeElement.addEventListener('blur', this.onBlur);
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.removeEventListener('blur', this.onBlur);
    }

    private onBlur(event: FocusEvent) {
        if (event.target instanceof HTMLInputElement) {
            event.target.value = event.target.value.trim();
        }
    }
}
