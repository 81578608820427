<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Photo'"
    [icon]="'photo'"
>
    <div class="content">
        <div>
            Photo Urn: <code adminCopyToClipboard>{{ photoUrn }}</code>
        </div>
        @if (canManagePhoto()) {
            <admin-save-button [pageStatus]="pageStatus" useContent="true" (save)="reEncodeImage(photoUrn)">
                Re-Encode image
            </admin-save-button>
        }
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
    </div>
</admin-page-simple-content>
