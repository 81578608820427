import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { EnableRequestLoggerInput } from 'app/shared/graph';
import { ViewContextService } from '../../../navigation/view-context.service';
import { Subscription } from 'rxjs';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';


@Component({
    selector: 'admin-add-request-logger-rule-dialog',
    templateUrl: './add-request-logger-rule-dialog.component.html',
    styleUrls: ['./add-request-logger-rule-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatSuffix, MatDialogActions, MatButton, MatDialogClose]
})
export class AddRequestLoggerRuleDialogComponent implements OnInit, OnDestroy {
    viewContextService = inject(ViewContextService);

    subscriptions = new Subscription();
    ruleInfo: EnableRequestLoggerInput = {
        routePrefix: '/',
        statusCode: 400,
        maximumCaptureCount: 10,
        logsExpirationInSeconds: 3600
    };

    ngOnInit(): void {
        this.subscriptions.add(this.viewContextService.context$.subscribe(context => {
            console.warn(context)
            if (context.type == 'store') {
                this.ruleInfo.storeId = context.storeId;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
