import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/dialog/confirm-dialog.component';
import { GeneratedTextConfiguration } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { StoreGeneratedTextService } from "./store-generated-text.service";
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    selector: 'admin-store-generated-text',
    templateUrl: './store-generated-text-page.component.html',
    styleUrls: ['./store-generated-text-page.component.scss'],
    imports: [PageSimpleContentComponent, MatButton, MatIcon, FormsModule, MatFormField, MatLabel, MatInput, SecretInputToggleComponent, MatSuffix, SaveButtonComponent, ErrorComponent]
})
export class StoreGeneratedTextPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly authService = inject(AuthorizationService);
    private readonly storeGeneratedTextService = inject(StoreGeneratedTextService);
    private readonly matDialog = inject(MatDialog);

    private readonly defaultConfig : GeneratedTextConfiguration = {
        accessToken: '',
        organization: '',
    };
    private storeId: string;
    private authContext?: AuthorizationContext;

    public hasConfigDefined: boolean;
    public currentGeneratedTextConfig: GeneratedTextConfiguration = this.defaultConfig;

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
        this.registerSubscription(this.route.params.subscribe(async params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeGeneratedTextService.getConfig(this.storeId), (generatedTextConfig) => {
                this.hasConfigDefined = !!generatedTextConfig;
                if (generatedTextConfig)
                    this.currentGeneratedTextConfig = generatedTextConfig;
            });
        }))
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    deleteConfig() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Delete configuration',
                message: `Do you really want to delete that configuration ?`,
                options: {
                    yes: {
                        label: 'Delete',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storeGeneratedTextService.deleteConfiguration({ storeId: this.storeId }), () => {
                this.hasConfigDefined = false;
                this.currentGeneratedTextConfig = this.defaultConfig;
            });
        });
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.storeGeneratedTextService.updateConfiguration({
            ...this.currentGeneratedTextConfig,
            storeId: this.storeId
        }), () => {
            this.hasConfigDefined = true;
        });
    }
}
