import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/hint/javascript-hint';

import { enableProdMode, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';


import { environment } from './environments/environment';
import { AuthenticationService } from './app/shared/authentication';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from './app/fuse-config';
import { MatNativeDateModule } from '@angular/material/core';
import { AppComponent } from './app/app.component';

/*
It crashs at startup with events.EventEmitter is not a constructor.
To check later this is not a requirement.
import { JSHINT } from "jshint";
(<any>window).JSHINT = JSHINT;
*/

export function getBaseUrl(): string {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FuseModule.forRoot(fuseConfig), MatNativeDateModule),
        provideAppInitializer(() => {
            const initializerFn = ((authenticationService: AuthenticationService) => () => {
                return authenticationService.handleAuthentication();
            })(inject(AuthenticationService));
            return initializerFn();
        }),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
                subscriptSizing: 'dynamic'
            }
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(routes)
    ]
})
    .catch(err => {
        const errorMsgElement = document.querySelector('#initialization-error');
        if (errorMsgElement) {
            errorMsgElement.innerHTML = `An error occurred during initialization.<br>Please try to reload in a few instants.<br>If the problem persists contact <a href="mailto:support@mediacliphub.com">support@mediacliphub.com</a>`;
        }
        const spinnerElement = document.querySelector('#fuse-splash-screen .spinner-wrapper');
        if (spinnerElement) {
            spinnerElement.remove();
        }
        console.error(err);
    });
