import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FulfillerLog } from 'app/shared/graph';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { PageStatus } from '../../../layout/content/page-status';
import { StoreFulfillersService } from './store-fulfillers.service';

@Component({
    selector: 'admin-store-fulfiller-logs-page',
    templateUrl: './store-fulfiller-logs-page.component.html',
    styleUrls: ['./store-fulfiller-logs-page.component.scss'],
    imports: [PageSimpleContentComponent, RouterLink, MatCard, MatIcon, DatePipe]
})
export class StoreFulfillerLogsPageContainerComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly storeFulfillersService = inject(StoreFulfillersService);

    storeId: string;
    fulfillerId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    logs: FulfillerLog[] = [];

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
        this.fulfillerId = this.route.snapshot.params.fulfillerId;
    }

    ngOnInit() {
        this.storeFulfillersService.getStoreFulfillerLogs(this.storeId, this.fulfillerId)
            .subscribe({
                next: logs => {
                    this.logs = logs;
                    this.pageStatus = PageStatus.loaded;
                },
                error: err => {
                    this.pageError = err;
                    this.pageStatus = PageStatus.loadError;
                }
            });
    }

    prettifyJson(json?: string): string | undefined {
        if (!json)
            return undefined;
        if (!json.startsWith('{'))
            return json;
        return JSON.stringify(JSON.parse(json), null, '  ');
    }
}
