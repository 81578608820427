import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './generic-external-photo-source-add-dialog.component.html',
    styleUrls: ['./generic-external-photo-source-add-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class GenericExternalPhotoSourceAddDialogComponent {
    dialogRef = inject<MatDialogRef<GenericExternalPhotoSourceAddDialogComponent, string>>(MatDialogRef);

    public photoSourceIdControl = new UntypedFormControl('', [
        Validators.required,
        Validators.max(255),
        Validators.pattern(/^[a-z0-9]+$/),
    ]);

    create(photoSourceId: string): void {
        this.dialogRef.close(photoSourceId);
    }
}
