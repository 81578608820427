<div class="user-list">
    @if (users.length === 0) {
        No users
    }
    @for (authorizedUser of users; track authorizedUser) {
        <div class="user">
            <img class="picture" [src]="authorizedUser.user.picture" />
            <div class="email">
                <a [routerLink]="['/admin-users', authorizedUser.user.email]">{{ authorizedUser.user.email }}</a>
            </div>
            <div class="roles">
                <mat-chip-set>
                    @for (role of authorizedUser.roles; track role) {
                        <mat-chip
                            [removable]="canManagePermissions"
                            [disabled]="loading"
                            (removed)="roleRemoved.emit({ email: authorizedUser.user.email, role: role })"
                        >
                            {{ role }}
                            @if (canManagePermissions) {
                                <button matChipRemove [disabled]="loading" aria-label="Remove role: {{ role }}">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            }
                        </mat-chip>
                    }
                    @if (canAddRole(authorizedUser)) {
                        <mat-chip [disabled]="loading" (click)="addUserRole.emit({ user: authorizedUser })">
                            <mat-icon>add</mat-icon>
                        </mat-chip>
                    }
                </mat-chip-set>
            </div>
        </div>
    }
    @if (canManagePermissions) {
        <button mat-stroked-button (click)="addUser.emit()" [disabled]="loading">
            <mat-icon>add</mat-icon>
            ADD USER
        </button>
    }
</div>
