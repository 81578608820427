import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { GlobalPermission, Store, StorePermission } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogData, AddStoreFulfillerDialogResult } from './add-store-fulfiller-dialog.component';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { StatusColorComponentDirective } from '../../../shared/utilities/status-color.component';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor, MatButton } from '@angular/material/button';
import { StoreFulfillersService } from './store-fulfillers.service';

@Component({
    selector: 'admin-store-fulfillers-page',
    templateUrl: './store-fulfillers-page.component.html',
    styleUrls: ['./store-fulfillers-page.component.scss'],
    imports: [PageSimpleContentComponent, StatusColorComponentDirective, MatIcon, MatTooltip, MatIconAnchor, RouterLink, MatButton]
})
export class StoreFulfillersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly storeFulfillersService = inject(StoreFulfillersService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly dialog = inject(MatDialog);

    storeId: string;

    storeWithFulfillers?: Store;
    auth: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((auth) => {
            this.auth = auth;
        });

        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeFulfillersService.getStoreFulfillers(this.storeId), storeWithFulfillers => {
                this.storeWithFulfillers = storeWithFulfillers;
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    openAddStoreFulfillerDialog() {
        let dialogRef = this.dialog.open<AddStoreFulfillerDialogComponent, AddStoreFulfillerDialogData, AddStoreFulfillerDialogResult>(AddStoreFulfillerDialogComponent, {
            data: {
                usedFulfillerId: this.storeWithFulfillers?.fulfillers?.map(e => e.fulfillerId) || []
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.router.navigate([result.fulfillerId], { relativeTo: this.route });
        });
    }

    canManageStoreFulfillers(): boolean {
        if (!this.storeWithFulfillers) {
            return false;
        }
        return this.auth.hasStorePermissions(this.storeId, this.storeWithFulfillers.subscriptionId, StorePermission.ManageStoreFulfillers);
    }

    canAddStoreFulfiller() {
        if (!this.storeWithFulfillers) {
            return false;
        }

        return this.auth.hasGlobalPermissions(GlobalPermission.ListFulfillers) &&
            this.auth.hasStorePermissions(this.storeId, this.storeWithFulfillers.subscriptionId, StorePermission.ManageStoreFulfillers);
    }
}
