import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FulfillerRole, GlobalRole, LibraryRole, StoreRole, SubscriptionRole } from '../graph/schema-dotnet';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AutoTrimDirective } from '../utilities/auto-trim.directive';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

type Roles = FulfillerRole | LibraryRole | SubscriptionRole | StoreRole | GlobalRole

export interface AddAuthorizedUserDialogData<TRole extends Roles> {
    target: {
        label: string,
        availableRoles: TRole[],
    };
    email?: string;
}

export interface AddAuthorizedUserDialogResult<TRole extends Roles> {
    email: string;
    role: TRole;
}

@Component({
    templateUrl: './add-authorized-user-dialog.component.html',
    styleUrls: ['./add-authorized-user-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, AutoTrimDirective, MatSelect, MatOption, MatDialogActions, MatButton, MatDialogClose]
})
export class AddAuthorizedUserDialogComponent<TRole extends Roles> {
    dialogRef = inject<MatDialogRef<AddAuthorizedUserDialogComponent<TRole>, AddAuthorizedUserDialogResult<TRole>>>(MatDialogRef);
    data = inject<AddAuthorizedUserDialogData<TRole>>(MAT_DIALOG_DATA);

    email: string = '';
    selectedRole?: TRole;

    constructor() {
        const data = this.data;

        this.email = data.email || '';
    }

    valid() {
        if (!this.selectedRole) {
            return;
        }
        this.dialogRef.close({
            email: this.email,
            role: this.selectedRole
        });
    }
}
