import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewContextSubscription } from '../../../navigation/view-context.service';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'admin-selectable-subscription-element',
    templateUrl: './selectable-subscription-element.component.html',
    styleUrls: ['./selectable-subscription-element.component.scss'],
    imports: [MatCard, MatRipple, MatCardHeader, MatCardTitle, MatCardSubtitle, MatCardContent]
})
export class SelectableSubscriptionElementComponent {
    @Input() subscription: ViewContextSubscription;
    @Input() lastAccessed?: number;
    @Output() selectSubscription: EventEmitter<ViewContextSubscription> = new EventEmitter<ViewContextSubscription>();

    constructor() {
    }
}
