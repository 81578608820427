<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
    <p [innerHTML]="data.message"></p>
    @if (data.subMessage) {
        <p [innerHTML]="data.subMessage"></p>
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{ data.options?.no?.label || "No" }}
    </button>

    <button mat-button [color]="data.options?.yes?.warn ? 'warn' : 'primary'" [mat-dialog-close]="true">
        {{ data.options?.yes?.label || "Yes" }}
    </button>
</mat-dialog-actions>
