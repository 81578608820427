import { Component, OnInit, inject } from '@angular/core';
import { Fulfiller, FulfillerClient, GlobalPermission, Maybe } from 'app/shared/graph';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { MatDialog } from '@angular/material/dialog';
import { CreateFulfillerAppDialogComponent, CreateFulfillerAppDialogResult } from './create-fulfiller-app-dialog.component';
import { LinkFulfillerAppDialogComponent, LinkFulfillerAppDialogResult } from './link-fulfiller-app-dialog.component';
import { EditFulfillerDialogComponent, EditFulfillerDialogResult } from './edit-fulfiller-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { StatusColorComponentDirective } from '../../../shared/utilities/status-color.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { FulfillerService } from './fulfiller.service';

@Component({
    selector: 'admin-fulfiller-page',
    templateUrl: './fulfiller-page.component.html',
    styleUrls: ['./fulfiller-page.component.scss'],
    imports: [PageSimpleContentComponent, ErrorComponent, MatButton, MatIcon, CopyToClipboardComponent, StatusColorComponentDirective, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem]
})
export class FulfillerPageContainerComponent extends AdminComponentBase implements OnInit {
    private readonly authorizationService = inject(AuthorizationService);
    private readonly route = inject(ActivatedRoute);
    private readonly dialog = inject(MatDialog);
    private readonly fulfillerService = inject(FulfillerService);

    fulfillerId: string;
    fulfiller?: Maybe<Fulfiller>;

    private authorizationContext: AuthorizationContext;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.registerSubscription(this.route.params.subscribe(params => {
            this.fulfillerId = params.fulfillerId;
            this.subscribeWithGenericLoadingErrorHandling(this.fulfillerService.load(this.fulfillerId), fulfiller => {
                this.fulfiller = fulfiller;
            });
        }));
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.fulfillerService.load(this.fulfillerId), fulfiller => {
            this.fulfiller = fulfiller;
        });
    }

    canManageFulfillers() {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageFulfillers);
    }

    createFulfillerAppAndLinkToIt(fulfiller: Fulfiller) {
        this.dialog.open<CreateFulfillerAppDialogComponent, any, CreateFulfillerAppDialogResult>(CreateFulfillerAppDialogComponent).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillerService.createFulfillerApp({
                displayName: result.displayName
            }), result => {
                this.subscribeWithGenericSavinErrorHandling(this.fulfillerService.updateFulfiller({
                    id: fulfiller.id,
                    fulfillerAppId: result.id
                }), () => {
                    this.reload();
                });
            });
        });
    }

    linkFulfillerApp(fulfiller: Fulfiller) {
        this.dialog.open<LinkFulfillerAppDialogComponent, any, LinkFulfillerAppDialogResult>(LinkFulfillerAppDialogComponent).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.fulfillerService.updateFulfiller({
                id: fulfiller.id,
                fulfillerAppId: result.appId
            }), () => {
                this.reload();
            });
        });
    }

    editFulfiller(fulfiller: Fulfiller) {
        let dialogRef = this.dialog.open<EditFulfillerDialogComponent, Fulfiller, EditFulfillerDialogResult>(
            EditFulfillerDialogComponent,
            {
                data: fulfiller,
                minWidth: '100vw',
                height: '100vh',
            }
        );
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.fulfillerService.updateFulfiller({
                id: fulfiller.id,
                client: result.client || undefined,
                fulfillerAppId: result.fulfillerAppId || undefined,
                fulfillerAppKey: result.client == FulfillerClient.Fuji ? (result.appKey || undefined) : undefined,
                libraryPackage: result.libraryPackage || undefined,
                mappingScript: result.mappingScript,
                url: result.url || undefined
            }), () => {
                this.reload();
            });
        });
    }
}
