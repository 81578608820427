<h1 mat-dialog-title>Enable Fulfiller Logs</h1>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Log duration</mat-label>
            <input matInput [(ngModel)]="duration" name="duration" min="1" max="15" required type="number" />
            <span matSuffix>Days&nbsp;</span>
            <mat-hint>Automatically disable logs after</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button [mat-dialog-close]="undefined">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="+duration">OK</button>
</mat-dialog-actions>
