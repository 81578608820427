import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PageStatus } from '../../../layout/content/page-status';
import { StoreSecretsService } from './store-secrets.service';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { AppSecret, CreateAppSecret, Store, StorePermission } from 'app/shared/graph';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { AppSecretService } from 'app/shared/app-secret/app-secret.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatList, MatListItem } from '@angular/material/list';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    templateUrl: './store-secrets-page.component.html',
    styleUrls: ['./store-secrets-page.component.scss'],
    imports: [PageSimpleContentComponent, MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatList, MatListItem, MatButton, MatIcon, CopyToClipboardComponent, ErrorComponent]
})
export class StoreSecretsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly storeSecretsService = inject(StoreSecretsService);
    private readonly appSecretService = inject(AppSecretService);
    private readonly route = inject(ActivatedRoute);
    private readonly promptService = inject(PromptDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly authorizationService = inject(AuthorizationService);

    authorizationContext: AuthorizationContext;

    storeId: string = '';
    secrets: AppSecret[] = [];
    storeWithSecrets?: Store;
    lastCreatedSecret?: CreateAppSecret;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
                const storeId = params.storeId;
                this.storeId = storeId;
                this.secrets = [];
                this.subscribeWithGenericLoadingErrorHandling(this.storeSecretsService.getStoreSecrets(storeId), (storeWithSecrets) => {
                    this.storeWithSecrets = storeWithSecrets;
                    this.secrets = storeWithSecrets?.secrets || [];
                });
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    revokeSecret(storeId: string, secret: AppSecret) {
        this.confirmDialogService
            .confirm(`Revoke secret ${secret.label}`, `Do you really want to revoke the secret ${secret.label} for the store ${storeId} ?`, undefined, { yes: { warn: true } })
            .subscribe(_ => {
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.revokeSecret({ appId: storeId, id: secret.id }), () => {
                    const index = this.secrets.findIndex(s => s.id == secret.id);
                    if (index !== -1) {
                        this.secrets.splice(index, 1);
                    }
                });
            });
    }

    createSecret(storeId: string) {
        this.promptService.prompt('Create a new secret', undefined, { defaultValue: this.secrets.length == 0 ? 'default' : undefined, fieldLabel: 'Label' })
            .subscribe(result => {
                this.pageStatus = PageStatus.saving;
                if (this.secrets.find(s => s.label == result)) {
                    this.pageError = new Error('This label is already used by another secret');
                    this.pageStatus = PageStatus.saveError;
                    return;
                }
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.createAppSecret({ appId: storeId, label: result }), secret => {
                    this.secrets.push({ id: secret.id, label: secret.label });
                    this.lastCreatedSecret = secret;
                });
            });
    }

    canManageSecrets(store: Store): boolean {
        return this.authorizationContext.hasStorePermissions(store.id, store.subscriptionId, StorePermission.ManageStoreSecrets);
    }
}
