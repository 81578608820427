<admin-page-simple-content
    id="photo-filters"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Designer: Built-In Filters Settings'"
    [icon]="'insert_photo'"
>
    <div class="content">
        <p class="modes">
            Mode
            <mat-button-toggle-group
                name="ListType"
                aria-label="Select an allow or block list"
                [(ngModel)]="listType"
                (change)="changeListType($event)"
            >
                <mat-button-toggle value="all">All Filters</mat-button-toggle>
                <mat-button-toggle value="allow">Select enabled filters</mat-button-toggle>
                <mat-button-toggle value="block">Select disabled filters</mat-button-toggle>
            </mat-button-toggle-group>
        </p>
        <p class="notes">
            @switch (listType) {
                @case ("all") {
                    All filters will be available to the users. Filters added in the future will be enabled by default
                }
                @case ("allow") {
                    Only the selected filters are going to be available to the users. Future filters are not going to be
                        shown by default
                }
                @case ("block") {
                    All filters except the one selected are going to be available to the users. Filters added in the
                        future will be enabled by default
                }
            }
        </p>
        @if (listType !== "all") {
            <div class="selectors">
                @for (filter of filterDefinitions; track filter) {
                    <div>
                        <mat-checkbox [(ngModel)]="enabledFiltersById[filter.id]">
                            {{ filter.displayName }}
                        </mat-checkbox>
                    </div>
                }
            </div>
        }
        <mat-divider></mat-divider>
        <p>Note: Changes here can take up to 5 minutes to propagate to the designer.</p>
        <div class="actions">
            <admin-save-button [pageStatus]="pageStatus" (save)="save()"> Save</admin-save-button>
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>
    </div>
</admin-page-simple-content>
