<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Subscription: Authorized Users'"
    [icon]="'perm_identity'"
>
    @if (subscriptionWithAuthorizedUsers) {
        <div class="content">
            @if (!canManageUsers(subscriptionWithAuthorizedUsers.id)) {
                <p>
                    <em
                    >Please contact <a href="mailto:support@mediacliphub.com">support&#64;mediacliphub.com</a> to
                        add or remove authorized users.</em
                    >
                </p>
            }
            <h2>Users with access to the subscription</h2>
            <div class="subscriptions">
                <admin-users-role-management
                    [users]="subscriptionWithAuthorizedUsers.authorizedUsers"
                    [availableRoles]="subscriptionRoles"
                    [loading]="pageStatus !== 'loaded'"
                    [canManagePermissions]="canManageUsers(subscriptionWithAuthorizedUsers.id)"
                    (addUser)="openAddSubscriptionRole(subscriptionWithAuthorizedUsers)"
                    (addUserRole)="openAddSubscriptionRole(subscriptionWithAuthorizedUsers, $event.user)"
                    (roleRemoved)="removeSubscriptionRole(subscriptionWithAuthorizedUsers, $event.email, $event.role)"
                >
                </admin-users-role-management>
            </div>
            <div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
            </div>
        </div>
    }
</admin-page-simple-content>
