<admin-page-simple-content
    id="external-photo-sources"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Subscriptions'"
    [icon]="'receipt'"
>
    <div class="content">
        <div class="header">
            <div>
                <button mat-icon-button [matMenuTriggerFor]="filterMenu">
                    <mat-icon>filter_alt</mat-icon>
                </button>
                <mat-menu #filterMenu>
                    <button mat-menu-item (click)="toggleShowActiveOnly()">
                        @if (filter.showActiveOnly) {
                            <mat-icon>check_box</mat-icon>
                        }
                        @if (!filter.showActiveOnly) {
                            <mat-icon>check_box_outline_blank</mat-icon>
                        }
                        Only show active
                    </button>
                    <button mat-menu-item (click)="toggleShowBillableOnly()">
                        @if (filter.showBillableOnly) {
                            <mat-icon>check_box</mat-icon>
                        }
                        @if (!filter.showBillableOnly) {
                            <mat-icon>check_box_outline_blank</mat-icon>
                        }
                        Only show billable
                    </button>
                </mat-menu>

                <mat-form-field class="dense-2" floatLabel="always">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label>Filter</mat-label>
                    <input matInput (keydown)="updateFilter()" (keyup)="updateFilter()" [(ngModel)]="filter.name" />
                </mat-form-field>
                <mat-chip-set>
                    @if (filter.showActiveOnly) {
                        <mat-chip
                        >Active
                            <button matChipRemove aria-label="Remove filter: Active" (click)="toggleShowActiveOnly()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    }
                    @if (filter.showBillableOnly) {
                        <mat-chip
                        >Billable
                            <button
                                matChipRemove
                                aria-label="Remove filter: Billable"
                                (click)="toggleShowBillableOnly()"
                            >
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    }
                </mat-chip-set>
            </div>

            @if (canCreateSubscriptions()) {
                <button mat-stroked-button (click)="addSubscriptions()">
                    <mat-icon>add</mat-icon>
                    Add Subscription
                </button>
            }
        </div>

        <table
            mat-table
            class="mat-elevation-z4"
            #subscriptionTable="matTable"
            [dataSource]="displayedSubscription"
            [trackBy]="subscriptionId"
        >
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let subscription">
                    <div class="subscription-label">
                        @if (!$subscription(subscription).active) {
                            <mat-icon color="warn">report_off</mat-icon>
                        }
                        <a [routerLink]="'./' + $subscription(subscription).id">{{
                                $subscription(subscription).label
                            }}</a>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="contact">
                <th mat-header-cell *matHeaderCellDef>Contact</th>
                <td mat-cell *matCellDef="let subscription" adminCopyToClipboard>
                    {{ $subscription(subscription).contact }}
                </td>
            </ng-container>
            <ng-container matColumnDef="billable">
                <th mat-header-cell *matHeaderCellDef>Billable</th>
                <td mat-cell *matCellDef="let subscription">
                    @if ($subscription(subscription).billing?.billingStartDateUtc) {
                        @if (canReadInvoice($subscription(subscription))) {
                            <a [routerLink]="'./' + $subscription(subscription).id + '/billing'">Billable</a>
                        } @else {
                            Billable
                        }
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="storeCount">
                <th mat-header-cell *matHeaderCellDef>Store count</th>
                <td mat-cell *matCellDef="let subscription">
                    {{ $subscription(subscription).storesCount }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</admin-page-simple-content>
