@if (pageStatus !== "loaded") {
    <admin-page-simple-content
        [pageError]="pageError"
        [pageStatus]="pageStatus"
        [title]="'Edit Project'"
        [icon]="'project'"
    >
    </admin-page-simple-content>
}
<div [style.display]="pageStatus !== 'loaded' ? 'none' : undefined" #designerDiv></div>
