@if (data.mode === "create") {
    <h2 mat-dialog-title>Add remote filter</h2>
}
@if (data.mode === "edit") {
    <h2 mat-dialog-title>Edit remote filter</h2>
}
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Name (internal use)</mat-label>
            <input matInput name="name" [(ngModel)]="filterInfo.name" pattern="[a-z0-9\-]+" required />
            <mat-hint>Only use a-z and 0-9 and -</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Alias (library referencing)</mat-label>
            <input matInput name="alias" [(ngModel)]="filterInfo.alias" pattern="[a-z0-9\-]+" />
            <mat-hint>Only use a-z and 0-9 and -</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Stackable Group</mat-label>
            <input matInput name="group" [(ngModel)]="filterInfo.group" pattern="[a-z0-9\-]+" />
            <mat-hint>Filters in the same group cannot be stacked. Leave empty to use the default group</mat-hint>
            <mat-error>Only use a-z and 0-9 and -</mat-error>
        </mat-form-field>
        <div class="filter-visibility">
            <mat-form-field>
                <mat-label>Order</mat-label>
                <input matInput name="order" [(ngModel)]="filterInfo.order" type="number" />
            </mat-form-field>
            <mat-checkbox [(ngModel)]="filterInfo.visible" name="visible">Visible</mat-checkbox>
        </div>

        <mat-form-field [floatLabel]="'always'">
            <mat-label>Type of filter</mat-label>
            <mat-select placeholder="Kind" name="kind" [(ngModel)]="filterInfo.kind" aria-label="Filter kind" required>
                <mat-option [value]="RemoteFilterKind.Generic">{{
                        RemoteFilterKind.Generic | remoteFilterLabel
                    }}
                </mat-option>
                <mat-option [value]="RemoteFilterKind.PerfectlyClear">{{
                        RemoteFilterKind.PerfectlyClear | remoteFilterLabel
                    }}
                </mat-option>
                <mat-option [value]="RemoteFilterKind.PicsartRemoveBackground">{{
                        RemoteFilterKind.PicsartRemoveBackground | remoteFilterLabel
                    }}
                </mat-option>
                <mat-option [value]="RemoteFilterKind.PicsartEffects">{{
                        RemoteFilterKind.PicsartEffects | remoteFilterLabel
                    }}
                </mat-option>
                <mat-option [value]="RemoteFilterKind.PicsartStyleTransfer">{{
                        RemoteFilterKind.PicsartStyleTransfer | remoteFilterLabel
                    }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        @if (filterInfo.kind === "Generic") {
            <div class="generic-data">
                <mat-form-field>
                    <mat-label>Url</mat-label>
                    <input
                        matInput
                        name="genericDataUrl"
                        [(ngModel)]="genericData.url"
                        pattern="^https://.+"
                        placeholder="https://"
                        required
                    />
                </mat-form-field>
                @for (header of genericData.headers; track header; let i = $index) {
                    <div class="header">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput [name]="'genericDataHeaderName' + i" [(ngModel)]="header.name" required />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Value</mat-label>
                            <input matInput [name]="'genericDataHeaderValue' + i" [(ngModel)]="header.value" required />
                        </mat-form-field>
                        <button mat-icon-button (click)="removeHeader(header)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                }
                <button mat-stroked-button (click)="addHeader()">Add header</button>
            </div>
        }
        @if (filterInfo.kind === "PerfectlyClear") {
            <div class="pc-data">
                <mat-form-field>
                    <mat-label>Api key</mat-label>
                    <input
                        matInput
                        #secretInput
                        name="PerfectlyClearApiKey"
                        [(ngModel)]="perfectlyClearData.apiKey"
                        required
                    />
                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Custom preset</mat-label>
                    <input matInput [(ngModel)]="perfectlyClearData.customPreset" name="PerfectlyClearPreset" />
                </mat-form-field>
            </div>
        }
        @if (filterInfo.kind === "PicsartEffects") {
            <div class="pc-data">
                <mat-form-field>
                    <mat-label>Api key</mat-label>
                    <input
                        matInput
                        #secretInput
                        name="PicsartEffectsApiKey"
                        [(ngModel)]="picsartEffectsData.apiKey"
                        required
                    />
                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Effect</mat-label>
                    <mat-select name="PicsartEffectsEffect" required [(ngModel)]="picsartEffectsData.effect">
                        @for (effect of picsartEffectsName; track effect) {
                            <mat-option [value]="effect"> {{ effect }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        }
        @if (filterInfo.kind === "PicsartStyleTransfer") {
            <div class="pc-data">
                <mat-form-field>
                    <mat-label>Api key</mat-label>
                    <input
                        matInput
                        #secretInput
                        name="PicsartStyleTransferApiKey"
                        [(ngModel)]="picsartStyleTransferData.apiKey"
                        required
                    />
                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Level</mat-label>
                    <mat-select name="PicsartStyleTransferLevel" [(ngModel)]="picsartStyleTransferData.level" required>
                        @for (level of picsartRmStyleTransferLevels; track level) {
                            <mat-option [value]="level.value"> {{ level.display }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Reference image url (HTTPS)</mat-label>
                    <input
                        matInput
                        name="PicsartStyleTransferReferenceImageUrl"
                        [(ngModel)]="picsartStyleTransferData.referenceImageUrl"
                        pattern="^https://.+"
                        placeholder="https://"
                        required
                    />
                </mat-form-field>
            </div>
        }
        @if (filterInfo.kind === "PicsartRemoveBackground") {
            <div class="pc-data">
                <mat-form-field>
                    <mat-label>Api key</mat-label>
                    <input
                        matInput
                        #secretInput
                        name="PicsartRemoveBackgroundApiKey"
                        [(ngModel)]="picsartRemoveBackgroundData.apiKey"
                        required
                    />
                    <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                </mat-form-field>
            </div>
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="form.invalid">OK</button>
</mat-dialog-actions>
