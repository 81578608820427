import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content';
import { Invoice, RevShareCalculatedOnValues, Subscription as HubSubscription, Subscription, SubscriptionPermission, UpdateSubscriptionInput } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { UpdateSubscriptionBillingDialogComponent } from './update-subscription-billing-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { EditInvoiceDialogComponent, EditInvoiceDialogData, EditInvoiceDialogResult } from './edit-invoice-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CurrencyComponent } from '../../../shared/utilities/currency.component';
import { BaseChartDirective } from 'ng2-charts';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { AdminCurrency } from '../../../shared/utilities/admin-currency.pipe';
import { InvoiceService } from './invoice.service';
import { SubscriptionBillingService } from './subscription-billing.service';

@Component({
    styleUrls: ['./subscription-billing-page.component.scss'],
    templateUrl: './subscription-billing-page.component.html',
    imports: [PageSimpleContentComponent, MatButton, MatIcon, CurrencyComponent, BaseChartDirective, ErrorComponent, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatAnchor, MatTable, MatSort, MatColumnDef, MatHeaderCell, MatSortHeader, MatCell, MatHeaderRow, MatRow, DecimalPipe, DatePipe, FromNowPipe, AdminCurrency, MatCellDef, MatHeaderCellDef, MatHeaderRowDef, MatRowDef]
})
export class SubscriptionBillingPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly invoiceService = inject(InvoiceService);
    private readonly dialog = inject(MatDialog);
    private readonly promptDialogService = inject(PromptDialogService);
    private readonly subscriptionBillingService = inject(SubscriptionBillingService);
    private readonly authService = inject(AuthorizationService);

    private authContext: AuthorizationContext;
    subscriptionId: string;
    subscriptionWithBilling: Subscription;
    recentInvoice?: Invoice;

    revenueChart?: ChartData<'bar'>;

    showEditDialog = (element: Invoice) => {
        const dialogRef = this.dialog.open<EditInvoiceDialogComponent, EditInvoiceDialogData, EditInvoiceDialogResult>(EditInvoiceDialogComponent, {
            data: {
                value: element.invoicedAdjusted || element.invoiced,
                currency: element.currency
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.adjustInvoice({ context: this.subscriptionWithBilling, data: element, newValue: result.value });
        });
    };

    displayDate(element: any): string {
        return `${element.year}-${element.month.toString().padStart(2, '0')}`;
    }

    compare = (a: number | string, b: number | string, isAsc: boolean) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };

    announceSortChange(sort: Sort) {
        const data = this.subscriptionWithBilling.invoices.slice();
        if (!sort.active || sort.direction === '') {
            this.subscriptionWithBilling.invoices = data;
            return;
        }

        this.subscriptionWithBilling.invoices = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'date':
                    return this.compare(`${this.displayDate(a)}`, `${this.displayDate(b)}`, isAsc);
                case 'monthlyFee':
                    return this.compare(a.monthlyFee, b.monthlyFee, isAsc);
                case 'revenue':
                    return this.compare(a.revenue, b.revenue, isAsc);
                case 'linesCount':
                    return this.compare(a.linesCount ?? 0, b.linesCount ?? 0, isAsc);
                case 'invoiced':
                    return this.compare(a.invoicedAdjusted || a.invoiced, b.invoicedAdjusted || b.invoiced, isAsc);
                default:
                    return 0;
            }
        });
    }


    barChartOptions: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'month'
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                    minRotation: 0,
                    maxRotation: 0
                }
            }] as any,
            yAxes: [{
                ticks: {
                    maxTicksLimit: 5,
                    callback: this.formatYAxis
                }
            }] as any
        }
    };

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => {
            this.authContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.reloadSubscription();
            }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    private reloadSubscription() {
        this.subscribeWithGenericLoadingErrorHandling(this.subscriptionBillingService.loadForBilling(this.subscriptionId), subscriptionWithBilling => {
            this.subscriptionWithBilling = subscriptionWithBilling;

            if (this.subscriptionWithBilling?.invoices?.length) {
                const invoices = this.subscriptionWithBilling.invoices!.slice().sort((i1, i2) => {
                    if (i1.year < i2.year) {
                        return 1;
                    }
                    if (i1.year > i2.year) {
                        return -1;
                    }
                    if (i1.month < i2.month) {
                        return 1;
                    }
                    if (i1.month > i2.month) {
                        return -1;
                    }
                    return 0;
                });
                let lastInvoice = invoices[0];
                let invoiceGenerated = lastInvoice.generated;
                if (invoiceGenerated) {
                    let diffDays = (Date.now() - Date.parse(invoiceGenerated)) / 3_600_000 / 24;
                    if (diffDays < 15) {
                        this.recentInvoice = lastInvoice;
                    }
                }
                let graphInvoice = invoices.reverse();
                this.revenueChart = {
                    datasets: [
                        { data: graphInvoice.map((i) => i.revenue || 0), label: 'Revenue' },
                        { data: graphInvoice.map((i) => i.invoicedAdjusted || i.invoiced || 0), label: 'Invoiced' },
                        { data: graphInvoice.map((i) => i.linesCount || 0), label: 'Lines' }
                    ],
                    labels: graphInvoice.map((i) => i.year + '-' + i.month.toString().padStart(2, '0'))
                } as ChartData<'bar'>;

                this.announceSortChange({ active: 'date', direction: 'desc' });
            }
        });
    }

    formatYAxis(label: number): string {
        if (label >= 1000000) {
            return label / 1000000 + 'M';
        } else if (label >= 1000) {
            return label / 1000 + 'k';
        } else {
            return '' + label;
        }
    }

    adjustInvoice(params: { context: HubSubscription, data: Invoice, newValue: string }): boolean {
        const subscription: HubSubscription = params.context;
        const invoice: Invoice = params.data;
        const invoicedAdjusted = parseFloat(params.newValue);
        this.subscribeWithGenericSavinErrorHandling(this.invoiceService.adjustInvoiced({
            subscriptionId: subscription.id,
            year: invoice.year,
            month: invoice.month,
            invoicedAdjusted: invoicedAdjusted
        }), () => {
            invoice.invoicedAdjusted = invoicedAdjusted;
        });
        return true;
    }

    editBilling(subscription: HubSubscription) {
        if (!subscription.billing) {
            throw new Error('Missing billing in subscription');
        }
        const data: UpdateSubscriptionInput = {
            id: subscription.id,
            billing: {
                ...subscription.billing,
                billingStartDateUtc:
                    subscription.billing.billingStartDateUtc || new Date().toUTCString(),
                currency: subscription.billing.currency || 'USD',
                monthlyFee: subscription.billing.monthlyFee || 0,
                multiplyByQuantity: subscription.billing.multiplyByQuantity,
                revShare: subscription.billing.revShare
                    ? {
                        calculatedOn:
                            subscription.billing.revShare.calculatedOn || RevShareCalculatedOnValues.NetSellingPrice,
                        percentage: subscription.billing.revShare.percentage || 0,
                        minimum: subscription.billing.revShare.minimum || 0,
                        maximum: subscription.billing.revShare.maximum || 0
                    }
                    : {
                        calculatedOn: RevShareCalculatedOnValues.NetSellingPrice,
                        percentage: 0,
                        minimum: 0,
                        maximum: 0
                    }
            }
        };
        let dialogRef = this.dialog.open<UpdateSubscriptionBillingDialogComponent, UpdateSubscriptionInput>(
            UpdateSubscriptionBillingDialogComponent, { data }
        );
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionBillingService.edit(result), () => {
                this.reloadSubscription();
            });
        });
    }

    openAdjustInvoice(invoice: Invoice) {
        this.promptDialogService.prompt('Adjust invoice', 'Update invoiced value').subscribe(result => {
            let invoicedAdjusted = parseFloat(result);
            this.subscribeWithGenericSavinErrorHandling(this.invoiceService.adjustInvoiced({
                subscriptionId: this.subscriptionId,
                year: invoice.year,
                month: invoice.month,
                invoicedAdjusted
            }), () => {
                invoice.invoicedAdjusted = invoicedAdjusted;
            });
        });
    }

    canUpdateInvoice(): boolean {
        return this.authContext.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.UpdateInvoice);
    }

    canManageSubscription(): boolean {
        return this.authContext.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.ManageSubscriptionMetadata);
    }
}
