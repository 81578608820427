import { ActivatedRoute, RouterLink } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { OrderLine, OrderLineStatusFlags, OrderLineUnion } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { OrdersService } from './orders.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { LineStatusComponent } from './line-status.component';
import { MatRipple } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';

const allColumns = ['storeOrderId', 'dateCreatedUtc', 'status'];
const mobileColumns = ['storeOrderId', 'status'];

@Component({
    templateUrl: './orders-page.component.html',
    styleUrls: ['./orders-page.component.scss'],
    imports: [PageSimpleContentComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, LineStatusComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatRipple, RouterLink, DatePipe, FromNowPipe]
})
export class OrdersPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly mediaService = inject(FuseMatchMediaService);
    private readonly ordersService = inject(OrdersService);

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    lines: OrderLineUnion[] = [];
    displayedColumns = allColumns;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(param => {
            let storeId = param.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.ordersService.getLines(storeId, OrderLineStatusFlags.StoreActionRequired), lines => {
                this.lines = lines;
            });
        }));
        this.registerSubscription(this.mediaService.onMediaChange.subscribe((value) => {
            if (value == 'lt-md')
                this.displayedColumns = mobileColumns
            if (value == 'gt-sm')
                this.displayedColumns = allColumns
        }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    $orderLine(o: any): OrderLine {
        return o as OrderLine;
    }
}
