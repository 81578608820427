import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateFilterCategoryInput, DeleteFilterCategoryInput, FilterCategory, GraphDotnetService, UpdateFilterCategoryInput } from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FilterCategoryService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    loadStoreFilterCategories(storeId: string): Observable<any> {
        return this.graphDotnetService.query(`
                query filterCategories($storeId: String!) {
                    filterCategories(storeId: $storeId) {
                        id
	                    label
                        builtInFilters
                        remoteFilters {
                            id
                            name
                            urlThumb
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.filterCategories));
    }

    createFilterCategory(input: CreateFilterCategoryInput): Observable<FilterCategory> {
        return this.graphDotnetService.mutate(
            `mutation CreateFilterCategory($input: CreateFilterCategoryInput!) {
                createFilterCategory(input: $input) {
                    id
                    label
                    builtInFilters
                    remoteFilters {
                        id
                        name
                        urlThumb
                    }
                }
            }`, { input }
        ).pipe(map(x => x.createFilterCategory));
    }

    deleteFilterCategory(input: DeleteFilterCategoryInput): Observable<void> {
        return this.graphDotnetService.mutate(
            `mutation DeleteFilterCategory($input: DeleteFilterCategoryInput!) {
                deleteFilterCategory(input: $input)
            }`, { input }
        ).pipe(map(x => x.deleteFilterCategory));
    }

    updateFilterCategory(input: UpdateFilterCategoryInput): Observable<void> {
        return this.graphDotnetService.mutate(
            `mutation UpdateFilterCategory($input: UpdateFilterCategoryInput!) {
                updateFilterCategory(input: $input)
            }`, { input }
        ).pipe(map(x => x.updateFilterCategory));
    }
}
