import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GeneratedImageConfigProperty, GeneratedImageData, GeneratedImageKind } from 'app/shared/graph';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AutoTrimDirective } from '../../../shared/utilities/auto-trim.directive';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';

@Component({
    selector: 'admin-generated-image-config-editor',
    templateUrl: './generated-image-config-editor.component.html',
    styleUrls: ['./generated-image-config-editor.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [MatFormField, MatLabel, MatInput, FormsModule, MatCheckbox, MatIconButton, MatIcon, MatButton, AutoTrimDirective, SecretInputToggleComponent, MatSuffix, MatHint]
})
export class GeneratedImageConfigEditorComponent {
    public readonly GeneratedImageKind = GeneratedImageKind;

    @Input()
    kind: GeneratedImageKind;
    @Input()
    isDefinition: boolean
    @Input()
    configs: GeneratedImageData;
    @Input()
    mapBoxAccessKey: string;
    @Output()
    mapBoxAccessKeyChange: EventEmitter<string> = new EventEmitter<string>();

    removeProperty(properties: Array<GeneratedImageConfigProperty>, index: number): void {
        properties.splice(index, 1);
    }

    addProperty(properties: Array<GeneratedImageConfigProperty>): void {
        properties.push({
            name: '',
            value: '',
            isSecret: false
        });
    }
}
