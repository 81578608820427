<admin-page-simple-content
    id="remote-filters"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Designer: Remote Filters Settings'"
    [icon]="'blur_on'"
>
    <div class="content">
        <section class="header">
            <mat-form-field class="dense-2" floatLabel="always">
                <mat-icon matPrefix>search</mat-icon>
                <mat-label>Filter</mat-label>
                <input matInput (keydown)="updateFilter()" (keyup)="updateFilter()" [(ngModel)]="filter.name" />
            </mat-form-field>

            <button mat-stroked-button (click)="openCreateFilterDialog()">
                <mat-icon>add</mat-icon>
                Add Remote Filter
            </button>
        </section>

        <table
            mat-table
            #storeRemoteFiltersTable="matTable"
            [dataSource]="displayedRemoteFilters"
            [trackBy]="remoteFilterId"
            class="sources-table mat-elevation-z4"
        >
            <ng-container matColumnDef="thumbnail">
                <mat-header-cell *matHeaderCellDef>Thumbnail</mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    <div class="thumbnail">
                        @if (remoteFilter.urlThumb) {
                            <img [src]="remoteFilter.urlThumb" height="48" width="48"  alt=""/>
                        }
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="visible">
                <mat-header-cell *matHeaderCellDef>Visible</mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    @if (remoteFilter.visible) {
                        <mat-icon>check</mat-icon>
                    }
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="order">
                <mat-header-cell *matHeaderCellDef>Order</mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    {{ remoteFilter.order }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    <div>
                        <span>{{ remoteFilter.name }}</span>
                        <div class="alias">
                            @if (remoteFilter.alias) {
                                <span>{{ remoteFilter.alias }}</span>
                            }
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>Stackable Group</mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    {{ remoteFilter.group }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let remoteFilter">
                    <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                        <ng-container>
                            <input
                                #file
                                type="file"
                                accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                                (change)="uploadRemoteFilterThumbnail(remoteFilter, $any($event.target).files)"
                                style="display: none"
                            />

                            <button mat-menu-item (click)="openEditRemoteFilter(remoteFilter)">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                            @if (!remoteFilter.urlThumb) {
                                <button mat-menu-item (click)="file.click()">
                                    <mat-icon>image</mat-icon>
                                    Configure static thumbnail
                                </button>
                            }
                            @if (remoteFilter.urlThumb) {
                                <button mat-menu-item (click)="removeRemoteFilterThumbnail(remoteFilter)">
                                    <mat-icon color="warn">hide_image</mat-icon>
                                    Remove static thumbnail
                                </button>
                            }
                        </ng-container>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>

        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
    </div>
</admin-page-simple-content>
