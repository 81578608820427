<h2 mat-dialog-title>Create New Fulfiller App</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Display name</mat-label>
            <input name="fulfillerId" matInput [(ngModel)]="fulfillerInfo.displayName" required />
            <mat-hint>Display name to identify the app later</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>

    <button mat-button color="primary" (click)="valid()" [disabled]="!form.valid">CREATE</button>
</mat-dialog-actions>
