<mat-card matRipple (click)="selectStore.next(store)">
    <mat-card-header>
        <mat-card-title>
            {{ store.storeLabel || store.storeId }}
            <mat-chip-set>
                <mat-chip [ngClass]="['store-env-' + store.storeEnvironment]">
                    {{ store.storeEnvironment | shortStoreEnv }}
                </mat-chip>
            </mat-chip-set>
        </mat-card-title>
        <mat-card-subtitle>
            <span class="store-id">{{ store.storeId }}</span>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content></mat-card-content>
</mat-card>
