import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { FilterCategory, LocalFilterDefinition, RemoteFilter } from 'app/shared/graph';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatError, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

export interface EditFilterCategoryDialogData {
    mode: 'create' | 'edit';
    filterBuiltIn: LocalFilterDefinition[];
    remoteFilters: RemoteFilter[]
    filterCategory: FilterCategory;
    filterCategories: Array<FilterCategory>;
}

export interface EditFilterCategoryDialogResult {
    id?: string;
    label: string;
    builtInFilter: string[];
    remoteFilters: string[];
}

@Component({
    selector: 'admin-edit-filter-category.component',
    templateUrl: './edit-filter-category.component.html',
    styleUrls: ['./edit-filter-category.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatError, MatHint, MatSelect, ReactiveFormsModule, MatOption, MatDialogActions, MatButton]
})
export class EditFilterCategoryDialogComponent {
    dialogRef = inject<MatDialogRef<EditFilterCategoryDialogComponent>>(MatDialogRef);
    readonly data = inject<EditFilterCategoryDialogData>(MAT_DIALOG_DATA);

    public filterCategoryInfo: EditFilterCategoryDialogResult;
    public storeId: string;
    label: string;
    builtInFilters= new FormControl();
    remoteFilters= new FormControl();
    IsValid = true;

    constructor(){
        const data = this.data;

        if (data.filterCategory){
            this.filterCategoryInfo = {
                id: data.filterCategory.id,
                label: data.filterCategory.label,
                builtInFilter: [],
                remoteFilters: []
            };
            this.builtInFilters.setValue(data.filterCategory.builtInFilters);
            this.remoteFilters.setValue(data.filterCategory.remoteFilters.map(rf => rf.id));
        } else {
            this.filterCategoryInfo = {
                label: '',
                builtInFilter: [],
                remoteFilters: [],
            }
        }
    }

    verifyUnicityLabel(event: string){
        if (this.data.mode === "create" )
            this.IsValid = this.data.filterCategories.findIndex(x => x.label == event) == -1;
        else
            this.IsValid = this.data.filterCategories.findIndex(x => x.label == event && x.id != this.data.filterCategory.id) == -1;
    }

    valid() {
        this.filterCategoryInfo.builtInFilter = this.builtInFilters.value;
        this.filterCategoryInfo.remoteFilters = this.remoteFilters.value;
        this.dialogRef.close(this.filterCategoryInfo);
    }

    cancel() {
        this.dialogRef.close();
    }
}
