<h2 mat-dialog-title>Add new photo source</h2>
<mat-dialog-content>
    <p class="mat-body">
        Choose an unique identifier for this new photo source. It should be unique between all your photo sources and
        must only contains lowercase characters and numbers.
    </p>
    <mat-form-field>
        <mat-label>Photo source id</mat-label>
        <input
            matInput
            #photoSourceIdInput
            id="newPhotoSourceId"
            autocomplete="off"
            name="photoSourceId"
            type="text"
            required
            [formControl]="photoSourceIdControl"
            (keydown.enter)="create(photoSourceIdInput.value)"
        />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button (click)="create(photoSourceIdInput.value)" [disabled]="!photoSourceIdControl.valid">
        CREATE
    </button>
</mat-dialog-actions>
