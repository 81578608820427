import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export interface PromptDialogData {
    title: string;
    message?: string;
    defaultValue?: string;
    label?: string;
}

@Component({
    selector: 'admin-prompt-dialog',
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class PromptDialogComponent {
    data = inject<PromptDialogData>(MAT_DIALOG_DATA);

    public value: string;

    constructor() {
        const data = this.data;

        this.value = data.defaultValue || '';
    }
}
