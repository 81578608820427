import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Fulfiller, FulfillerRole, FulfillerUserRole, GlobalPermission } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import {
    AddAuthorizedUserDialogComponent,
    AddAuthorizedUserDialogData,
    AddAuthorizedUserDialogResult
} from 'app/shared/permission/add-authorized-user-dialog.component';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { UsersRolesManagementComponent } from 'app/shared/permission/users-roles-management.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { FulfillerService } from './fulfiller.service';

@Component({
    templateUrl: './fulfiller-authorized-user.component.html',
    styleUrls: ['./fulfiller-authorized-user.component.scss'],
    imports: [PageSimpleContentComponent, UsersRolesManagementComponent, ErrorComponent]
})
export class FulfillerAuthorizedUserComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly route = inject(ActivatedRoute);
    private readonly fulfillerService = inject(FulfillerService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly dialog = inject(MatDialog);

    fulfillerId: string;
    fulfillerRoles: FulfillerRole[] = [];
    fulfillerWithAuthorizedUsers?: Fulfiller | null;
    authContext: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.fulfillerId = params.fulfillerId;
                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAvailableRoles(),
                    this.fulfillerService.getAuthorizedUsers(this.fulfillerId)
                ]), ([availableRoles, fulfillerWithAuthorizedUsers]) => {
                    this.fulfillerRoles = availableRoles.fulfillerRoles;
                    this.fulfillerWithAuthorizedUsers = fulfillerWithAuthorizedUsers;
                });
            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    canManageUsers() {
        return this.authContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    openAddFulfillerRole(fulfiller: Fulfiller, user?: FulfillerUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<FulfillerRole>, AddAuthorizedUserDialogData<FulfillerRole>, AddAuthorizedUserDialogResult<FulfillerRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.fulfillerRoles.filter(role => !user || !user.roles.includes(role)),
                    label: fulfiller.id
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRoleOnFulfiller({
                email: result.email,
                fulfillerAccess: { id: fulfiller.id, roles: [result.role] }
            }), user => {
                let existingUser = fulfiller.authorizedUsers.find(e => e.user?.email === user.email);
                if (existingUser) {
                    existingUser.roles.push(result.role);
                } else {
                    fulfiller.authorizedUsers.push({
                        user,
                        roles: [result.role]
                    });
                }
            });
        });
    }

    removeFulfillerRole(fulfiller: Fulfiller, email: string, role: FulfillerRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                fulfillers: [{ id: fulfiller.id, roles: [role] }]
            }), () => {
                let user = fulfiller.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }
}
