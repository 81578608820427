import { PromptDialogComponent, PromptDialogData } from './prompt-dialog.component';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PromptDialogService {
    private readonly dialog = inject(MatDialog);


    prompt(title: string, message?: string, options?: { defaultValue?: string, fieldLabel?: string }): Observable<string> {
        const dialogRef = this.dialog.open<PromptDialogComponent, PromptDialogData, string>(PromptDialogComponent, {
            data: { title: title, message: message, defaultValue: options?.defaultValue, label: options?.fieldLabel } as PromptDialogData
        });
        return dialogRef.afterClosed().pipe(
            filter(r => !!r),
            map(r => r!)
        );
    }

}
