import { Component, inject } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface AddSubscriptionDialogResult {
    subscriptionLabel: string;
}

@Component({
    selector: 'admin-add-subscription-dialog',
    templateUrl: './add-subscription-dialog.component.html',
    styleUrls: ['./add-subscription-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatDialogActions, MatButton, MatDialogClose]
})
export class AddSubscriptionDialogComponent {
    dialogRef = inject<MatDialogRef<AddSubscriptionDialogComponent>>(MatDialogRef);

    subscriptionInfo: AddSubscriptionDialogResult = {
        subscriptionLabel: '',
    };

    valid() {
        this.dialogRef.close(this.subscriptionInfo);
    }
}
