<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Settings: Fulfiller: ' + fulfillerId"
    [icon]="'print'"
>
    @if (storeFulfiller) {
        <div class="content">
            <h1>Fulfiller configuration</h1>
            @if (storeFulfiller.fulfiller?.form?.documentationLink) {
                <div class="documentation-link">
                    Documentation:
                    <a target="_blank" class="external" [attr.href]="fulfillerForm.documentationLink">
                        {{ fulfillerForm.documentationLink }}
                        <mat-icon>open_in_new</mat-icon>
                    </a>
                </div>
            }
            <form #form="ngForm" (ngSubmit)="save()">
                @if (!this.isFirstStoreFulfiller) {
                    <div class="config-checkbox">
                        <mat-checkbox name="isDefault" [(ngModel)]="storeFulfiller.isDefault"
                        >This is the default fulfiller
                        </mat-checkbox>
                        <span class="mat-caption">
                            Use this fulfiller when no fulfiller is specified in checkout order request
                        </span>
                    </div>
                }
                @if (fulfillerForm) {
                    @for (field of fulfillerForm.fields; track field) {
                        @switch (field.type) {
                            @case ("Text") {
                                <mat-form-field>
                                    <mat-label>{{ field.label }}</mat-label>
                                    <input
                                        matInput
                                        [name]="field.key"
                                        [ngModel]="getField(storeFulfiller.configs, field.key)"
                                        (ngModelChange)="updateValue(storeFulfiller.configs, field.key, $event)"
                                        [required]="field.required"
                                        type="text"
                                        autocomplete="off"
                                    />
                                    <mat-hint>{{ field.description }}</mat-hint>
                                </mat-form-field>
                            }
                            @case ("Password") {
                                <mat-form-field>
                                    <mat-label>{{ field.label }}</mat-label>
                                    <input
                                        matInput
                                        [name]="field.key"
                                        [ngModel]="getField(storeFulfiller.configs, field.key)"
                                        (ngModelChange)="updateValue(storeFulfiller.configs, field.key, $event)"
                                        [required]="field.required"
                                        type="password"
                                        autocomplete="off"
                                    />
                                    <mat-hint>{{ field.description }}</mat-hint>
                                </mat-form-field>
                            }
                            @case ("CarrierIdentifier") {
                                <div class="mat-form-field-custom">
                                    <div class="header">
                                        <div class="config-checkbox">
                                            <mat-checkbox
                                                [name]="field.key"
                                                #useCustomMappingScript
                                                [checked]="!!getField(storeFulfiller.configs, field.key)"
                                                (change)="updateValue(storeFulfiller.configs, field.key, undefined)"
                                            >
                                                Configure a custom script
                                            </mat-checkbox>
                                            <span class="mat-caption">{{ field.description }}</span>
                                        </div>
                                        @if (field.sampleValue && useCustomMappingScript.checked) {
                                            <button
                                                mat-stroked-button
                                                type="button"
                                                class="insert-example"
                                                (click)="
                                                    updateValue(
                                                        storeFulfiller.configs,
                                                        field.key,
                                                        (getField(storeFulfiller.configs, field.key) || '') +
                                                            field.sampleValue
                                                    )
                                                "
                                            >
                                                Insert example
                                            </button>
                                        }
                                    </div>
                                    @if (useCustomMappingScript.checked) {
                                        <ngx-codemirror
                                            [required]="field.required"
                                            [name]="field.key"
                                            [ngModel]="getField(storeFulfiller.configs, field.key)"
                                            (ngModelChange)="updateValue(storeFulfiller.configs, field.key, $event)"
                                            [options]="{
                                                lineNumbers: true,
                                                theme: 'material',
                                                mode: 'javascript',
                                            }"
                                        ></ngx-codemirror>
                                    }
                                </div>
                            }
                            @case ("Json") {
                                <div class="mat-form-field-custom field-json">
                                    <div class="label">
                                        <label>{{ field.label }}</label>
                                        @if (field.sampleValue) {
                                            <button
                                                mat-button
                                                type="button"
                                                class="insert-example"
                                                (click)="
                                                    updateValue(
                                                        storeFulfiller.configs,
                                                        field.key,
                                                        (getField(storeFulfiller.configs, field.key) || '') +
                                                            field.sampleValue
                                                    )
                                                "
                                            >
                                                Insert example
                                            </button>
                                        }
                                    </div>
                                    <ngx-codemirror
                                        [name]="field.key"
                                        [required]="field.required"
                                        [ngModel]="getField(storeFulfiller.configs, field.key)"
                                        (ngModelChange)="updateValue(storeFulfiller.configs, field.key, $event)"
                                        [ngModelOptions]="{ standalone: true }"
                                        [options]="{
                                            lineNumbers: true,
                                            theme: 'material',
                                            mode: { name: 'javascript', json: true },
                                        }"
                                    ></ngx-codemirror>
                                    <mat-hint>{{ field.description }}</mat-hint>
                                </div>
                            }
                            @default {
                                <div>Unknown type '{{ field.type }}'</div>
                            }
                        }
                    }
                }
                <div class="config-checkbox">
                    <mat-checkbox
                        #logCheckbox
                        [checked]="isLogEnable(storeFulfiller.configs)"
                        (change)="toggleLog($event.checked, storeFulfiller.configs)"
                    >
                        Enable Request/Response Logs
                        @if (isLogEnable(storeFulfiller.configs)) {
                            <span class="mat-caption">
                                (Will automatically be disable
                                {{ getField(storeFulfiller.configs, "Mediaclip_EnableLog") | fromNow }})
                            </span>
                        }
                    </mat-checkbox>
                    <span class="mat-caption">
                        Enable details logging for all requests that are sent to this fulfiller and all responses
                        received. This option should only be enable temporary for debug purpose only. Logging will be
                        automatically disabled after the specified time.
                    </span>
                </div>
                <admin-save-button [disabled]="!form.valid" [pageStatus]="pageStatus" useContent="true">
                    @if (mode === "edit") {
                        Save
                    }
                    @if (mode === "create") {
                        Create
                    }
                </admin-save-button>
            </form>
            <div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
            </div>
        </div>
    }
</admin-page-simple-content>
