<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Settings: Endpoints'"
    [icon]="'settings_ethernet'"
>
    @if (storeConfig) {
        <div class="content">
            <form #form="ngForm" (ngSubmit)="save()">
                <mat-form-field>
                    <mat-label>Default cart page</mat-label>
                    <input
                        matInput
                        [(ngModel)]="storeConfig.cartRedirectionPage.url"
                        name="cartRedirectionPageUrl"
                        placeholder="https://"
                        [pattern]="urlPattern"
                        type="url"
                        autocomplete="off"
                    />
                    <mat-error>{{ invalidUrlMessage }}</mat-error>
                    <mat-hint>
                        The shopping cart page on your store (when adding to cart, if the "Add To Cart" endpoint is not
                        configured).
                    </mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Exit page</mat-label>
                    <input
                        matInput
                        [(ngModel)]="storeConfig.exitPage.url"
                        name="exitPageUrl"
                        placeholder="https://"
                        [pattern]="urlPattern"
                        type="url"
                        autocomplete="off"
                    />
                    <mat-error>{{ invalidUrlMessage }}</mat-error>
                    <mat-hint>The store home page.</mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Login page</mat-label>
                    <input
                        matInput
                        [(ngModel)]="storeConfig.loginPage.url"
                        name="loginPageUrl"
                        placeholder="https://"
                        [pattern]="urlPattern"
                        type="url"
                        autocomplete="off"
                    />
                    <mat-error>{{ invalidUrlMessage }}</mat-error>
                    <mat-hint
                    >The url where the user need to be redirected when a login is required in the designer. You can
                        use <code>&lcub;ProjectId&rcub;</code> in the url
                    </mat-hint>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Corrections completed page</mat-label>
                    <input
                        matInput
                        [(ngModel)]="storeConfig.correctionsCompletedPage.url"
                        name="correctionsCompletedPageUrl"
                        placeholder="https://"
                        [pattern]="urlPattern"
                        type="url"
                        autocomplete="off"
                    />
                    <mat-error>{{ invalidUrlMessage }}</mat-error>
                    <mat-hint>
                        When the
                        <a href="https://doc.mediaclip.ca/hub/features/proofing/" rel="external" target="_blank"
                        >proofing workflow</a
                        >
                        is enabled, where to redirect once corrections are complete.
                    </mat-hint>
                </mat-form-field>

                <admin-store-endpoint [endpoint]="storeConfig.addToCartEndpoint">
                    <h2 title>
                        <mat-icon>shopping_cart</mat-icon>
                        Add to Cart Endpoint
                    </h2>
                    <mat-hint>
                        When adding to cart, this endpoint should create the line item in the shopping cart.
                        <a
                            href="https://doc.mediaclip.ca/hub/store-endpoints/add-to-cart/"
                            rel="external"
                            target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.getPriceEndpoint">
                    <h2 title>
                        <mat-icon>attach_money</mat-icon>
                        Get Products Price Endpoint
                    </h2>
                    <mat-hint>
                        Fetch the price to show for a project.
                        <a href="https://doc.mediaclip.ca/hub/store-endpoints/get-price/" rel="external" target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.getProductsPriceEndpoint">
                    <h2 title>
                        <mat-icon>list</mat-icon>
                        Get Products Price Endpoint
                    </h2>
                    <mat-hint>
                        Fetch the price to show for a list of products.
                        <a
                            href="https://doc.mediaclip.ca/hub/store-endpoints/get-products-price/"
                            rel="external"
                            target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.getProductQuantitiesEndpoint">
                    <h2 title>
                        <mat-icon>production_quantity_limits</mat-icon>
                        Get Product Quantities Endpoint
                    </h2>
                    <mat-hint>
                        Fetch the product bundle quantities and associated prices.<a
                        href="https://doc.mediaclip.ca/hub/store-endpoints/get-product-quantities/"
                        rel="external"
                        target="_blank"
                    >More info...</a
                    >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.getAlternateProductsEndpoint">
                    <h2 title>
                        <mat-icon>rule_settings</mat-icon>
                        Get Alternate Products Endpoint
                    </h2>
                    <mat-hint>
                        Fetch the product alternative products.<a
                        href="https://doc.mediaclip.ca/hub/store-endpoints/get-alternative-products/"
                        rel="external"
                        target="_blank"
                    >More info...</a
                    >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.getProductDisplaySettingsEndpoint">
                    <h2 title>
                        <mat-icon>display_settings</mat-icon>
                        Get Product Display Settings Endpoint
                    </h2>
                    <mat-hint>
                        Endpoint used to load the product title and the alternative orientation of a project in the
                        designer.
                        <a
                            href="https://doc.mediaclip.ca/hub/store-endpoints/product-display-settings/"
                            rel="external"
                            target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.statusUpdateEndpoint">
                    <h2 title>
                        <mat-icon>sync_alt</mat-icon>
                        Status Update Endpoint
                    </h2>
                    <mat-hint>
                        Receive updates after an order has been checked out.
                        <a
                            href="https://doc.mediaclip.ca/hub/store-endpoints/status-update/"
                            rel="external"
                            target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-store-endpoint [endpoint]="storeConfig.onProjectDeletedEndpoint">
                    <h2 title>
                        <mat-icon>delete</mat-icon>
                        On Project Deleted Endpoint
                    </h2>
                    <mat-hint>
                        Once a project has been deleted.
                        <a
                            href="https://doc.mediaclip.ca/hub/store-endpoints/on-project-deleted/"
                            rel="external"
                            target="_blank"
                        >More info...</a
                        >
                    </mat-hint>
                </admin-store-endpoint>

                <admin-save-button [disabled]="!form.valid" [pageStatus]="pageStatus"></admin-save-button>
            </form>
        </div>
    }

    <div>
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
    </div>
</admin-page-simple-content>
