<ng-content select="[title]"></ng-content>
<!-- region Add to Cart Endpoint -->
<mat-form-field>
    <mat-label>Url</mat-label>
    <input
        matInput
        [(ngModel)]="endpoint.url"
        [required]="endpoint.headers.length > 0"
        name="addToCartEndpointUrl"
        placeholder="https://"
        [pattern]="urlPattern"
        type="url"
        autocomplete="off"
    />
    <mat-error>{{ invalidUrlMessage }}</mat-error>
    <mat-hint>
        <ng-content select="mat-hint"></ng-content>
    </mat-hint>
</mat-form-field>
<div class="headers-container">
    @for (header of endpoint.headers; track header; let i = $index) {
        <div class="header-container">
            <mat-form-field>
                <mat-label>Header Name</mat-label>
                <input matInput [(ngModel)]="header.name" [name]="'headerName' + i" required autocomplete="off" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Header Value</mat-label>
                <input
                    #secretInput
                    matInput
                    [(ngModel)]="header.value"
                    [name]="'headerValue' + i"
                    required
                    autocomplete="off"
                />
                <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
            </mat-form-field>
            <div class="actions">
                <button mat-icon-button type="button" (click)="removeHeader(endpoint.headers, i)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    }
    <button mat-stroked-button type="button" (click)="addHeader(endpoint.headers)" class="add-header">
        <mat-icon>add</mat-icon>
        Add header
    </button>
</div>
<!-- endregion -->
