import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from './navigation/navigation.service';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';
import { LayoutComponent } from './layout/layout.component';

@Component({
    selector: 'app', // eslint-disable-line
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [FuseProgressBarComponent, LayoutComponent]
})
export class AppComponent implements OnInit, OnDestroy {
    private document = inject(DOCUMENT);
    private _fuseConfigService = inject(FuseConfigService);
    private _fuseNavigationService = inject(FuseNavigationService);
    private _fuseSidebarService = inject(FuseSidebarService);
    private _fuseSplashScreenService = inject(FuseSplashScreenService);
    private _platform = inject(Platform);
    private navigationService = inject(NavigationService);

    fuseConfig: any;

    private _unsubscribeAll: Subject<any>;

    constructor() {
        // Register the navigation to the service
        this._fuseNavigationService.register('main', []);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.navigationService.navigation$.subscribe(navigation => {
            this._fuseNavigationService.unregister('main');
            this._fuseNavigationService.register('main', navigation);
            this._fuseNavigationService.setCurrentNavigation('main');
        });

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility
            for (let i = 0; i < this.document.body.classList.length; i++) {
                const className = this.document.body.classList[i];

                if (className.startsWith('theme-')) {
                    this.document.body.classList.remove(className);
                }
            }

            this.document.body.classList.add(this.fuseConfig.colorTheme);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key: string): void {
        this._fuseSidebarService.getSidebar(key)!.toggleOpen();
    }
}
