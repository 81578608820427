import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class SubscriptionPermissionService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getAuthorizedUsers(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        authorizedUsers {
                            user {
                                email
                                picture
                            }
                            roles
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }
}
