<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Manage admin user'"
    [icon]="'save'"
>
    @if (user) {
        <div class="content">
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
            <div class="header">
                <img [src]="user.user.picture" class="user-avatar" />
                <h2 adminCopyToClipboard>{{ user.user.email }}</h2>
            </div>
            <section class="info">
                <h3>Info</h3>
                <ul>
                    <li>
                        Auth0 Id: <code adminCopyToClipboard>{{ user.user.id }}</code>
                    </li>
                    @if (user.user.lastLoginDate) {
                        <li>
                            Last login:
                            {{ user.user.lastLoginDate | date: "full" }}
                            <span>({{ user.user.lastLoginDate | fromNow }})</span>
                        </li>
                    }
                </ul>
            </section>
            <section class="global-roles">
                <h3>Global Roles</h3>
                <mat-chip-set>
                    @for (role of user.globalRoles; track role) {
                        <mat-chip>
                            {{ role }}
                            @if (canManageUser()) {
                                <button
                                    matChipRemove
                                    (click)="removeGlobalRole(user, role)"
                                    [disabled]="pageStatus === 'saving'"
                                    aria-label="Remove role: {{ role }}"
                                >
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            }
                        </mat-chip>
                    }
                    @if (canManageUser()) {
                        <mat-chip [disabled]="pageStatus === 'saving'" (click)="openAddGlobalRole(user)">
                            <mat-icon>add</mat-icon>
                        </mat-chip>
                    }
                </mat-chip-set>
            </section>
            <section class="downloads">
                <h3>Authorized downloads</h3>
                <div class="download-list">
                    <mat-chip-set>
                        @for (download of user.downloads; track download) {
                            <mat-chip>
                                {{ download }}
                                @if (canManageUser()) {
                                    <button
                                        matChipRemove
                                        (click)="removeDownload(user, download)"
                                        [disabled]="pageStatus === 'saving'"
                                        aria-label="Remove download: {{ download }}"
                                    >
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                }
                            </mat-chip>
                        }
                    </mat-chip-set>
                    <form #form="ngForm" (ngSubmit)="addDownload(downloadValue)">
                        <mat-form-field class="dense-2">
                            <mat-label>New download</mat-label>
                            <input
                                matInput
                                name="downloadValue"
                                [(ngModel)]="downloadValue"
                                [matAutocomplete]="auto"
                                required
                            />
                        </mat-form-field>
                        <mat-autocomplete #auto="matAutocomplete">
                            @for (download of filterDownloads(availableDownloads); track download) {
                                <mat-option [value]="download.path">
                                    {{ download.path }}
                                </mat-option>
                            }
                        </mat-autocomplete>
                        <button mat-stroked-button type="submit" [disabled]="form.invalid || pageStatus === 'saving'">
                            <mat-icon>add</mat-icon>
                            Add
                        </button>
                    </form>
                </div>
            </section>
        </div>
    }
</admin-page-simple-content>
