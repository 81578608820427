import { Component, OnInit, inject } from '@angular/core';
import { Subscription, SubscriptionPermission, SubscriptionRole, SubscriptionUserRole } from 'app/shared/graph';
import { AddAuthorizedUserDialogComponent, AddAuthorizedUserDialogData, AddAuthorizedUserDialogResult } from 'app/shared/permission/add-authorized-user-dialog.component';
import { forkJoin } from 'rxjs';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { UsersRolesManagementComponent } from 'app/shared/permission/users-roles-management.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { PageStatus } from '../../../layout/content/page-status';
import { SubscriptionPermissionService } from './subscription-permission.service';

@Component({
    templateUrl: './subscription-authorized-user-page.component.html',
    styleUrls: ['./subscription-authorized-user-page.component.scss'],
    imports: [PageSimpleContentComponent, UsersRolesManagementComponent, ErrorComponent]
})
export class SubscriptionAuthorizedUserPageComponent extends AdminComponentBase implements OnInit {
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly subscriptionPermissionService = inject(SubscriptionPermissionService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly route = inject(ActivatedRoute);
    private readonly dialog = inject(MatDialog);

    public subscriptionRoles: SubscriptionRole[] = [];

    public subscriptionId: string;
    public subscriptionWithAuthorizedUsers: Subscription;
    public authContext: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.authorizedUsersService.getAvailableRoles(),
                    this.subscriptionPermissionService.getAuthorizedUsers(this.subscriptionId)
                ]), ([availableRoles, subscriptionWithAuthorizedUsers]) => {
                    this.subscriptionWithAuthorizedUsers = subscriptionWithAuthorizedUsers;
                    this.subscriptionRoles = availableRoles.subscriptionRoles;
                });
            }));
    }

    canManageUsers(subscriptionId: string) {
        return this.authContext.hasSubscriptionPermissions(subscriptionId, SubscriptionPermission.ManageAdminUsers);
    }

    openAddSubscriptionRole(subscription: Subscription, user?: SubscriptionUserRole) {
        const dialogRef = this.dialog.open<AddAuthorizedUserDialogComponent<SubscriptionRole>, AddAuthorizedUserDialogData<SubscriptionRole>, AddAuthorizedUserDialogResult<SubscriptionRole>>(AddAuthorizedUserDialogComponent, {
            data: {
                email: user?.user?.email,
                target: {
                    availableRoles: this.subscriptionRoles.filter(role => !user || !user.roles.includes(role)),
                    label: subscription.label || subscription.id
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.pageStatus = PageStatus.saving;
            this.authorizedUsersService.grantUserRoleOnSubscription({
                email: result.email,
                subscriptionAccess: { id: subscription.id, roles: [result.role] }
            }).subscribe({
                next: (user) => {
                    this.pageStatus = PageStatus.loaded;
                    let existingUser = subscription.authorizedUsers.find(e => e.user?.email === user.email);
                    if (existingUser) {
                        existingUser.roles.push(result.role);
                    } else {
                        subscription.authorizedUsers.push({
                            user,
                            roles: [result.role]
                        });
                    }
                },
                error: err => {
                    this.pageStatus = PageStatus.saveError;
                    this.pageError = err;
                }
            });
        });
    }

    removeSubscriptionRole(subscription: Subscription, email: string, role: SubscriptionRole) {
        this.confirmDialogService.confirm('Remove role', `Remove the role <code>${role}</code> for the user <code>${email}</code>?`, undefined, {
            yes: {
                label: 'Remove role',
                warn: true
            }
        }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.revokeUserRole({
                email,
                subscriptions: [{ id: subscription.id, roles: [role] }]
            }), () => {
                let user = subscription.authorizedUsers.find(e => e.user?.email == email);
                if (user) {
                    let roleIndex = user.roles.indexOf(role);
                    if (roleIndex !== -1) {
                        user.roles.splice(roleIndex, 1);
                    }
                }
            });
        });
    }
}
