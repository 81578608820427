import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { GlobalPermission, StockPhotoSourceConfig } from 'app/shared/graph';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { AdminComponentBase } from "../../../layout/content/admin-component-base";
import { MatDialog } from "@angular/material/dialog";
import { Router, RouterLink } from "@angular/router";
import {
    AddStockPhotoSourceDialogComponent,
    AddStockPhotoSourceDialogResult
} from "./add-stock-photo-source-dialog.component";
import {
    RelatedStoresStockPhotoSourceDialogComponent,
    RelatedStoresStockPhotoSourceDialogData
} from "./related-store-stock-photo-source-dialog.component";
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatPrefix, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { StockPhotoSourcesService } from './stock-photo-sources.service';

@Component({
    selector: 'admin-stock-photo-sources-page',
    templateUrl: './stock-photo-sources-page.component.html',
    styleUrls: ['./stock-photo-sources-page.component.scss'],
    imports: [PageSimpleContentComponent, MatFormField, MatIcon, MatPrefix, MatLabel, MatInput, FormsModule, MatButton, ErrorComponent, MatTable, MatColumnDef, MatHeaderCell, MatCell, RouterLink, CopyToClipboardComponent, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatHeaderRow, MatRow, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef]
})
export class StockPhotoSourcesPageContainerComponent extends AdminComponentBase implements OnInit {
    private readonly authorizationService = inject(AuthorizationService);
    private readonly stockPhotoSourcesService = inject(StockPhotoSourcesService);
    private readonly dialog = inject(MatDialog);
    private readonly router = inject(Router);


    @ViewChild('stockPhotoSourcesTable', { static: false })
    stockPhotoSourcesTable: MatTable<StockPhotoSourceConfig>;
    stockPhotoSources: StockPhotoSourceConfig[] = [];
    displayedStockPhotoSources: StockPhotoSourceConfig[] = [];
    displayedColumns: string[] = [
        'id',
        'photosEndpointUrl',
        'visibility',
        'enabled',
        'actions'
    ];
    filter: {
        name: string;
    } = {
        name: ''
    };

    private authorizationContext: AuthorizationContext;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));

        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.list(), (stockPhotoSources) => {
            this.stockPhotoSources = stockPhotoSources;
            this.updateDisplayedStockPhotoSources();
        });
    }

    updateFilter() {
        this.updateDisplayedStockPhotoSources();
    }

    private updateDisplayedStockPhotoSources() {
        this.displayedStockPhotoSources = this.stockPhotoSources
            .filter(s => this.isStockPhotoSourcesVisible(s))
            .sort((a, b) => a.id.localeCompare(b.id));
        this.stockPhotoSourcesTable.renderRows();
    }


    private isStockPhotoSourcesVisible(f: StockPhotoSourceConfig) {
        if (this.filter.name) {
            if (!f.id.toLowerCase().includes(this.filter.name.toLowerCase())) {
                return false;
            }
        }
        return true;
    }

    stockPhotoSourceId(i: number, stockPhotoSourceConfig: StockPhotoSourceConfig) {
        return stockPhotoSourceConfig.id;
    }

    addStockPhotoSource() {
        this.dialog.open<AddStockPhotoSourceDialogComponent, void, AddStockPhotoSourceDialogResult>(AddStockPhotoSourceDialogComponent).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.stockPhotoSourcesService.createStockPhotoSource({
                id: result.id,
                label: result.label,
                visible: true,
                photosEndpoint: {
                    url: ''
                }
            }), () => {
                this.router.navigate(['/stock-photo-sources', result.id]);
            });
        });
    }

    showRelatedStores(stockPhotoSourceId: string) {
        this.dialog.open<RelatedStoresStockPhotoSourceDialogComponent, RelatedStoresStockPhotoSourceDialogData, void>(
            RelatedStoresStockPhotoSourceDialogComponent,
            {
                data: {
                    stockPhotoSourceId: stockPhotoSourceId
                }
            }).afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
        });
    }

    canManageStockPhotoSource(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }


    $stockPhotoSource(source: any): StockPhotoSourceConfig {
        return source as StockPhotoSourceConfig;
    }
}
