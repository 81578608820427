import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    templateUrl: './error-404-page.component.html',
    styleUrls: ['./error-404-page.component.scss'],
    imports: [RouterLink]
})
export class Error404PageContainerComponent {
}
