<admin-page-simple-content
    id="external-photo-sources"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Edit Direct Photo Source'"
    [icon]="'perm_identity'"
>
    @if (directPhotoSource) {
        <div class="content">
            <p>
                You can find more information about the following fields in the
                <a href="https://doc.mediaclip.ca/hub/photo-sources/direct/" target="_blank">documentation</a>
            </p>
            <form (ngSubmit)="save()" #form="ngForm">
                <section class="section-config">
                    <h2 class="mat-h2">Endpoints</h2>
                    <mat-form-field>
                        <mat-label>Photo Endpoint Url</mat-label>
                        <input
                            matInput
                            [(ngModel)]="directPhotoSource.photoEndpoint.url"
                            required
                            autocomplete="off"
                            placeholder="https://"
                            pattern="^https://.+"
                            name="photoEndpointUrl"
                        />
                        <mat-error>Should starts with https://</mat-error>
                    </mat-form-field>
                    <span>Photo Endpoint Headers</span>
                    @for (header of directPhotoSource.photoEndpoint.headers; track header; let i = $index) {
                        <span class="endpoint-header">
                            <mat-form-field>
                                <mat-label>Header name</mat-label>
                                <input matInput [(ngModel)]="header.name" [name]="'headerName-' + i" required />
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Header value</mat-label>
                                <input
                                    matInput
                                    #secretInput
                                    [(ngModel)]="header.value"
                                    [name]="'headerValue-' + i"
                                    required
                                />
                                <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
                            </mat-form-field>
                            <button
                                mat-icon-button
                                type="button"
                                (click)="deleteHeader(directPhotoSource.photoEndpoint, i)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span>
                    }
                    <button mat-stroked-button type="button" (click)="addHeader(directPhotoSource.photoEndpoint)">
                        <mat-icon>add</mat-icon>
                        Add header
                    </button>
                </section>
                <admin-save-button [disabled]="!form.valid" [pageStatus]="pageStatus">Save</admin-save-button>
            </form>
            <div>
                @if (pageStatus === "saveError" && pageError) {
                    <admin-error [error]="pageError"></admin-error>
                }
            </div>
        </div>
    } @else {
        The source does not exist
    }
</admin-page-simple-content>
