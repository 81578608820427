import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';

import { AdminComponentBase, PageSimpleContentComponent, PageStatus } from '../../../layout/content';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { MatIcon } from '@angular/material/icon';
import { ErrorComponent } from 'app/shared/components/error.component';
import { DesignerCustomizationsService } from './designer-customizations.service';
import { MatButton } from "@angular/material/button";

@Component({
    selector: 'admin-store-designer-logo',
    templateUrl: './store-designer-logo.component.html',
    styleUrls: ['./store-designer-logo.component.scss'],
    imports: [PageSimpleContentComponent, SaveButtonComponent, MatIcon, ErrorComponent, MatButton]
})
export class StoreDesignerLogoComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly designerCustomizationsService = inject(DesignerCustomizationsService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    public logoUrl?: string;
    public storeId: string;
    private baseLogoUrl: string;

    @ViewChild('logo')
    public logo: HTMLImageElement;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.baseLogoUrl = `${environment.hub.hubApiUrl}/stores/${this.storeId}/config/logo`;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId), (storeFiles) => {
                this.logoUrl = storeFiles.logo?.url ?? undefined;
                this.pageStatus = PageStatus.loaded;
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    async onFilesChanged(files: FileList) {
        const contentBase64 = await blobToBase64(files[0]);

        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateLogo({
            storeId: this.storeId,
            contentBase64: contentBase64,
            mimeType: files[0].type
        }), () => {
            this.logoUrl = `${this.baseLogoUrl}?t=${new Date().getTime()}`;
        });
    }

    deleteLogo(): void {
        this.confirmDialogService
            .confirm(
                'Confirmation',
                `Do you want to delete the logo for this store?`
            ).subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateLogo({
                    storeId: this.storeId,
                    contentBase64: '',
                    mimeType: ''
                }), () => {
                    this.logoUrl = undefined;
                });
            }
        );
    }

    protected readonly PageStatus = PageStatus;
}

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const raw = (reader.result as string);
            const separator = raw.indexOf(',');
            resolve(raw.substring(separator === -1 ? 0 : separator + 1) as string);
        };
        reader.readAsDataURL(blob);
    });
}
