import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { forkJoin } from 'rxjs';

import { DesignerReleaseChannelService } from './designer-release-channel.service';
import { DesignerBranch, DesignerSelectorRule, DesignerModuleName } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { MatDivider } from '@angular/material/divider';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';

@Component({
    selector: 'admin-designer-versions',
    templateUrl: './store-designer-release-channel-configuration.component.html',
    styleUrls: ['./store-designer-release-channel-configuration.component.scss'],
    imports: [PageSimpleContentComponent, MatFormField, MatLabel, MatSelect, FormsModule, MatOption, SaveButtonComponent, MatDivider, MatInput, MatButton, ErrorComponent]
})
export class StoreDesignerVersionConfigurationComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly designerReleaseChannelService = inject(DesignerReleaseChannelService);

    storeId: string;
    designerReleaseChannel: string;
    releaseChannels: DesignerBranch[] = [];

    rules: DesignerSelectorRule[] = [];
    modules = [
        { value: null, display: '' },
        { value: DesignerModuleName.Photobook, display: DesignerModuleName.Photobook },
        { value: DesignerModuleName.Gifting, display: DesignerModuleName.Gifting },
        { value: DesignerModuleName.Print, display: DesignerModuleName.Print }
    ];

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                    this.designerReleaseChannelService.getDesignerReleaseChannels(),
                    this.designerReleaseChannelService.getDesignerReleaseChannelConfiguration(this.storeId)
                ]),
                ([branches, { designerBranch, rules }]) => {
                    this.releaseChannels = branches;
                    if (!branches.find(x => x.id === designerBranch.id)) {
                        this.releaseChannels.push(designerBranch);
                    }
                    this.designerReleaseChannel = designerBranch.id;
                    this.rules = rules ?? [];
                    this.addEmptyRule();
                });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    private addEmptyRule() {
        this.rules.push(<any>{
            weight: 1
        });
    }

    public deleteRule(rule: DesignerSelectorRule) {
        if (!confirm('Are you sure you want to delete the rule ' + rule.label)) {
            return;
        }

        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.deleteStoreDesignerReleaseChannelRule(this.storeId, rule.id), () => {
            const i = this.rules.findIndex((r) => r.id === rule.id);
            this.rules.splice(i, 1);
        });
    }

    public editRule(rule: DesignerSelectorRule) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.updateStoreDesignerReleaseChannelRule(
            this.storeId,
            rule.id,
            rule.designerBranch,
            rule.weight,
            rule.label,
            rule.module
        ));
    }


    addRule(rule: DesignerSelectorRule) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.addStoreDesignerReleaseChannelRule(
            this.storeId,
            rule.designerBranch,
            rule.weight,
            rule.label,
            rule.module
        ), addedRule => {
            rule.id = addedRule.id;
            this.addEmptyRule();
        });
    }

    isRuleValid(rule: DesignerSelectorRule) {
        return rule.designerBranch && rule.weight && rule.label;
    }

    saveDefaultDesignerReleaseChannel(designerReleaseChannel: string) {
        this.subscribeWithGenericSavinErrorHandling(this.designerReleaseChannelService.updateStoreDefaultDesignerReleaseChannel(
                this.storeId,
                designerReleaseChannel
            )
        );
    }
}
