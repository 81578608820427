import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';

import { DynamicScriptLoaderService } from './dynamic-script-loader.service';
import { HubTokenService } from './hub-token.service';
import { environment } from '../../../../environments/environment';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { ProjectsService } from './projects.service';

@Component({
    templateUrl: './mediaclip-designer.component.html',
    styleUrls: ['./mediaclip-designer.component.scss'],
    imports: [PageSimpleContentComponent]
})
export class MediaclipDesignerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly hubTokenService = inject(HubTokenService);
    private readonly dynamicScriptLoader = inject(DynamicScriptLoaderService);
    private readonly projectsService = inject(ProjectsService);

    private readonly loadHubMinJs: Promise<any>;

    @ViewChild('designerDiv', { static: true })
    designerDiv: ElementRef;

    state?: {
        storeId: string;
        projectId: string;
    } | {
        storeId: string;
        storeOrderId: string;
        storeOrderLineNumber: number;
    };

    constructor() {
        super();
        this.loadHubMinJs = this.dynamicScriptLoader.load('mediaclip-hub');
    }

    ngOnInit() {
        this.registerSubscription(
            this.route.params.subscribe(params => {
                const projectId = params['projectId'];
                if (projectId) {
                    this.startDesignerForProjectEdition(projectId);
                } else {
                    const storeId = params['storeId'];
                    const storeOrderId = params['storeOrderId'];
                    const storeOrderLineNumber = +params['storeLineNumber'];
                    this.startDesignerForLineEdition(storeId, storeOrderId, storeOrderLineNumber);
                }
            })
        );
    }

    ngOnDestroy() {
        window.mediaclip.hub.destroy(true);
        this.unsubscribeSubscriptions();
    }

    startDesignerForLineEdition(storeId: string, storeOrderId: string, storeOrderLineNumber: number) {
        this.state = {
            storeId,
            storeOrderId,
            storeOrderLineNumber
        };
        this.subscribeWithGenericLoadingErrorHandling(this.hubTokenService.generateCustomerSupportToken({
            storeId,
            storeOrderId,
            storeOrderLineNumber
        }), (async tokenInfo => {
            await this.loadHubMinJs;
            this.launchDesigner(
                {
                    scheme: tokenInfo.scheme,
                    token: tokenInfo.token
                },
                {
                    storeOrderId: storeOrderId,
                    storeOrderLineNumber: storeOrderLineNumber
                }
            );
        }));
    }

    startDesignerForProjectEdition(projectId: string) {
        this.projectsService.getProject(projectId).subscribe((project) => {
            if (project) {
                this.state = {
                    projectId: projectId,
                    storeId: project.storeId
                };
                this.subscribeWithGenericLoadingErrorHandling(this.hubTokenService.generateCustomerSupportToken({
                    storeId: project?.storeId,
                    projectId
                }), async tokenInfo => {
                    await this.loadHubMinJs;
                    this.launchDesigner(
                        {
                            scheme: tokenInfo.scheme,
                            token: tokenInfo.token
                        },
                        { projectId: projectId }
                    );
                });
            }
        });
    }

    launchDesigner(
        authorization: { scheme: string; token: string },
        settings: { projectId: string } | { storeOrderId: string; storeOrderLineNumber: number }
    ) {
        window.mediaclip.hub.init({
            hubUrl: environment.hub.hubApiUrl,
            authorization
        });

        window.mediaclip.hub.launch({
            ...settings,
            container: this.designerDiv.nativeElement,
            keepAliveUrl: 'FIXME: use function',
            onError: function(error, details) {
                console.log('An error occurred', error, details);
            },
            onSaveComplete: function() {
                console.log('Project has been saved!');
            },
            onAddToCartComplete: () => {
                let state = this.state;
                if (!state) {
                    return;
                }
                if ('projectId' in state) {
                    this.startDesignerForProjectEdition(state.projectId);
                } else {
                    const storeId = state.storeId;
                    const storeOrderId = state.storeOrderId;
                    const storeOrderLineNumber = +state.storeOrderLineNumber;
                    if (storeId && storeOrderId && storeOrderLineNumber) {
                        this.router.navigate([
                            'stores',
                            storeId,
                            'orders',
                            storeOrderId,
                            'lines',
                            storeOrderLineNumber
                        ]);
                    }
                }
            }
        });
    }
}
