<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Subscription: Billing'"
    [icon]="'receipt_long'"
>
    @if (subscriptionWithBilling) {
        <div class="content">
            <h2>Billing</h2>
            @if (!subscriptionWithBilling.billing?.billingStartDateUtc) {
                <button mat-stroked-button color="primary" (click)="editBilling(subscriptionWithBilling)">
                    <mat-icon>attach_money</mat-icon>
                    Enable and configure billing
                </button>
            }
            @if (subscriptionWithBilling.billing?.billingStartDateUtc) {
                <table>
                    <tr>
                        <td>Billing start date</td>
                        <td>
                            {{ subscriptionWithBilling.billing.billingStartDateUtc | date: "mediumDate" }}
                            ({{ subscriptionWithBilling.billing.billingStartDateUtc | fromNow }})
                        </td>
                    </tr>
                    @if (subscriptionWithBilling.billing.monthlyFee) {
                        <tr>
                            <td>Monthly fee</td>
                            <td>
                                <admin-currency
                                    [value]="subscriptionWithBilling.billing?.monthlyFee"
                                    [currency]="subscriptionWithBilling.billing?.currency"
                                ></admin-currency>
                            </td>
                        </tr>
                    }
                    @if (subscriptionWithBilling.billing.revShare && subscriptionWithBilling.billing.revShare.percentage) {
                        <tr>
                            <td>Revenue Share</td>
                            <td>
                                {{ subscriptionWithBilling.billing.revShare.percentage }}% (calculated on
                                <code>{{ subscriptionWithBilling.billing.revShare.calculatedOn }}</code
                                >)
                            </td>
                        </tr>
                    }
                    @if (subscriptionWithBilling.billing.revShare &&
                    (subscriptionWithBilling.billing.revShare.minimum ||
                        subscriptionWithBilling.billing.revShare.maximum)) {
                        <tr>
                            <td>Limit per line</td>
                            <td>
                                From {{ subscriptionWithBilling.billing.revShare.minimum || 0 }}
                                {{ subscriptionWithBilling.billing.currency }} to
                                {{ subscriptionWithBilling.billing.revShare.maximum || "∞" }}
                                {{ subscriptionWithBilling.billing.currency }}
                            </td>
                        </tr>
                    }
                </table>
                @if (canManageSubscription()) {
                    <button mat-stroked-button (click)="editBilling(subscriptionWithBilling)">
                        <mat-icon>attach_money</mat-icon>
                        Configure billing
                    </button>
                }
            }
            @if (subscriptionWithBilling.billing?.billingStartDateUtc) {
                <h2>Revenue</h2>
                @if (!subscriptionWithBilling.invoices?.length) {
                    <p>No revenue data available.</p>
                }
                @if (subscriptionWithBilling.invoices?.length) {
                    <div style="width: 100%; height: 200px">
                        @if (revenueChart) {
                            <canvas
                                baseChart
                                [datasets]="revenueChart?.datasets"
                                [labels]="revenueChart?.labels"
                                [options]="barChartOptions"
                                [type]="'bar'"
                            >
                            </canvas>
                        }
                    </div>
                }
                <div>
                    @if (pageStatus === "saveError" && pageError) {
                        <admin-error [error]="pageError"></admin-error>
                    }
                </div>
                @if (recentInvoice) {
                    <h2>
                        Last invoice
                        @if (canUpdateInvoice()) {
                            <button mat-icon-button [matMenuTriggerFor]="recentInvoiceMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        }
                    </h2>
                    <mat-menu #recentInvoiceMenu>
                        <button mat-menu-item (click)="openAdjustInvoice(recentInvoice)">Adjust invoice</button>
                    </mat-menu>
                    <div class="last-invoice">
                        <div class="invoice-field">
                            <span class="label">Invoice Period</span>
                            <span class="value">{{ recentInvoice.year }} - {{ recentInvoice.month }}</span>
                        </div>
                        <div class="invoice-field">
                            <span class="label">Monthly Fee</span>
                            <span class="value">{{
                                    recentInvoice.monthlyFee | adminCurrency: recentInvoice.currency
                                }}</span>
                        </div>
                        <div class="invoice-field">
                            <span class="label">Revenue</span>
                            <span class="value">{{
                                    recentInvoice.revenue | adminCurrency: recentInvoice.currency
                                }}</span>
                        </div>
                        <div class="invoice-field">
                            <span class="label">Order Lines</span>
                            <span class="value">{{ recentInvoice.linesCount | number }}</span>
                        </div>
                        <div class="invoice-field">
                            <span class="label">Invoiced</span>
                            <span class="value">
                                @if (recentInvoice.invoicedAdjusted) {
                                    {{ recentInvoice.invoicedAdjusted | adminCurrency: recentInvoice.currency }}
                                    Original: {{ recentInvoice.invoiced | adminCurrency: recentInvoice.currency }}
                                }
                                @if (!recentInvoice.invoicedAdjusted) {
                                    {{ recentInvoice.invoiced | adminCurrency: recentInvoice.currency }}
                                }
                            </span>
                        </div>
                        <div class="invoice-field">
                            <span class="label">Generated</span>
                            <span class="value"
                            >{{ recentInvoice.generated | date: "mediumDate" }} ({{
                                    recentInvoice.generated | fromNow
                                }})</span
                            >
                        </div>
                        <a mat-stroked-button [href]="recentInvoice?.url"> Download </a>
                    </div>
                }
                <h2>Invoices</h2>
                @if (!subscriptionWithBilling.invoices?.length) {
                    <p>No billing cycle completed yet.</p>
                }
                <table
                    mat-table
                    matSort
                    [dataSource]="subscriptionWithBilling.invoices"
                    class="mat-elevation-z8"
                    (matSortChange)="announceSortChange($event)"
                    matSortActive="date"
                    matSortDirection="desc"
                >
                    <ng-container matColumnDef="date">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
                        <td mat-cell *matCellDef="let element">{{ displayDate(element) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="monthlyFee">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Monthly Fee</th>
                        <td mat-cell *matCellDef="let element">
                            <admin-currency [value]="element.monthlyFee" [currency]="element.currency"></admin-currency>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="revenue">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Revenue</th>
                        <td mat-cell *matCellDef="let element">
                            <admin-currency [value]="element.revenue" [currency]="element.currency"></admin-currency>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="linesCount">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Lines</th>
                        <td mat-cell *matCellDef="let element">{{ element.linesCount ?? "N/A" }}</td>
                    </ng-container>
                    <ng-container matColumnDef="invoiced">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Invoiced</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="mc-display-editable_invoice">
                                <admin-currency
                                    [value]="element.invoicedAdjusted || element.invoiced"
                                    [currency]="element.currency"
                                ></admin-currency>
                                <mat-icon fontIcon="edit" (click)="showEditDialog(element)"></mat-icon>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="report">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Report</th>
                        <td mat-cell *matCellDef="let element"><a href="{{ element.url }}">Download</a></td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="['date', 'monthlyFee', 'revenue', 'linesCount', 'invoiced', 'report']"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: ['date', 'monthlyFee', 'revenue', 'linesCount', 'invoiced', 'report']
                        "
                    ></tr>
                </table>
            }
            <!-- invoices -->
        </div>
    }
</admin-page-simple-content>
