import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { StockPhotoSourceConfig } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { StockPhotoSourcesService } from './stock-photo-sources.service';

export interface RelatedStoresStockPhotoSourceDialogData {
    stockPhotoSourceId: string;
}

@Component({
    templateUrl: './related-store-stock-photo-source-dialog.component.html',
    styleUrls: ['./related-store-stock-photo-source-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatButton, MatDialogActions, MatDialogClose]
})
export class RelatedStoresStockPhotoSourceDialogComponent extends AdminComponentBase implements OnInit {
    dialogRef = inject<MatDialogRef<RelatedStoresStockPhotoSourceDialogComponent>>(MatDialogRef);
    private readonly data = inject<RelatedStoresStockPhotoSourceDialogData>(MAT_DIALOG_DATA);
    private readonly stockPhotoSourcesService = inject(StockPhotoSourcesService);
    private readonly router = inject(Router);


    stockPhotoSource: StockPhotoSourceConfig;

    ngOnInit(): void {
        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.loadStores(this.data.stockPhotoSourceId), (stockPhotoSourceConfig) => {
            this.stockPhotoSource = stockPhotoSourceConfig;
        });
    }

    openStoreSettings(storeId: string) {
        this.router.navigate(['stores', storeId, 'settings', 'external-photo-sources']);
        this.dialogRef.close();
    }
}
