import { Component, inject } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface AddStockPhotoSourceDialogResult {
    id: string;
    label: string | undefined;
}

@Component({
    templateUrl: './add-stock-photo-source-dialog.component.html',
    styleUrls: ['./add-stock-photo-source-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatError, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class AddStockPhotoSourceDialogComponent {
    dialogRef = inject<MatDialogRef<AddStockPhotoSourceDialogComponent>>(MatDialogRef);

    stockPhotoSourceInfo: AddStockPhotoSourceDialogResult = {
        id: '',
        label: undefined
    };

    valid() {
        this.dialogRef.close(this.stockPhotoSourceInfo);
    }
}
