import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { SearchResultUnion } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ViewContextService } from '../../../navigation/view-context.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { SearchService } from './search.service';

interface SearchResultDisplay {
    type: string;
    id: string;
    label: string;
    icon: string;
    url: string;
    query?: Params;
    store?: { id: string };
}

@Component({
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss'],
    imports: [PageSimpleContentComponent, MatCard, MatIcon, RouterLink]
})
export class SearchPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly viewContext = inject(ViewContextService);
    private readonly searchService = inject(SearchService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    searchResult: SearchResultDisplay[] = [];
    storeId: string | null = null;

    ngOnInit(): void {
        this.registerSubscription(this.viewContext.context$.subscribe((context) => {
            if (context.type === 'store') {
                this.storeId = context.storeId;
            } else {
                this.storeId = null;
            }
        }));
        this.registerSubscription(this.route.queryParams.subscribe(query => {
            this.search(query.q, this.storeId);
        }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    async search(query: string, storeId: string | null): Promise<void> {
        if (!query) {
            return;
        }
        this.subscribeWithGenericLoadingErrorHandling(this.searchService.search(query, storeId), result => {
            this.searchResult = result.map(x => this.toDisplay(x));
            if (this.searchResult.length === 1) {
                this.router.navigateByUrl(this.searchResult[0].url, { replaceUrl: true });
            }
        });
    }

    private toDisplay(result: SearchResultUnion): SearchResultDisplay {
        switch (result.__typename) {
            case 'OrderSearchResult':
                return {
                    type: 'Order',
                    id: result.hubOrderId,
                    label: result.storeOrderId,
                    icon: 'local_offer',
                    url: `/stores/${result.storeId}/orders/${result.storeOrderId}`
                };
            case 'OrderLineSearchResult':
                return {
                    type: 'OrderLine',
                    id: result.hubLineId,
                    label: `${result.hubLineId} (${result.hubOrderId})`,
                    icon: 'local_offer',
                    url: `/stores/${result.storeId}/orders/${result.storeOrderId}/lines/${result.storeOrderLineNumber}`
                };
            case 'ProjectSearchResult':
                return {
                    type: 'Project',
                    id: result.projectId,
                    label: result.projectId,
                    icon: 'save',
                    url: `/projects/${result.projectId}`
                };
            case 'SharedProjectSearchResult':
                return {
                    type: 'SharedProject',
                    id: result.sharedProjectId,
                    label: result.sharedProjectId,
                    icon: 'save',
                    url: `/shared-projects/${result.sharedProjectId}`
                };
            case 'UserSearchResult':
                return {
                    type: 'User',
                    id: result.hubUserId,
                    label: result.storeUserId,
                    icon: 'face',
                    url: `/users/${result.hubUserId}`
                };
            case 'SubscriptionSearchResult':
                return {
                    type: 'Subscription',
                    id: result.subscriptionId,
                    label: result.subscriptionLabel || result.subscriptionId,
                    icon: 'store',
                    url: `/subscriptions/${result.subscriptionId}`
                };
            case 'StoreSearchResult':
                return {
                    type: 'Store',
                    id: result.storeId,
                    label: (result.storeLabel || result.storeId) + ', ' + (result.subscriptionLabel || result.subscriptionId),
                    icon: 'store',
                    url: `/stores/${result.storeId}/dashboards/overview`
                };
            default:
                throw new Error(`Unknown result type: '${result.__typename}`);
        }
    }
}
