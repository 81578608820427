import { Chart, ChartConfiguration, ChartData, ChartOptions, ChartType } from 'chart.js/auto';
import { Component, Input, OnInit, inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { fontString } from 'chart.js/helpers';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { convert } from './reports-datasets-to-chart-converter';
import { BaseChartDirective } from 'ng2-charts';
import { ErrorComponent } from 'app/shared/components/error.component';
import { ReportsService } from './reports.service';

declare module 'chart.js' {
    interface PluginOptionsByType<TType extends ChartType> {
        yValuesOnTop?: { active: boolean };
        xLabelsOnTop?: { active: boolean };
    }
}

@Component({
    selector: 'admin-widget-usage-timeline',
    templateUrl: './widget-usage-timeline.component.html',
    styleUrls: ['./widget-usage-timeline.component.scss'],
    animations: fuseAnimations,
    imports: [BaseChartDirective, ErrorComponent]
})
export class WidgetUsageTimelineComponent extends AdminComponentBase implements OnInit {
    private reportsService = inject(ReportsService);

    chartOptions: ChartOptions<'line'>;
    chartData: ChartConfiguration['data'];

    @Input()
    storeId: string;

    constructor() {
        super();
        this._registerCustomChartJSPlugin();
    }

    ngOnInit() {
        this.subscribeWithGenericLoadingErrorHandling(this.reportsService.getVolumeTimelineReport(this.storeId), (rows) => {
            this.setData(convert(rows));
        });
    }

    private setData(value: ChartData<'line'>) {
        if (!value) {
            return;
        }
        this.chartData = value;
        this.chartOptions = {
            spanGaps: false,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 32,
                    left: 32,
                    right: 32
                }
            },
            elements: {
                point: {
                    radius: 4,
                    borderWidth: 2,
                    hoverRadius: 4,
                    hoverBorderWidth: 2
                },
                line: {
                    tension: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                        tickLength: 18
                    },
                    ticks: {
                        color: '#ffffff'
                    }
                },
                y: {
                    display: false,
                    ticks: {
                        min: 0,
                        max: Math.ceil(
                            Math.max(
                                Math.max(...(value!.datasets![0].data as number[])),
                                Math.max(...(value!.datasets![1].data as number[]))
                            ) * 1.1
                        ),
                        stepSize: 1
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                filler: {
                    propagate: false
                },
                xLabelsOnTop: {
                    active: true
                },
                yValuesOnTop: {
                    active: true
                }
            }
        } as ChartOptions<'line'>;
    }


    private _registerCustomChartJSPlugin(): void {
        Chart.register({
            id: 'yValuesOnTop',
            afterDatasetsDraw: this.drawValuesAboveDots
        });
    }

    private drawValuesAboveDots(chart: Chart, args: {}, options: any, cancelable: false): void {
        if (chart.options.plugins?.yValuesOnTop?.active !== true) {
            return;
        }
        // To only draw at the end of animation, check for easing === 1
        const ctx = chart.ctx!;

        chart.data!.datasets!.forEach((dataset: any, i: number) => {
            if (i !== 0) {
                // Only draw for the frontmost dataset (orders)
                return;
            }
            const meta = chart.getDatasetMeta(i);
            if (meta.hidden) {
                // Do not draw when hidden
                return;
            }
            meta.data.forEach((element: any, index: number) => {
                ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                const fontSize = 13;
                const fontStyle = 'normal';
                const fontFamily = 'Roboto, Helvetica Neue, Arial';
                ctx.font = fontString(fontSize, fontStyle, fontFamily);

                const dataString = dataset.data![index]!.toString();

                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                const padding = 15;
                const startY = 24;
                const position = (<any>element).tooltipPosition();
                ctx.fillText(dataString, position.x, startY);

                ctx.save();

                ctx.beginPath();
                ctx.setLineDash([5, 3]);
                ctx.moveTo(position.x, startY + padding);
                ctx.lineTo(position.x, position.y - padding);
                ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                ctx.stroke();

                ctx.restore();
            });
        });
    }
}
