import { Pipe, PipeTransform } from '@angular/core';

import { RemoteFilterKind, StoreEnvironment } from 'app/shared/graph';

@Pipe({ name: 'remoteFilterLabel' })
export class RemoteFilterLabelPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case RemoteFilterKind.Generic:
                return 'Generic';
            case RemoteFilterKind.PerfectlyClear:
                return 'Perfectly Clear';
            case RemoteFilterKind.PicsartRemoveBackground:
                return 'Picsart - Remove Background';
            case RemoteFilterKind.PicsartEffects:
                return 'Picsart - Effect';
            case RemoteFilterKind.PicsartStyleTransfer:
                return 'Picsart - Style Transfer';
            default:
                return value;
        }
    }
}
