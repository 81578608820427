import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

export interface ErrorDialogData {
    message: string;
    details?: string;
}

@Component({
    selector: 'admin-error-dialog',
    templateUrl: 'error-dialog.component.html',
    styleUrls: ['error-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton]
})
export class ErrorDialogComponent {
    dialogRef = inject<MatDialogRef<ErrorDialogComponent>>(MatDialogRef);
    data = inject<ErrorDialogData>(MAT_DIALOG_DATA);


    ok(): void {
        this.dialogRef.close();
    }
}
