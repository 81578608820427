import { Injectable, inject } from '@angular/core';
import { ViewContextService } from './view-context.service';
import { AuthorizationService } from 'app/shared/authentication';
import { combineLatestWith, Observable } from 'rxjs';
import { Navigation } from './navigation-types';
import { map } from 'rxjs/operators';
import { buildNavigation } from './build-navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private readonly viewContextService = inject(ViewContextService);
    private readonly authorizationService = inject(AuthorizationService);

    public navigation$: Observable<Navigation>;

    constructor() {
        this.navigation$ = this.viewContextService.context$
            .pipe(
                combineLatestWith(this.authorizationService.authorizationContext()),
                map(([viewContext, authorizationContext]) => {
                    return buildNavigation(viewContext, authorizationContext);
                })
            );
    }

}
