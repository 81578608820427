import { Injectable } from '@angular/core';

export type ResourceIdentity = {
    owner: string;
    package: string;
    group: string;
    id: string;
    options?: string;
}

@Injectable({ providedIn: 'root' })
export class ResourceIdentityParser {
    private readonly _resourceRegex: RegExp = /^\$\(package:(?<owner>.+?)\/(?<package>.+?)\)\/(?<group>.+)\/(?<id>.+?)$/;

    parse(resourceId: string): ResourceIdentity {
        let [success, resourceIdentity] = this.tryParse(resourceId);
        if (!success) {
            throw new Error(`Failed to parse resource ${resourceId}`);
        }

        return resourceIdentity!;
    }

    tryParse(resourceId: string): [boolean, ResourceIdentity | null] {
        let resourceIdentity: ResourceIdentity;
        let options: string | undefined;
        const indexOfOptions = resourceId.indexOf('[');
        if (indexOfOptions !== -1) {
            options = resourceId.slice(indexOfOptions);
            resourceId = resourceId.slice(0, indexOfOptions);
        }

        const match = this._resourceRegex.exec(resourceId);
        if (!match) {
            return [false, null];
        }

        resourceIdentity = {
            owner: match.groups!['owner'],
            package: match.groups!['package'],
            group: match.groups!['group'],
            id: match.groups!['id'],
            options: options
        };

        return [true, resourceIdentity];
    }
}
