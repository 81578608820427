import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { GlobalPermission, PageInfo, UserRoles } from 'app/shared/graph';
import { MatPaginator } from '@angular/material/paginator';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatButton, MatIconButton } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { AuthorizationContext, AuthorizationService, AuthorizedUsersService } from 'app/shared/authentication';

@Component({
    templateUrl: './admin-users-page.component.html',
    styleUrls: ['./admin-users-page.component.scss'],
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatIcon, MatPrefix, MatLabel, MatInput, MatError, MatButton, ErrorComponent, MatTable, MatColumnDef, MatHeaderCell, MatCell, CopyToClipboardComponent, RouterLink, MatHeaderRow, MatRow, MatIconButton, DatePipe, FromNowPipe, MatHeaderCellDef, MatCellDef, MatHeaderRowDef, MatRowDef]
})
export class AdminUsersPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly authorizedUsersService = inject(AuthorizedUsersService);
    private readonly promptDialogService = inject(PromptDialogService);

    users: UserRoles[] = [];
    pageInfo: PageInfo = { page: 0, pageSize: 5, hasNextPage: false };
    filter?: string;
    authorizationContext: AuthorizationContext;

    @ViewChild(MatPaginator) paginator: MatPaginator;


    ngOnInit() {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.subscribeWithGenericLoadingErrorHandling(this.authorizedUsersService.listUsers(0), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    search() {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.listUsers(0, this.filter), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    loadPage(page: number) {
        this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.listUsers(page, this.filter), result => {
            this.users = result.items || [];
            this.pageInfo = result.pageInfo;
        });
    }

    userRole$(element: any): UserRoles {
        return element as UserRoles;
    }

    canAddUsers() {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageAdminUsers);
    }

    openAddUserDialog() {
        this.registerSubscription(this.promptDialogService.prompt('Add user', undefined, { fieldLabel: 'Email' }).subscribe((email) => {
            this.subscribeWithGenericSavinErrorHandling(this.authorizedUsersService.grantUserRole({
                email: email
            }), () => {
                this.router.navigate([email], { relativeTo: this.route });
            });
        }));
    }
}
