import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription as HubSubscription, SubscriptionPermission } from 'app/shared/graph';
import { MatDialog } from '@angular/material/dialog';
import {
    UpdateSubscriptionInformationDialogComponent,
    UpdateSubscriptionInformationDialogData,
    UpdateSubscriptionInformationDialogResult
} from './update-subscription-info-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { ConfirmDialogComponent, ConfirmDialogData } from 'app/shared/dialog/confirm-dialog.component';
import { SubscriptionsService } from './subscriptions.service';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { AdminComponentBase } from '../../../layout/content';
import { PageSimpleContentComponent } from '../../../layout/content';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MatIcon } from '@angular/material/icon';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatButton } from '@angular/material/button';
import { SubscriptionStoresComponentComponent } from './subscription-stores-component.component';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { PageStatus } from '../../../layout/content';

@Component({
    selector: 'admin-subscription-page',
    templateUrl: './subscription-page.component.html',
    styleUrls: ['./subscription-page.component.scss'],
    animations: fuseAnimations,
    imports: [PageSimpleContentComponent, FusePerfectScrollbarDirective, MatIcon, CopyToClipboardComponent, MatButton, SubscriptionStoresComponentComponent, DatePipe, FromNowPipe]
})
export class SubscriptionPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly dialog = inject(MatDialog);
    private readonly authService = inject(AuthorizationService);
    private readonly subscriptionsService = inject(SubscriptionsService);

    subscription?: HubSubscription;
    auth: AuthorizationContext;

    subscriptionId: string;

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => {
            this.auth = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.subscriptionId = params.subscriptionId;
                this.subscribeWithGenericLoadingErrorHandling(this.subscriptionsService.load(this.subscriptionId), subscription => {
                    this.subscription = subscription;
                });
            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    editInfo(subscription: HubSubscription) {
        let dialogRef = this.dialog.open<UpdateSubscriptionInformationDialogComponent, UpdateSubscriptionInformationDialogData, UpdateSubscriptionInformationDialogResult>(
            UpdateSubscriptionInformationDialogComponent,
            {
                data: <UpdateSubscriptionInformationDialogData>{
                    label: subscription.label,
                    contact: subscription.contact,
                    notes: subscription.notes
                }
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.subscriptionsService.edit({ id: subscription.id, ...result }), () => {
                this.reloadSubscription();
            });
        });
    }

    openDeactivationDialog(subscription: HubSubscription) {
        const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                title: 'Deactivate subscription',
                message: `Do you really want to deactivate this subscription ?`,
                subMessage: `All users data of all the stores will be deleted after 90 days.`,
                options: {
                    yes: {
                        label: 'Deactivate',
                        warn: true
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.pageStatus = PageStatus.saving;
            this.subscriptionsService.deactivateSubscriptionAndScheduleDeletionOfUserData({ id: subscription.id })
                .subscribe({
                    next: () => {
                        this.reloadSubscription();
                    },
                    error: err => {
                        this.pageError = err;
                        this.pageStatus = PageStatus.saveError;
                    }
                });

        });
    }

    private reloadSubscription() {
        this.pageStatus = PageStatus.loading;
        this.subscriptionsService.load(this.subscriptionId).subscribe({
            next: subscription => {
                this.subscription = subscription;
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.saveError;
            }
        });
    }

    canManageSubscription(): boolean {
        return this.auth.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.ManageSubscriptionMetadata);
    }

    canDeactivateSubscription(): boolean {
        return this.auth.hasSubscriptionPermissions(this.subscriptionId, SubscriptionPermission.DeactivateSubscription);
    }
}
