import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RequestLoggerService } from './request-logger.service';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { forkJoin } from 'rxjs';
import { EnableRequestLoggerInput, RequestLog, RequestLoggingConfiguration } from 'app/shared/graph';
import { MatDialog } from '@angular/material/dialog';
import { AddRequestLoggerRuleDialogComponent } from './add-request-logger-rule-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatCardActions } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';

@Component({
    templateUrl: './request-logger-page.component.html',
    styleUrls: ['./request-logger-page.component.scss'],
    imports: [PageSimpleContentComponent, ErrorComponent, MatButton, MatIcon, MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, MatSuffix, MatDivider, CopyToClipboardComponent, MatCardActions, DatePipe, FromNowPipe]
})
export class RequestLoggerPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly requestLoggerService = inject(RequestLoggerService);
    private readonly dialog = inject(MatDialog);

    readonly appNames: Readonly<string[]> = ['HubApi', 'Ecb', 'Render', 'Photos'];
    configurationsByAppName: Record<string, RequestLoggingConfiguration> = {};
    logsPerRuleId: Record<string, RequestLog[]> = {};

    ngOnInit(): void {
        this.loadRules();
    }

    loadRules() {
        this.subscribeWithGenericLoadingErrorHandling(
            forkJoin(this.appNames.map(appName => this.requestLoggerService.loadRequestLoggerConfiguration(appName))),
            (configurations: RequestLoggingConfiguration[]): void => {
                for (let i = 0; i < this.appNames.length; i++) {
                    this.configurationsByAppName[this.appNames[i]] = configurations[i];
                    for (let rule of configurations[i].rules)
                        this.loadLogs(rule.id)
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    addNewRule(appName: string) {
        let dialogRef = this.dialog.open<AddRequestLoggerRuleDialogComponent, any, EnableRequestLoggerInput>(AddRequestLoggerRuleDialogComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            if (!result.storeId)
                result.storeId = undefined;
            if (!result.statusCode)
                result.statusCode = undefined;
            if (!result.logsExpirationInSeconds)
                result.logsExpirationInSeconds = undefined;
            if (!result.maximumCaptureCount)
                result.maximumCaptureCount = undefined;
            this.subscribeWithGenericSavinErrorHandling(this.requestLoggerService.enableRequestLogger(appName, result), () => {
                this.loadRules()
            })
        });
    }

    deleteRule(appName: string, ruleId: string) {
        this.subscribeWithGenericSavinErrorHandling(this.requestLoggerService.disableRequestLogger(appName, ruleId), () => {
            this.loadRules()
        })

    }

    loadLogs(ruleId: string) {
        this.requestLoggerService.loadRequestLogs(ruleId).subscribe((logs) => {
            this.logsPerRuleId[ruleId] = logs
        });
    }
}
