import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';
import { DesignerBranch } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class DesignerReleaseChannelService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    public getDesignerReleaseChannels(): Observable<DesignerBranch[]> {
        return this.graphDotnetService.query(`
                query designerReleaseChannels {
                    designerReleaseChannels {
                        id
                    }
                }
            `,
            {}
        ).pipe(map((q) => q.designerReleaseChannels));
    }
}
