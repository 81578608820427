import {
    AddStoreStockPhotoSourceInput,
    CreateStockPhotoSourceInput,
    GraphDotnetService,
    MediaclipMutationAddStoreStockPhotoSourceArgs,
    MediaclipMutationCreateStockPhotoSourceArgs,
    MediaclipMutationRemoveStoreStockPhotoSourceArgs,
    MediaclipMutationUpdateStockPhotoSourceArgs,
    RemoveStoreStockPhotoSourceInput,
    StockPhotoSourceConfig,
    UpdateStockPhotoSourceInput
} from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StockPhotoSourcesService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    list(): Observable<StockPhotoSourceConfig[]> {
        return this.graphDotnetService.query(`
                query getStockPhotoSourceConfigurations {
                    stockPhotoSourceConfigurations {
                        id
                        label
                        visible
                        disabled
                        photosEndpoint {
                          url
                        }
                    }
                }
            `
        ).pipe(map(e => e.stockPhotoSourceConfigurations));
    }

    load(stockPhotoSourceId: any): Observable<StockPhotoSourceConfig> {
        return this.graphDotnetService.query(`
                query getStockPhotoSourceConfiguration($stockPhotoSourceId: String!) {
                    stockPhotoSourceConfiguration(id: $stockPhotoSourceId) {
                        id
                        label
                        visible
                        disabled
                        thumbnailUrl
                        photosEndpoint {
                          url
                          headers {
                            name
                            value
                          }
                        }
                        albumsEndpoint {
                          url
                          headers {
                            name
                            value
                          }
                        }
                    }
                }
            `,
            { stockPhotoSourceId }
        ).pipe(map(e => e.stockPhotoSourceConfiguration));
    }

    loadStores(stockPhotoSourceId: any): Observable<StockPhotoSourceConfig> {
        return this.graphDotnetService.query(`
                query getStockPhotoSourceConfiguration($stockPhotoSourceId: String!) {
                    stockPhotoSourceConfiguration(id: $stockPhotoSourceId) {
                        id
                        stores {
                          id
                          label
                        }
                    }
                }
            `,
            { stockPhotoSourceId }
        ).pipe(map(e => e.stockPhotoSourceConfiguration));
    }

    createStockPhotoSource(input: CreateStockPhotoSourceInput): Observable<StockPhotoSourceConfig> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateStockPhotoSourceArgs>(`
                mutation createStockPhotoSource($input: CreateStockPhotoSourceInput!) {
                    createStockPhotoSource(input: $input) {
                        id
                    }
                }
            `, { input }
        ).pipe(map(x => x.createStockPhotoSource));
    }

    updateStockPhotoSource(input: UpdateStockPhotoSourceInput): Observable<StockPhotoSourceConfig> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStockPhotoSourceArgs>(`
                mutation updateStockPhotoSource($input: UpdateStockPhotoSourceInput!) {
                    updateStockPhotoSource(input: $input) {
                        id
                    }
                }
            `, { input }
        ).pipe(map(x => x.updateStockPhotoSource));
    }

    removeFromStore(input: RemoveStoreStockPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationRemoveStoreStockPhotoSourceArgs>(`
                mutation removeStoreStockPhotoSource($input: RemoveStoreStockPhotoSourceInput!) {
                    removeStoreStockPhotoSource(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.removeStoreStockPhotoSource));
    }

    addToStore(input: AddStoreStockPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationAddStoreStockPhotoSourceArgs>(`
                mutation addStoreStockPhotoSource($input: AddStoreStockPhotoSourceInput!) {
                    addStoreStockPhotoSource(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.addStoreStockPhotoSource));
    }
}
