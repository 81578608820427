import { Component, Input } from '@angular/core';
import { Endpoint, HttpHeader } from 'app/shared/graph';
import { MatFormField, MatLabel, MatError, MatHint, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'admin-store-endpoint',
    templateUrl: './store-endpoint.component.html',
    styleUrl: './store-endpoint.component.scss',
    imports: [MatFormField, MatLabel, MatInput, FormsModule, MatError, MatHint, SecretInputToggleComponent, MatSuffix, MatIconButton, MatIcon, MatButton]
})
export class StoreEndpointComponent {
    @Input() endpoint: Endpoint;
    urlPattern = /^https?:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';

    removeHeader(headers: Array<HttpHeader>, index: number): void {
        headers.splice(index, 1);
    }

    addHeader(headers: Array<HttpHeader>): void {
        headers.push({
            name: '',
            value: ''
        });
    }
}
