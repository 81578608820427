<admin-page-simple-content [pageError]="pageError" [pageStatus]="pageStatus" [title]="'Admin users'" [icon]="'group'">
    <div class="content">
        <div class="header">
            <form #form="ngForm" (ngSubmit)="search()">
                <mat-form-field class="dense-2" floatLabel="always">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label>Filter</mat-label>
                    <input
                        matInput
                        [(ngModel)]="filter"
                        name="filter"
                        placeholder="some@domain.com"
                        pattern='^[^:"]+$'
                    />
                    <mat-error>Invalid characters</mat-error>
                </mat-form-field>
                <button mat-stroked-button [disabled]="form.invalid || pageStatus === 'saving'" type="submit">
                    <mat-icon>search</mat-icon>
                    Search
                </button>
            </form>
            @if (canAddUsers()) {
                <button mat-stroked-button (click)="openAddUserDialog()">
                    <mat-icon>add</mat-icon>
                    Add user
                </button>
            }
        </div>

        <div>
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>

        <table mat-table [dataSource]="users" class="mat-elevation-z8">
            <ng-container matColumnDef="picture">
                <th mat-header-cell *matHeaderCellDef>Picture</th>
                <td mat-cell *matCellDef="let element"><img [src]="userRole$(element).user.picture" width="32"  alt=""/></td>
            </ng-container>
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Id</th>
                <td mat-cell *matCellDef="let element">
                    <code adminCopyToClipboard>{{ userRole$(element).user.id }}</code>
                </td>
            </ng-container>
            <ng-container matColumnDef="lastLogin">
                <th mat-header-cell *matHeaderCellDef>Last login</th>
                <td mat-cell *matCellDef="let element">
                    @if (userRole$(element).user.lastLoginDate) {
                        <div>{{ userRole$(element).user.lastLoginDate | date: "mediumDate" }}</div>
                        <div class="mat-caption">{{ userRole$(element).user.lastLoginDate | fromNow }}</div>
                    }
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['./', userRole$(element).user.email]">{{ userRole$(element).user.email }}</a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['picture', 'email', 'id', 'lastLogin']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['picture', 'email', 'id', 'lastLogin']"></tr>
        </table>
        <div class="paginator">
            <span class="mat-caption">Page: {{ pageInfo.page + 1 }}</span>
            <button mat-icon-button [disabled]="pageInfo.page === 0" (click)="loadPage(0)">
                <mat-icon>first_page</mat-icon>
            </button>
            <button mat-icon-button [disabled]="pageInfo.page === 0" (click)="loadPage(pageInfo.page - 1)">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-icon-button [disabled]="!pageInfo.hasNextPage" (click)="loadPage(pageInfo.page + 1)">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
</admin-page-simple-content>
