<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'User: ' + user?.storeUserId || '...'"
    [icon]="'face'"
>
    @if (user) {
        <div class="content">
            @if (user?.store) {
                <mat-tab-group>
                    <mat-tab label="User Information">
                        <div class="user-info tab-content p-24" fusePerfectScrollbar>
                            <h2>Actions</h2>
                            <div class="actions">
                                @if (!user.isDeleted && user.isGuest) {
                                    <button
                                        mat-stroked-button
                                        (click)="openConsolidateDialog(user)"
                                        [disabled]="pageStatus === 'saving'"
                                    >
                                        <mat-icon>merge</mat-icon>
                                        Consolidate
                                    </button>
                                }
                                <span class="separator"></span>
                                @if (user && !user.isDeleted && canManageUser(user)) {
                                    <button
                                        mat-stroked-button
                                        (click)="deleteUser(user)"
                                        color="warn"
                                        [disabled]="pageStatus === 'saving'"
                                    >
                                        <mat-icon>delete</mat-icon>
                                        Delete user
                                    </button>
                                }
                            </div>
                            @if (pageStatus === "saveError" && pageError) {
                                <admin-error [error]="pageError"></admin-error>
                            }
                            <h2>Information</h2>
                            @if (user.isDeleted) {
                                <div class="field">
                                    <span class="label"></span>
                                    <span class="value">
                                        <p class="delete-notice">
                                            <mat-icon color="warn">warning</mat-icon>
                                            This user has been deleted
                                        </p>
                                    </span>
                                </div>
                            }
                            <div class="field">
                                <span class="label">Hub User</span>
                                <span class="value">
                                    <code adminCopyToClipboard>{{ user.id }}</code>
                                </span>
                            </div>
                            <div class="field">
                                <span class="label">Store ID</span>
                                <span class="value">
                                    <code adminCopyToClipboard>{{ user.store.id }}</code>
                                </span>
                            </div>
                            <div class="field">
                                <span class="label">Store User ID</span>
                                <span class="value">
                                    <code adminCopyToClipboard>{{ user.storeUserId }}</code>
                                </span>
                            </div>
                            <div class="field">
                                <span class="label">Status</span>
                                <span class="value">
                                    @if (user.isGuest) {
                                        Guest account
                                    }
                                    @if (!user.isGuest && user.id === user.consolidatedId) {
                                        Full account
                                    }
                                    @if (!user.isGuest && user.id !== user.consolidatedId) {
                                        Consolidated:
                                        <a [routerLink]="'/users/' + user.consolidatedId"
                                        ><code>{{ user.consolidatedId }}</code></a
                                        >
                                    }
                                </span>
                            </div>
                            @if (user.aliases?.length ?? 0 > 0) {
                                <div class="field">
                                    <span class="label">Aliases</span>
                                    <span class="value alias-value">
                                        @for (alias of user.aliases; track alias; let i = $index) {
                                            <a [routerLink]="'/users/' + alias"
                                            ><code>{{ alias }}</code></a
                                            >
                                        }
                                    </span>
                                </div>
                            }
                        </div>
                    </mat-tab>
                    <mat-tab label="Projects list">
                        <div class="project-list">
                            @for (project of user.projects; track project) {
                                <div class="project">
                                    <div class="thumb">
                                        @if (!project.isDeleted && project.currentVersion?.thumbnailUrl) {
                                            <img [src]="project.currentVersion?.thumbnailUrl" loading="lazy" />
                                        } @else {
                                            <div class="no-preview">
                                                <mat-icon>preview_off</mat-icon>
                                            </div>
                                        }
                                    </div>
                                    <div class="info">
                                        <div class="project-field">
                                            <span class="label">Project ID</span>
                                            <span class="value project-id-value">
                                                <a [routerLink]="['/projects', project.id]">{{ project.id }}</a>
                                            </span>
                                        </div>

                                        @if (project.isDeleted) {
                                            <div class="project-field">
                                                <span class="label"></span>
                                                <span class="value project-delete-notice">
                                                    <mat-icon color="warn">warning</mat-icon>This project has been
                                                    deleted
                                                </span>
                                            </div>
                                        }
                                        @if (project.lastAccessDateUtc) {
                                            <div class="project-field">
                                                <span class="label">Last access</span>
                                                <span class="value">{{
                                                        project.lastAccessDateUtc | date: "medium"
                                                    }}</span>
                                            </div>
                                        }
                                        <div class="project-field">
                                            <span class="label">Created</span>
                                            <span class="value">{{ project.dateCreatedUtc | date: "medium" }}</span>
                                        </div>

                                        @if (project.currentVersion) {
                                            <div class="project-field">
                                                <span class="label">Last modification</span>
                                                <span class="value">{{
                                                        project.currentVersion.lastModifiedUtc | date: "medium"
                                                    }}</span>
                                            </div>
                                        }
                                        @if (project.product) {
                                            <div class="project-field">
                                                <span class="label">Product</span>
                                                <span class="value"
                                                ><code adminCopyToClipboard>{{ project.product.id }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (project.theme) {
                                            <div class="project-field">
                                                <span class="label">Theme</span>
                                                <span class="value"
                                                ><code adminCopyToClipboard>{{ project.theme.id }}</code></span
                                                >
                                            </div>
                                        }
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            }
                        </div>
                    </mat-tab>
                </mat-tab-group>
            }
        </div>
    }
</admin-page-simple-content>
