import { Injectable, inject } from "@angular/core";
import { ResourceIdentityParser } from "./resource-identity-parser.util";

@Injectable({ providedIn: 'root' })
export class GitLabUrlBuilder {
    private readonly resourceIdentityParser = inject(ResourceIdentityParser);


    GetResourceUrl(resourceId: string): string | undefined {
        let [success, resourceIdentity] = this.resourceIdentityParser.tryParse(resourceId);
        if (!success)
            return undefined;

        let gitlabGroup = resourceIdentity?.owner.startsWith('mediaclip') ? 'mediaclip': 'librairies';
        return `https://git.mediacliphub.com/${gitlabGroup}/${resourceIdentity?.owner}/tree/master/${resourceIdentity?.package}/${resourceIdentity?.group}/${resourceIdentity?.id}`;
    }
}
