<h1 mat-dialog-title>Consolidate user</h1>
<div mat-dialog-content>
    <p>
        Consolidate the user <code>{{ data.anonymousStoreUSerId }}</code> to another user. See the
        <a href="https://doc.mediaclip.ca/hub/features/anonymous-users/" target="_blank" class="external">
            documentation
            <mat-icon>open_in_new</mat-icon>
        </a>
        for more information.
    </p>
    <div class="warnings">
        <mat-icon color="warn">warning</mat-icon>
        <ul>
            <li>This operation cannot be reverted.</li>
            <li>Please make sure you are consolidating this user to the correct target user</li>
            <li>
                After this action the target user will have access to the project and the photos of the user
                <code>{{ data.anonymousStoreUSerId }}</code>
            </li>
        </ul>
    </div>
    <div class="notes">
        <mat-icon color="primary">info</mat-icon>
        <ul>
            <li>If the target user does not exist, it will be created.</li>
            <li>
                If the target user is the same as the anonymous user, the anonymous user will not be marked as anonymous
                anymore.
            </li>
        </ul>
    </div>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Target Store User ID</mat-label>
            <input
                matInput
                name="storeUserId"
                (blur)="targetStoreUserId = targetStoreUserId.trim()"
                [(ngModel)]="targetStoreUserId"
                type="text"
                required
            />
            <mat-hint>
                The user id to which the user <code>{{ data.anonymousStoreUSerId }}</code> will be consolidated
            </mat-hint>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary" (click)="valid()" [disabled]="!!form.invalid" cdkFocusInitial>OK</button>
</mat-dialog-actions>
