<div id="volume-widget">
    <div class="position-relative p-24 blue-900">
        <div>
            <span class="h2">Dashboards: Overview</span>
            <span class="h5 secondary-text">New projects and placed orders in the last 14 days</span>
        </div>
    </div>

    <div class="position-relative h-256 pb-16 blue-900">
        @if (chartOptions) {
            <canvas baseChart [data]="chartData" [options]="chartOptions" [type]="'line'"></canvas>
        }
    </div>

    @if (pageStatus === "saveError" || (pageStatus === "loadError" && pageError)) {
        <admin-error [error]="pageError"></admin-error>
    }
</div>
