import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectPhotoSource, Endpoint, GenericExternalPhotoSourceConfig } from 'app/shared/graph';
import { PageStatus } from '../../../layout/content/page-status';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { ExternalPhotosConfigService } from './external-photos-config.service';

@Component({
    selector: 'admin-direct-photo-source-edit-page',
    templateUrl: './direct-photo-source-edit-page.component.html',
    styleUrls: ['./direct-photo-source-edit-page.component.scss'],
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatLabel, MatInput, MatError, SecretInputToggleComponent, MatSuffix, MatIconButton, MatIcon, MatButton, SaveButtonComponent, ErrorComponent]
})
export class DirectPhotoSourceEditPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly externalPhotosConfigService = inject(ExternalPhotosConfigService);

    public storeId: string;
    public photoSourceId: string;
    public directPhotoSource?: DirectPhotoSource;
    public mode: 'create' | 'edit';
    private redirectTimerId: number;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.photoSourceId = params.photoSourceId;
            this.mode = this.route.snapshot.data.mode;

            if (this.mode == 'edit') {
                this.subscribeWithGenericLoadingErrorHandling(this.externalPhotosConfigService.loadDirectPhotoSource(this.storeId, this.photoSourceId), (source) => {
                    this.directPhotoSource = source;
                });
            } else {
                this.directPhotoSource = {
                    name: this.photoSourceId,
                    photoEndpoint: {
                        url: '',
                        headers: []
                    }
                };
                this.setPageStatus(PageStatus.loaded);
            }

        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
        if (this.redirectTimerId)
            window.clearTimeout(this.redirectTimerId)
    }

    save() {
        if (!this.directPhotoSource) {
            return;
        }
        if (this.mode === 'edit') {
            let input = {
                storeId: this.storeId,
                ...this.directPhotoSource
            };
            this.subscribeWithGenericSavinErrorHandling(this.externalPhotosConfigService.updateDirectPhotoSource(input), this.completeSave.bind(this));
        } else {
            let input = {
                storeId: this.storeId,
                ...this.directPhotoSource
            };
            this.subscribeWithGenericSavinErrorHandling(this.externalPhotosConfigService.createDirectPhotoSource(input), this.completeSave.bind(this));
        }
    }
    private completeSave() {
        this.pageStatus = PageStatus.loaded;
        this.redirectTimerId = window.setTimeout(() => {
            this.router.navigate(['stores', this.storeId, 'settings', 'external-photo-sources']);
        }, 500);
    }

    addHeader(endpoint: Endpoint) {
        endpoint.headers.push({ name: '', value: '' });
    }

    deleteHeader(endpoint: Endpoint, index: number) {
        endpoint.headers.splice(index, 1);
    }
}
