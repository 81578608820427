<h1 mat-dialog-title>Change status</h1>
<div mat-dialog-content>
    <p>Change line status from <code>{{ previousStatus }}</code> to?</p>
    <form>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select name="status" [(value)]="data.status">
                @for (status of statuses; track status) {
                    <mat-option [value]="status.value">{{ status.viewValue }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        @if (data.isSuperAdmin) {
            <div>
                <mat-checkbox name="actionRequired" [(ngModel)]="data.actionRequired">Action required</mat-checkbox>
            </div>
        }

        <mat-form-field>
            <mat-label>Comment</mat-label>
            <textarea name="comment" matInput [(ngModel)]="data.details" rows="3"></textarea>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>OK</button>
</mat-dialog-actions>
