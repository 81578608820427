import { Component, ElementRef, Input, Renderer2, ViewEncapsulation, inject } from '@angular/core';
import { NavbarVerticalStyle2Component } from './vertical/style-2/style-2.component';

@Component({
    selector: 'navbar', // eslint-disable-line
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NavbarVerticalStyle2Component]
})
export class NavbarComponent {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    _variant: string;

    constructor() {
        // Set the private defaults
        this._variant = 'vertical-style-2';
    }

    get variant(): string {
        return this._variant;
    }

    @Input()
    set variant(value: string) {
        // Remove the old class name
        this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

        // Store the variant value
        this._variant = value;

        // Add the new class name
        this._renderer.addClass(this._elementRef.nativeElement, value);
    }
}
