import { Component, inject } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface CreateFulfillerAppDialogResult {
    displayName: string;
}

@Component({
    templateUrl: './create-fulfiller-app-dialog.component.html',
    styleUrls: ['./create-fulfiller-app-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class CreateFulfillerAppDialogComponent {
    dialogRef = inject<MatDialogRef<CreateFulfillerAppDialogResult>>(MatDialogRef);


    fulfillerInfo: CreateFulfillerAppDialogResult = {
        displayName: ''
    };

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
