import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ViewContextService, ViewContextSubscription } from '../../../navigation/view-context.service';
import { MatCard, MatCardActions, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { SelectableSubscriptionElementComponent } from './selectable-subscription-element.component';

@Component({
    templateUrl: './subscription-selector-page.component.html',
    styleUrls: ['./subscription-selector-page.component.scss'],
    imports: [MatCard, MatCardTitle, MatCardContent, MatFormField, MatLabel, MatInput, FormsModule, MatAutocompleteTrigger, MatAutocomplete, MatOption, MatCardActions, MatButton, SelectableSubscriptionElementComponent]
})

export class SubscriptionSelectorPageComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly viewContextService = inject(ViewContextService);

    availableContexts: ViewContextSubscription[];
    filteredContexts: ViewContextSubscription[];
    contextFilterText: string = '';
    selectedSubscription?: ViewContextSubscription;

    ngOnInit(): void {
        this.viewContextService.availableContexts$.subscribe(contexts => {
            this.availableContexts = [...contexts.filter(x => x.type === 'subscription')] as ViewContextSubscription[];
            this.updateFilter();
        });
    }

    updateFilter() {
        let filterText = this.contextFilterText.toLowerCase();
        this.filteredContexts = this.availableContexts.filter(c => {
            if (!filterText) {
                return true;
            }
            return c.subscriptionId.toLowerCase().includes(filterText) || c.subscriptionLabel.toLowerCase().includes(filterText);
        });
    }

    selectSubscription(subscription: ViewContextSubscription) {
        if (!subscription) {
            return;
        }
        const redirectSubPath = localStorage.getItem('routeRedirectAfterSubscriptionSelect')
            ? '/' + localStorage.getItem('routeRedirectAfterSubscriptionSelect')
            : '';
        localStorage.removeItem('routeRedirectAfterSubscriptionSelect');
        this.router.navigateByUrl(`subscriptions/${encodeURIComponent(subscription.subscriptionId)}${redirectSubPath}`);
    }

    updateSelectedSubscription(subscriptionId: string) {
        this.selectedSubscription = this.availableContexts.find(x => x.subscriptionId == subscriptionId);
    }
}
