<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Subscription: Cleanup'"
    [icon]="'cleaning_services'"
>
    @if (subscriptionWithCleanup) {
        <div class="content">
            <p>
                Define the delay (in days) before projects will be deleted after their last access time (opened,
                ordered, shared, edited)
            </p>
            <section>
                <h2>Current configuration</h2>
                <div class="expiration-info">
                    <div class="admin-field">
                        <div class="label">Photobook</div>
                        <div class="value">
                            <span
                            ><code>{{
                                    currentProjectExpirations?.photobookDelayInDays
                                        ? currentProjectExpirations?.photobookDelayInDays + " days"
                                        : "no expiration"
                                }}</code></span
                            >
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Gifting</div>
                        <div class="value">
                            <span
                            ><code>{{
                                    currentProjectExpirations?.giftingDelayInDays
                                        ? currentProjectExpirations?.giftingDelayInDays + " days"
                                        : "no expiration"
                                }}</code></span
                            >
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Print</div>
                        <div class="value">
                            <span
                            ><code>{{
                                    currentProjectExpirations?.printDelayInDays
                                        ? currentProjectExpirations?.printDelayInDays + " days"
                                        : "no expiration"
                                }}</code></span
                            >
                        </div>
                    </div>
                </div>
                @if (canManageSubscription()) {
                    <button mat-stroked-button (click)="editExpirations()" [disabled]="pageStatus === 'saving'">
                        <mat-icon>edit</mat-icon>
                        Edit
                    </button>
                }
            </section>
            @if (pendingProjectExpirations?.changedConfigurationDateUtc) {
                <section>
                    <h2>Pending configuration</h2>
                    <p>
                        This configuration has been requested on
                        <code>{{ pendingProjectExpirations.changedConfigurationDateUtc | date: "full" : "UTC" }}</code>
                        and will be effective after 1 week
                    </p>
                    <div class="actions">
                        @if (canManageSubscription()) {
                            <button
                                mat-stroked-button
                                color="warn"
                                (click)="cancelPendingProjectExpiration()"
                                [disabled]="pageStatus === 'saving'"
                            >
                                <mat-icon>delete</mat-icon>
                                Cancel
                            </button>
                        }
                    </div>
                    <div class="expiration-info">
                        <div class="admin-field">
                            <div class="label">Photobook</div>
                            <div class="value">
                                <span
                                ><code>{{
                                        pendingProjectExpirations?.photobookDelayInDays
                                            ? pendingProjectExpirations?.photobookDelayInDays + " days"
                                            : "no expiration"
                                    }}</code></span
                                >
                            </div>
                        </div>
                        <div class="admin-field">
                            <div class="label">Gifting</div>
                            <div class="value">
                                <span
                                ><code>{{
                                        pendingProjectExpirations?.giftingDelayInDays
                                            ? pendingProjectExpirations?.giftingDelayInDays + " days"
                                            : "no expiration"
                                    }}</code></span
                                >
                            </div>
                        </div>
                        <div class="admin-field">
                            <div class="label">Print</div>
                            <div class="value">
                                <span
                                ><code>{{
                                        pendingProjectExpirations?.printDelayInDays
                                            ? pendingProjectExpirations?.printDelayInDays + " days"
                                            : "no expiration"
                                    }}</code></span
                                >
                            </div>
                        </div>
                    </div>
                </section>
            }
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>
    }
</admin-page-simple-content>
