import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export interface DisplayCodeDialogData {
    text: string;
    mimeType: string;
    editable?: boolean;
}

export type DisplayCodeDialogResult = {
    text: string;
}

@Component({
    selector: 'admin-display-code-dialog',
    templateUrl: './display-code-dialog.component.html',
    styleUrls: ['./display-code-dialog.component.scss'],
    imports: [CdkScrollable, MatDialogContent, CodemirrorModule, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class DisplayCodeDialogComponent {
    dialogRef = inject<MatDialogRef<DisplayCodeDialogComponent, DisplayCodeDialogResult>>(MatDialogRef);
    data = inject<DisplayCodeDialogData>(MAT_DIALOG_DATA);

    text: string;
    editable: boolean;

    constructor() {
        const data = this.data;

        this.text = data.text;
        this.editable = !!data.editable;
    }

    edit() {
        this.dialogRef.close({ text: this.text });
    }
}
