import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

// Note: We are not using the built-in CurrencyPipe because it formats USD as '$' and we cannot specify the wide style.
@Pipe({ name: 'adminCurrency' })
export class AdminCurrency implements PipeTransform {
    transform(value: number, currency: string): string {
        return formatCurrency(value, 'en-US', this.getCurrencySymbol(currency), currency);
    }

    private getCurrencySymbol(currency: string) {
        if (!currency) {
            return '';
        }
        if (currency === 'USD') {
            return 'US$';
        }
        return Intl.NumberFormat(undefined, { style: 'currency', currency: currency })
            .formatToParts()
            .find(part => part.type === 'currency')?.value ?? '';
    }
}
