import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FulfillerForm, Maybe, Store, StoreFulfiller, StoreFulfillerData } from 'app/shared/graph';
import { PageStatus } from '../../../layout/content/page-status';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StoreFulfillerEnableLogDialogComponent } from './store-fulfiller-enable-log-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { ErrorDialogComponent, ErrorDialogData } from 'app/shared/components/error-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { FulfillerService } from './fulfiller.service';
import { StoreFulfillersService } from './store-fulfillers.service';

@Component({
    templateUrl: './store-fulfiller-page.component.html',
    styleUrls: ['./store-fulfiller-page.component.scss'],
    animations: fuseAnimations,
    imports: [PageSimpleContentComponent, MatIcon, FormsModule, MatCheckbox, MatFormField, MatLabel, MatInput, MatHint, MatButton, CodemirrorModule, SaveButtonComponent, ErrorComponent, FromNowPipe]
})
export class StoreFulfillerPageContainerComponent implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly dialog = inject(MatDialog);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly storeFulfillersService = inject(StoreFulfillersService);
    private readonly fulfillerService = inject(FulfillerService);

    storeId: string;
    fulfillerId: string;
    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;
    mode: 'edit' | 'create' = 'edit';

    storeWithFulfillers: Store;
    storeFulfiller?: StoreFulfiller;
    fulfillerForm?: Maybe<FulfillerForm>;
    isFirstStoreFulfiller: boolean = false;

    @ViewChild('logCheckbox')
    logCheckbox: MatCheckbox;

    private redirectTimerId?: number;

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
        this.fulfillerId = this.route.snapshot.params.fulfillerId;
    }

    ngOnInit(): void {
        this.storeFulfillersService.getStoreFulfillers(this.storeId).subscribe({
            next: (storeWithFulfillers) => {
                this.storeWithFulfillers = storeWithFulfillers;
                if (storeWithFulfillers.fulfillers.find(x => x.fulfillerId == this.fulfillerId) != undefined) {
                    this.mode = 'edit';
                    this.loadStoreFulfiller();
                } else {
                    this.mode = 'create';
                    this.isFirstStoreFulfiller = storeWithFulfillers.fulfillers.length == 0;
                    this.initNewStoreFulfiller(this.isFirstStoreFulfiller);
                }
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });

    }

    loadStoreFulfiller() {
        this.storeFulfillersService.getStoreFulfiller(this.storeId, this.fulfillerId).subscribe({
            next: (storeFulfiller) => {
                this.storeFulfiller = storeFulfiller!;
                this.fulfillerForm = storeFulfiller!.fulfiller.form;
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    initNewStoreFulfiller(isFirstFulfiller: boolean) {
        this.fulfillerService.loadFormData(this.fulfillerId).subscribe({
            next: fulfillerForm => {
                this.storeFulfiller = {
                    isDefault: isFirstFulfiller,
                    configs: []
                } as any as StoreFulfiller;
                this.fulfillerForm = fulfillerForm;
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.redirectTimerId) {
            clearTimeout(this.redirectTimerId);
            this.redirectTimerId = undefined;
        }
    }

    updateValue(configs: Array<StoreFulfillerData>, key: string, value?: string) {
        let config = configs.find(e => e.name == key);
        if (!config) {
            if (value !== undefined) {
                configs.push({
                    name: key,
                    value: value
                });
            }
        } else {
            if (value === undefined) {
                let index = configs.findIndex(e => e.name == key);
                configs.splice(index, 1);
            } else {
                config.value = value;
            }
        }
    }

    getField(configs: Array<StoreFulfillerData>, key: string) {
        return configs.find(e => e.name == key)?.value;
    }

    save() {
        if (!this.storeFulfiller) {
            return;
        }

        let alreadyHasDefault = this.storeWithFulfillers.fulfillers.filter(x => x.isDefault && x.fulfillerId !== this.fulfillerId).length > 0;
        if (!this.storeFulfiller.isDefault && !alreadyHasDefault) {
            this.dialog.open<ErrorDialogComponent, ErrorDialogData>(ErrorDialogComponent, { data: { message: 'The store should have at least one default fulfiller' } });
            return;
        }

        if (this.storeFulfiller.isDefault && alreadyHasDefault) {
            this.confirmDialogService
                .confirm(
                    'Confirmation',
                    `Setting this fulfiller as default will replace any existing default fulfiller. Do you want to proceed?`
                ).subscribe(() => {
                    this.saveCore(this.storeFulfiller!);
                }
            );
        } else {
            this.saveCore(this.storeFulfiller);
        }
    }

    saveCore(storeFulfiller: StoreFulfiller) {
        let configs: StoreFulfillerData[] = storeFulfiller.configs?.filter(e => e.value != null) || [];
        this.pageStatus = PageStatus.saving;
        if (this.mode === 'edit') {

            this.storeFulfillersService.updateStoreFulfiller({
                storeId: this.storeId,
                fulfillerId: this.fulfillerId,
                configs: configs,
                isDefault: storeFulfiller.isDefault
            }).subscribe({
                next: () => {
                    this.pageStatus = PageStatus.loaded;
                    this.redirectTimerId = window.setTimeout(() => {
                        this.router.navigate(['stores', this.storeId, 'settings', 'fulfillers']);
                    }, 500);
                },
                error: err => {
                    this.pageError = err;
                    this.pageStatus = PageStatus.saveError;
                }
            });
        } else {
            this.storeFulfillersService.createStoreFulfiller({
                storeId: this.storeId,
                fulfillerId: this.fulfillerId,
                configs: configs,
                isDefault: storeFulfiller.isDefault
            }).subscribe({
                next: () => {
                    this.pageStatus = PageStatus.loaded;
                    this.redirectTimerId = window.setTimeout(() => {
                        this.router.navigate(['stores', this.storeId, 'settings', 'fulfillers']);
                    }, 500);
                },
                error: err => {
                    this.pageError = err;
                    this.pageStatus = PageStatus.saveError;
                }
            });
        }
    }

    isLogEnable(configs
                :
                Array<StoreFulfillerData>
    ):
        boolean {
        let config = configs.find(e => e.name == 'Mediaclip_EnableLog')?.value;
        if (!config) {
            return false;
        }
        return new Date(config) > new Date();
    }

    toggleLog(checked
              :
              boolean, configs
              :
              Array<StoreFulfillerData>
    ) {
        this.logCheckbox.indeterminate = false;
        if (!checked) {
            this.updateValue(configs, 'Mediaclip_EnableLog', undefined);
        } else {
            this.logCheckbox.indeterminate = true;
            this.dialog.open(StoreFulfillerEnableLogDialogComponent).afterClosed().subscribe(result => {
                this.logCheckbox.indeterminate = false;
                if (!result) {
                    this.logCheckbox.checked = false;
                    return;
                }
                let endDate = new Date();
                endDate.setDate(endDate.getDate() + result);
                this.updateValue(configs, 'Mediaclip_EnableLog', endDate.toISOString());
            })
        }
    }
}
