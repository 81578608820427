import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { AuthenticatedUser, StoreStatus } from '../../shared/graph';
import { Observable, Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthenticationService } from '../../shared/authentication';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ViewContext, ViewContextService } from '../../navigation/view-context.service';
import fuzzysort from 'fuzzysort';
import { MatToolbar } from '@angular/material/toolbar';
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { FuseSearchBarComponent } from '@fuse/components/search-bar/search-bar.component';
import { ShortStoreEnvPipe } from '../../shared/utilities/short-store-env.pipe';
import KeysResult = Fuzzysort.KeysResult;

type FuzzyMatchPart = {
    match: string
}

type KeysOfUnion<T> = T extends T ? keyof T : never;

@Component({
    selector: 'toolbar', // eslint-disable-line
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [MatToolbar, NgIf, MatIconButton, MatIcon, MatButton, MatMenuTrigger, NgClass, NgSwitch, NgSwitchCase, MatMenu, MatMenuItem, FormsModule, NgFor, FuseSearchBarComponent, AsyncPipe, ShortStoreEnvPipe]
})
export class ToolbarComponent implements OnInit, OnDestroy {
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private _fuseConfigService = inject(FuseConfigService);
    private _fuseSidebarService = inject(FuseSidebarService);
    private authenticationService = inject(AuthenticationService);
    viewContextService = inject(ViewContextService);

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild('storeMenu', { static: false }) storeMenu: MatMenu;

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    searchMode = false;
    _unsubscribeAll: Subject<any>;

    viewContext: ViewContext = { type: 'none' };
    availableContexts: ViewContext[];
    filteredContexts: Fuzzysort.KeysResults<ViewContext> = fuzzysort.go<ViewContext>('', [], { keys: [] });
    contextFilterText: string = '';
    fuzzySearchKeys: Array<KeysOfUnion<ViewContext>> = ['storeId', 'storeLabel', 'subscriptionLabel', 'fulfillerId', 'libraryOwner'];

    userAccount$: Observable<AuthenticatedUser>;
    filter: {
        showActiveStores: boolean;
        showDeactivatedStores: boolean;
        showSubscriptions: boolean;
        showLibraries: boolean;
        showFulfillers: boolean;
    } = {
        showActiveStores: true,
        showDeactivatedStores: false,
        showSubscriptions: true,
        showLibraries: true,
        showFulfillers: true
    };
    someFilteredContextsHidden: boolean = false;
    currentRoute: ActivatedRouteSnapshot;

    constructor() {
        this.userAccount$ = this.authenticationService.authenticatedUser$;
        this._unsubscribeAll = new Subject();

        let savedFilter = localStorage.getItem('view-context-selector-filter');
        if (savedFilter) {
            this.filter = { ...this.filter, ...JSON.parse(savedFilter) };
        }
    }

    ngOnInit(): void {
        this.viewContextService.context$.pipe(takeUntil(this._unsubscribeAll)).subscribe(context => this.viewContext = context);
        this.viewContextService.availableContexts$.subscribe(contexts => {
            this.availableContexts = [...contexts];
            this.updateFilter();
        });

        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings: any) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            map(route => route.snapshot)
        ).subscribe(route => this.currentRoute = route);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    toggleSidebarOpen(key: string): void {
        this._fuseSidebarService.getSidebar(key)!.toggleOpen();
    }

    searchCollapsedChanged(collapsed: boolean): void {
        this.searchMode = !collapsed;
    }

    async search(value: string): Promise<void> {
        if (!value) {
            return;
        }

        await this.router.navigate(['/search'], { queryParams: { q: value } });
    }

    selectFirstContext(event: KeyboardEvent): void {
        event.stopPropagation();
        if (this.filteredContexts.length > 0) {
            this.selectContext(this.filteredContexts[0].obj);
            setTimeout(() => this.trigger.closeMenu(), 0);
        }
    }

    selectContext(context: ViewContext): void {
        this.viewContextService.setContext(context);
        if (context.type === 'store') {
            if (this.currentRoute.data.viewContextType === context.type && this.currentRoute.data.viewContextChangeUrl) {
                this.router.navigateByUrl(this.currentRoute.data.viewContextChangeUrl.replace(':storeId', context.storeId));
            } else {
                this.router.navigate(['/stores', context.storeId, 'dashboards', 'overview']);
            }
        }
        if (context.type === 'library') {
            if (this.currentRoute.data.viewContextType === context.type && this.currentRoute.data.viewContextChangeUrl) {
                this.router.navigateByUrl(this.currentRoute.data.viewContextChangeUrl.replace(':libraryOwner', context.libraryOwner));
            } else {
                this.router.navigate(['/libraries', context.libraryOwner]);
            }
        }
        if (context.type === 'fulfiller') {
            if (this.currentRoute.data.viewContextType === context.type && this.currentRoute.data.viewContextChangeUrl) {
                this.router.navigateByUrl(this.currentRoute.data.viewContextChangeUrl.replace(':fulfillerId', context.fulfillerId));
            } else {
                this.router.navigate(['/fulfillers', context.fulfillerId]);
            }
        }
        if (context.type === 'subscription') {
            if (this.currentRoute.data.viewContextType === context.type && this.currentRoute.data.viewContextChangeUrl) {
                this.router.navigateByUrl(this.currentRoute.data.viewContextChangeUrl.replace(':subscriptionId', context.subscriptionId));
            } else {
                this.router.navigate(['/subscriptions', context.subscriptionId]);
            }
        }
    }

    signOut(): void {
        this.authenticationService.logout();
    }

    identifyContext(_index: number, context: ViewContext): string {
        switch (context.type) {
            case 'subscription':
                return 'sub:' + context.subscriptionId;
            case 'none':
                return 'none';
            case 'store':
                return 'store:' + context.storeId;
            case 'library':
                return 'library:' + context.libraryOwner;
            case 'fulfiller':
                return 'fulfiller:' + context.fulfillerId;
        }
    }

    preventMenuFocus($event: KeyboardEvent) {
        if ($event.key === 'ArrowDown' || $event.key === 'ArrowDown' || $event.key === 'Escape' || $event.key === 'Enter') {
            return;
        }
        $event.stopImmediatePropagation();
        this.updateFilter();
    }

    areContextEquals(context1: ViewContext, context2: ViewContext) {
        if (context1.type === 'store' && context2.type === 'store') {
            return context1.storeId === context2.storeId;
        }
        if (context1.type === 'subscription' && context2.type === 'subscription') {
            return context1.subscriptionId === context2.subscriptionId;
        }
        return false;
    }

    updateFilter() {
        let filterText = this.contextFilterText.toLowerCase();
        let viewableContexts = this.availableContexts.filter(c => {
            switch (c.type) {
                case 'none':
                    break;
                case 'store':
                    if (!this.filter.showActiveStores && !this.filter.showDeactivatedStores) {
                        return false;
                    }
                    return ((this.filter.showActiveStores && c.status == StoreStatus.Active) || (this.filter.showDeactivatedStores && c.status != StoreStatus.Active));
                case 'subscription':
                    return this.filter.showSubscriptions;

                case 'library':
                    return this.filter.showLibraries;

                case 'fulfiller':
                    return this.filter.showFulfillers;

            }
            return false;
        });
        let filteredContexts = fuzzysort.go<ViewContext>(filterText, viewableContexts, {
            keys: this.fuzzySearchKeys,
            limit: 25,
            all: true,
            threshold: -20000
        });

        this.someFilteredContextsHidden = filteredContexts.length >= 25;
        this.filteredContexts = filteredContexts;
        localStorage.setItem('view-context-selector-filter', JSON.stringify(this.filter));
    }

    toggleFilterShowActiveStores(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.filter.showActiveStores = !this.filter.showActiveStores;
        this.updateFilter();
    }

    toggleFilterShowDeactivatedStores(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.filter.showDeactivatedStores = !this.filter.showDeactivatedStores;
        this.updateFilter();
    }

    toggleFilterShowSubscriptions(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.filter.showSubscriptions = !this.filter.showSubscriptions;
        this.updateFilter();
    }

    toggleFilterShowLibraries(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.filter.showLibraries = !this.filter.showLibraries;
        this.updateFilter();
    }

    toggleFilterShowFulfillers(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        this.filter.showFulfillers = !this.filter.showFulfillers;
        this.updateFilter();
    }

    highlight(contextResult: KeysResult<ViewContext>, key: KeysOfUnion<ViewContext>): (string | FuzzyMatchPart)[] {
        let context = contextResult[this.indexOfFuzzySearchKey(key)];
        let result = context.highlight((match, _index) => {
            return {
                match
            } as FuzzyMatchPart;
        });
        if (!result?.length) {
            return [((contextResult.obj) as any)[key]];
        }
        return result;
    }

    isString(o: any) {
        return typeof o === 'string';
    }

    indexOfFuzzySearchKey(key: KeysOfUnion<ViewContext>): number {
        return this.fuzzySearchKeys.indexOf(key);
    }
}
