import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { NgClass } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    imports: [NgClass, MatIconButton, MatIcon]
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    private _fuseConfigService = inject(FuseConfigService);

    collapsed: boolean;
    fuseConfig: any;

    @Output()
    value: EventEmitter<any>;

    @Output()
    go: EventEmitter<any>;

    @Output()
    collapsedChanged: EventEmitter<boolean>;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor()
    {
        // Set the defaults
        this.value = new EventEmitter();
        this.go = new EventEmitter();
        this.collapsedChanged = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config: any) => {
                    this.fuseConfig = config;
                }
            );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
        this.collapsedChanged.emit(true);
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
        this.collapsedChanged.emit(false);

    }

    /**
     * Search (type)
     *
     * @param event
     */
    search(event: any): void
    {
        this.value.emit(event.target.value);
    }

    /**
     * Search (go)
     *
     * @param event
     */
    enter(event: any): void
    {
        this.go.emit(event.target.value);
        this.collapse();
    }

}
