import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/dialog/confirm-dialog.component';
import { Store, StoreEnvironment, StoreStatus, SubscriptionPermission } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { PageStatus } from '../../../layout/content/page-status';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { DatePipe } from '@angular/common';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { StoresService } from './stores.service';

@Component({
    selector: 'admin-store-status',
    templateUrl: './store-status-page.component.html',
    styleUrls: ['./store-status-page.component.scss'],
    imports: [PageSimpleContentComponent, MatButton, MatIcon, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatSelect, MatOption, MatCheckbox, MatIconButton, MatError, SaveButtonComponent, ErrorComponent, DatePipe, FromNowPipe]
})
export class StoreStatusPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly authService = inject(AuthorizationService);
    private readonly storesService = inject(StoresService);
    private readonly matDialog = inject(MatDialog);

    storeId: string;
    authContext?: AuthorizationContext;
    storeWithMetadata?: Store;
    noCommaPattern = /^[^,]*$/;

    environments = [
        { value: StoreEnvironment.Production, display: 'Production (billable, monitored)' },
        { value: StoreEnvironment.Staging, display: 'Staging (non-billable, monitored)' },
        { value: StoreEnvironment.Development, display: 'Development (non-billable, non-monitored)' },
        { value: StoreEnvironment.Demonstration, display: 'Demonstration (non-billable, monitored)' },
        { value: StoreEnvironment.Test, display: 'Test (used for testing)' }
    ];

    statuses = [
        { value: StoreStatus.Active, display: 'Active' },
        { value: StoreStatus.Closed, display: 'Closed', disabled: true },
        { value: StoreStatus.ScheduledForDeletion, display: 'ScheduledForDeletion', disabled: true },
        { value: StoreStatus.ScheduledForDeletionNotificationSent, display: 'ScheduledForDeletionNotificationSent', disabled: true }
    ];

    platforms = [
        { value: '', display: 'Unspecified' },
        { value: 'BigCommerce', display: 'BigCommerce' },
        { value: 'Ecwid', display: 'Ecwid' },
        { value: 'Magento', display: 'Magento' },
        { value: 'Prestashop', display: 'Prestashop' },
        { value: 'Shopify', display: 'Shopify' },
        { value: 'Wix', display: 'Wix' },
        { value: 'WooCommerce', display: 'WooCommerce' },
        { value: 'Other', display: 'Other' },
        { value: 'Custom', display: 'In-House (Custom)' }
    ];

    plugins = [
        { value: '', display: 'Unspecified' },
        { value: 'Magento', display: 'Magento' },
        { value: 'Shopify', display: 'Shopify' },
        { value: 'WooCommerce', display: 'WooCommerce' },
        { value: 'None', display: 'None' }
    ];

    ngOnInit(): void {
        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storesService.getStore(this.storeId), (store) => {
                this.storeWithMetadata = store;
            });
        }));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscriptions();
    }

    deactivate() {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Deactivate store',
                message: `Do you really want to deactivate the store <code>${this.storeId}</code> ?`,
                subMessage: `All data will be deleted after 90 days. This will immediately prevent the store from creating any new orders or projects`,
                options: {
                    yes: {
                        label: 'Deactivate',
                        warn: true
                    },
                    no: {
                        label: 'Cancel'
                    }
                }
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.subscribeWithGenericSavinErrorHandling(this.storesService.deactivateStore({ id: this.storeId }), () => {
                this.reloadStore();
            });
        });
    }

    save() {
        if (!this.storeWithMetadata) {
            return;
        }

        this.pageStatus = PageStatus.saving;
        this.subscribeWithGenericSavinErrorHandling(this.storesService.updateStore({
            id: this.storeId,
            label: this.storeWithMetadata.label,
            environment: this.storeWithMetadata.environment,
            status: this.storeWithMetadata.status,
            automaticDesignerUpgrade: this.storeWithMetadata.automaticDesignerUpgrade,
            platform: this.storeWithMetadata.metadata.platform,
            plugin: this.storeWithMetadata.metadata.plugin,
            testDesignerInfo: this.storeWithMetadata.metadata.testDesignerInfo,
            ignoredProblems: this.storeWithMetadata.metadata.ignoredProblems
        }));
    }

    addDesignerTestInfo() {
        if (!this.storeWithMetadata) {
            return;
        }
        this.storeWithMetadata.metadata.testDesignerInfo.push({});
    }

    removeDesignerTestInfo(i: number) {
        if (!this.storeWithMetadata) {
            return;
        }
        this.storeWithMetadata.metadata.testDesignerInfo.splice(i, 1);
    }

    canDeactivateSubscription(subscriptionId: string) {
        return this.authContext?.hasSubscriptionPermissions(subscriptionId, SubscriptionPermission.ManageSubscriptionMetadata);
    }

    private reloadStore() {
        this.subscribeWithGenericLoadingErrorHandling(this.storesService.getStore(this.storeId), (store) => {
            this.storeWithMetadata = store;
        });
    }
}
