<h2 mat-dialog-title>Add user to {{ data.target.label }}</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input
                matInput
                adminAutoTrim
                name="email"
                pattern="^.+@.+$"
                [(ngModel)]="email"
                required
                [disabled]="!!data.email"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select name="role" [(ngModel)]="selectedRole" required>
                @for (role of data.target.availableRoles; track role) {
                    <mat-option [value]="role">
                        {{ role }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>

    <button mat-button color="primary" [disabled]="!form.valid" (click)="valid()">OK</button>
</mat-dialog-actions>
