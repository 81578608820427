import { Component, inject } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FulfillerClient } from 'app/shared/graph';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

export interface AddFulfillerDialogResult {
    fulfillerId: string;
    client: FulfillerClient;
}

@Component({
    templateUrl: './add-fulfiller-dialog.component.html',
    styleUrls: ['./add-fulfiller-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatError, MatSelect, MatOption, MatDialogActions, MatButton, MatDialogClose]
})
export class AddFulfillerDialogComponent {
    dialogRef = inject<MatDialogRef<AddFulfillerDialogComponent>>(MatDialogRef);

    fulfillerInfo: AddFulfillerDialogResult = {
        fulfillerId: '',
        client: FulfillerClient.Default
    };

    clients = Object.values(FulfillerClient);

    valid() {
        this.dialogRef.close(this.fulfillerInfo);
    }
}
