import { Component, OnInit, inject } from '@angular/core';
import { DesignerBranch, StoreEnvironment } from 'app/shared/graph';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { DesignerReleaseChannelService } from './designer-release-channel.service';

export interface CreateStoreDialogResult {
    label: string;
    environment: StoreEnvironment;
    designerReleaseChannel: string;
    appId?: string;
}

@Component({
    templateUrl: './create-store-dialog.component.html',
    styleUrls: ['./create-store-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatSelect, MatOption, MatHint, MatError, MatDialogActions, MatButton, MatDialogClose]
})
export class CreateStoreDialogComponent implements OnInit {
    private readonly dialogRef = inject<MatDialogRef<CreateStoreDialogComponent, CreateStoreDialogResult>>(MatDialogRef);
    private readonly designerReleaseChannelService = inject(DesignerReleaseChannelService);

    label: string;
    environment: StoreEnvironment = StoreEnvironment.Production;
    designerReleaseChannel: string = '';
    environments: string[] = [];
    appId?: string;
    designerReleaseChannels: DesignerBranch[] = [];

    ngOnInit(): void {
        this.designerReleaseChannelService.getDesignerReleaseChannels().subscribe((designerReleaseChannels) => {
            this.designerReleaseChannels = designerReleaseChannels;
        });
        for (let storeEnvironment in StoreEnvironment) {
            this.environments.push(storeEnvironment);
        }
    }

    valid() {
        this.dialogRef.close({
            label: this.label,
            appId: this.appId,
            environment: this.environment,
            designerReleaseChannel: this.designerReleaseChannel
        })
    }
}
