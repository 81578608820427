import { Component, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { ActivatedRoute } from '@angular/router';
import { GlobalPermission, HttpHeader, StockPhotoSourceConfig } from 'app/shared/graph';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { StockPhotoSourcesService } from './stock-photo-sources.service';

@Component({
    templateUrl: './stock-photo-source-page.component.html',
    styleUrls: ['./stock-photo-source-page.component.scss'],
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatCheckbox, MatError, MatIcon, SecretInputToggleComponent, MatSuffix, MatIconButton, MatButton, SaveButtonComponent, ErrorComponent]
})
export class StockPhotoSourcePageComponent extends AdminComponentBase implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly stockPhotoSourcesService = inject(StockPhotoSourcesService);

    urlPattern = /^https?:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';
    stockPhotoSource: StockPhotoSourceConfig;

    private authorizationContext: AuthorizationContext;

    ngOnInit(): void {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authorizationContext = authContext;
        });

        this.registerSubscription(
            this.route.params.subscribe(params => {
                let stockPhotoSourceId = params.stockPhotoSourceId;
                this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.load(stockPhotoSourceId), (stockPhotoSourceConfig) => {
                    let stockPhotoSourceConfigNoNull = { ...stockPhotoSourceConfig };
                    if (!stockPhotoSourceConfigNoNull.photosEndpoint.headers) {
                        stockPhotoSourceConfigNoNull.photosEndpoint.headers = [];
                    }
                    if (!stockPhotoSourceConfigNoNull.albumsEndpoint) {
                        stockPhotoSourceConfigNoNull.albumsEndpoint = { url: '', headers: [] };
                    }
                    if (!stockPhotoSourceConfigNoNull.albumsEndpoint.headers) {
                        stockPhotoSourceConfigNoNull.albumsEndpoint.headers = [];
                    }
                    this.stockPhotoSource = stockPhotoSourceConfigNoNull;
                });
            }));
    }

    canManageStockPhotoSource(): boolean {
        return this.authorizationContext.hasGlobalPermissions(GlobalPermission.ManageStockPhotoSources);
    }

    save() {
        this.subscribeWithGenericSavinErrorHandling(this.stockPhotoSourcesService.updateStockPhotoSource({
            id: this.stockPhotoSource.id,
            label: this.stockPhotoSource.label,
            visible: this.stockPhotoSource.visible,
            disabled: this.stockPhotoSource.disabled,
            thumbnailUrl: this.stockPhotoSource.thumbnailUrl,
            photosEndpoint: this.stockPhotoSource.photosEndpoint,
            albumsEndpoint: this.stockPhotoSource.albumsEndpoint
        }), () => {
            this.reload();
        });
    }

    reload() {
        this.subscribeWithGenericLoadingErrorHandling(this.stockPhotoSourcesService.load(this.stockPhotoSource.id), source => {
            this.stockPhotoSource = source;
        });
    }

    removeHeader(headers: Array<HttpHeader>, index: number): void {
        headers.splice(index, 1);
    }

    addHeader(headers: Array<HttpHeader>): void {
        headers.push({
            name: '',
            value: ''
        });
    }
}
