import { Component, OnInit, inject } from '@angular/core';
import { Product } from 'app/shared/graph';
import { fuseAnimations } from '@fuse/animations';
import { LibraryService } from './library.service';
import { PageStatus } from '../../../layout/content/page-status';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GitLabUrlBuilder } from "./git-lab-url-builder.util";
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatLabel, MatPrefix, MatHint } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';

@Component({
    selector: 'admin-products-page',
    templateUrl: './products-page.component.html',
    styleUrls: ['./products-page.component.scss'],
    animations: fuseAnimations,
    imports: [PageSimpleContentComponent, MatFormField, MatLabel, MatIcon, MatPrefix, MatInput, FormsModule, MatHint, CopyToClipboardComponent, RouterLink]
})
export class ProductsPageContainerComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly libraryService = inject(LibraryService);
    private readonly gitLabUrlBuilder = inject(GitLabUrlBuilder);

    storeId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    products: Product[] = [];
    filteredProducts: Product[] = [];
    filter: string;

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
    }

    ngOnInit(): void {
        this.libraryService.getProducts(this.storeId).subscribe({
            next: (products) => {
                this.products = products;
                this.updateFilter();
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        });
    }

    updateFilter() {
        if (this.filter) {
            this.filteredProducts = this.products.filter(p =>
                p.id.includes(this.filter) || p.label?.includes(this.filter)
            );
        } else {
            this.filteredProducts = this.products;
        }
    }

    getGitlabLink(productId: string) {
        return this.gitLabUrlBuilder.GetResourceUrl(productId);
    }

    getThumbnailUrlWithStoreId(product: Product): string | undefined {
        if (!product.thumbnail)
            return undefined;
        let url = new URL(product.thumbnail)
        url.searchParams.append('data-storeId', this.storeId);
        return url.toString();
    }
}
