@if (data.editable) {
    <mat-dialog-content>
        <ngx-codemirror
            [(ngModel)]="text"
            [contentEditable]="data.editable"
            [options]="{ lineNumbers: true, matchBrackets: true, lint: true, theme: 'material', mode: data.mimeType }"
        ></ngx-codemirror>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button color="primary" (click)="edit()">Edit</button>
    </mat-dialog-actions>
} @else {
    <ngx-codemirror
        [ngModel]="text"
        [contentEditable]="false"
        [options]="{ lineNumbers: true, matchBrackets: true, lint: true, theme: 'material', mode: data.mimeType }"
    ></ngx-codemirror>
}
