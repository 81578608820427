<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Fulfiller Logs'"
    [icon]="'print'"
>
    <div class="content">
        <p>
            This page display json request and response from call to the fulfiller when creating an order. You can
            enable request logging in the
            <a [routerLink]="['/stores', storeId, 'settings', 'fulfillers', fulfillerId]">
                stores's fulfiller configuration
            </a>
        </p>

        @for (log of logs; track log) {
            <mat-card>
                <div class="log-header">
                    <mat-icon>{{ log.type === "Request" ? "call_made" : "call_received" }}</mat-icon>
                    {{ log.type }} - {{ log.dateCreatedUtc | date: "full" }}
                </div>
                <div>
                    OrderId: <a [routerLink]="['/orders', log.orderId]">{{ log.orderId }}</a>
                </div>
                <pre>{{ prettifyJson(log.data) }}</pre>
            </mat-card>
        }
    </div>
</admin-page-simple-content>
