import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Fulfiller, Library, Store, StoreEnvironment, Subscription } from 'app/shared/graph';
import { AuthenticationService } from 'app/shared/authentication';

import { map, take } from 'rxjs/operators';

export type ViewContext = ViewContextNone | ViewContextStore | ViewContextSubscription | ViewContextLibrary | ViewContextFulfiller;

export interface ViewContextNone {
    type: 'none';
}

export interface ViewContextStore {
    type: 'store';
    storeId: string;
    status: string;
    storeEnvironment: StoreEnvironment;
    storeLabel: string;
    subscriptionId: string;
    subscriptionLabel: string;
}

export interface ViewContextSubscription {
    type: 'subscription';
    subscriptionId: string;
    subscriptionLabel: string;
}

export interface ViewContextLibrary {
    type: 'library';
    libraryOwner: string;
}

export interface ViewContextFulfiller {
    type: 'fulfiller';
    fulfillerId: string;
}


@Injectable({
    providedIn: 'root'
})
export class ViewContextService {
    private readonly authenticationService = inject(AuthenticationService);

    private noneContext: ViewContextNone = { type: 'none' };
    private _context: ViewContext = this.noneContext;
    private _context$: BehaviorSubject<ViewContext> = new BehaviorSubject<ViewContext>(this.noneContext);

    get context$(): Observable<ViewContext> {
        return this._context$;
    }

    get context(): ViewContext {
        return this._context;
    }

    availableContexts$: Observable<ViewContext[]>;

    constructor() {
        let lastViewContextJson = localStorage.getItem('view-context');
        let lastViewContext: any;
        if (lastViewContextJson) {
            lastViewContext = JSON.parse(lastViewContextJson);
        }
        this.availableContexts$ = this.authenticationService.authenticatedUser$.pipe(
            map(u => {
                    return u.stores.map(store => this.createStoreViewContext(store))
                        .concat(u.subscriptions.map(subscription => this.createSubscriptionViewContext(subscription)))
                        .concat(u.fulfillers.map(fulfiller => this.createFulfillerViewContext(fulfiller)))
                        .concat(u.libraries.map(library => this.createLibraryViewContext(library)));
                }
            )
        );
        if (lastViewContext) {
            this.authenticationService.authenticatedUser$.pipe(take(1))
                .subscribe((user) => {
                    if (lastViewContext.type === 'store') {
                        let store = user.stores.find(x => x.id == lastViewContext.storeId);
                        this.setContext(this.createStoreViewContext(store));
                    } else if (lastViewContext.type === 'subscription') {
                        let subscriptions: Subscription[] = Object.values(user.stores.reduce((previousValue, currentValue) => {
                            previousValue[currentValue.subscription.id] = currentValue.subscription;
                            return previousValue;
                        }, {} as Record<string, Subscription>));
                        let subscription = subscriptions.find(x => x.label == lastViewContext.subscriptionId);
                        this.setContext(this.createSubscriptionViewContext(subscription));
                    } else if (lastViewContext.type === 'library') {
                        let library = user.libraries.find(x => x.owner == lastViewContext.libraryOwner);
                        this.setContext(this.createLibraryViewContext(library));
                    } else if (lastViewContext.type === 'fulfiller') {
                        let fulfiller = user.fulfillers.find(x => x.id == lastViewContext.fulfillerId);
                        this.setContext(this.createFulfillerViewContext(fulfiller));
                    }
                });
        }
    }

    setContext(context: ViewContext) {
        this._context = context;
        this._context$.next(context);
        localStorage.setItem('view-context', JSON.stringify(context));
    }

    selectStore(storeId: string | null) {
        this.authenticationService.authenticatedUser$.pipe(take(1))
            .subscribe((user) => {
                let store = user.stores.find(x => x.id == storeId);
                if (store) {
                    this.setContext(this.createStoreViewContext(store));
                }
            });
    }

    selectLibrary(libraryOwner: string | null) {
        this.authenticationService.authenticatedUser$.pipe(take(1))
            .subscribe((user) => {
                let library = user.libraries.find(x => x.owner == libraryOwner);
                if (library) {
                    this.setContext(this.createLibraryViewContext(library));
                }
            });
    }

    selectSubscription(subscriptionId: string | null) {
        this.authenticationService.authenticatedUser$.pipe(take(1))
            .subscribe((user) => {
                let store = user.stores.find(x => x.subscription.id == subscriptionId);
                if (store) {
                    this.setContext(this.createSubscriptionViewContext(store.subscription));
                }
            });
    }

    selectFulfiller(fulfillerId: string | null) {
        this.authenticationService.authenticatedUser$.pipe(take(1))
            .subscribe((user) => {
                let fulfiller = user.fulfillers.find(x => x.id == fulfillerId);
                if (fulfiller) {
                    this.setContext(this.createFulfillerViewContext(fulfiller));
                }
            });
    }

    createStoreViewContext(store?: Store): ViewContext {
        if (!store) {
            return {
                type: 'none'
            };
        }
        return {
            type: 'store',
            storeId: store.id,
            storeLabel: store.label || store.id,
            storeEnvironment: store.environment,
            status: store.status,
            subscriptionId: store.subscription.id,
            subscriptionLabel: store.subscription.label
        };
    }

    createLibraryViewContext(library?: Library): ViewContext {
        if (!library) {
            return {
                type: 'none'
            };
        }
        return {
            type: 'library',
            libraryOwner: library.owner
        };
    }

    createFulfillerViewContext(fulfiller?: Fulfiller): ViewContext {
        if (!fulfiller) {
            return {
                type: 'none'
            };
        }
        return {
            type: 'fulfiller',
            fulfillerId: fulfiller.id
        };
    }

    createSubscriptionViewContext(subscription?: Subscription): ViewContext {
        if (!subscription) {
            return {
                type: 'none'
            };
        }
        return {
            type: 'subscription',
            subscriptionId: subscription.id,
            subscriptionLabel: subscription.label
        };
    }
}
