<div class="navbar-header" [ngClass]="[fuseConfig.layout.navbar.secondaryBackground, 'env-' + environmentName]">
    <div class="logo">
        <img class="logo-icon" src="../../../../../assets/images/logos/hub.svg" />
        @switch (environmentName) {
            @case ("Development") {
                <span class="logo-text">Mediaclip&nbsp;Dev</span>
            }
            @case ("Staging") {
                <span class="logo-text">Mediaclip&nbsp;Staging</span>
            }
            @default {
                <span class="logo-text">Mediaclip&nbsp;Hub</span>
            }
        }
    </div>

    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()">
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()">
        <mat-icon>arrow_back</mat-icon>
    </button>
</div>

<div class="navbar-content" fusePerfectScrollbar [ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>
