import { Component, OnInit, inject } from '@angular/core';
import { Maybe, Product, Theme } from 'app/shared/graph';
import { PageStatus } from '../../../layout/content/page-status';
import { ActivatedRoute } from '@angular/router';
import { LibraryService } from './library.service';
import { environment } from '../../../../environments/environment';
import { GitLabUrlBuilder } from "./git-lab-url-builder.util";
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';

import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'admin-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss'],
    imports: [PageSimpleContentComponent, CopyToClipboardComponent, MatFormField, MatLabel, MatIcon, MatPrefix, MatInput, FormsModule, MatCheckbox, MatSelect, MatOption]
})
export class ProductPageContainerComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly libraryService = inject(LibraryService);
    private readonly gitLabUrlBuilder = inject(GitLabUrlBuilder);

    storeId: string;
    productId: string;

    pageStatus: PageStatus = PageStatus.loading;
    pageError?: Error;

    product?: Maybe<Product>;
    filteredThemes: Theme[] = [];

    filter: string;
    showPreview: boolean;
    selectedOptions: Record<string, string> = {};

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
        this.productId = this.route.snapshot.queryParams.id;
    }

    ngOnInit(): void {
        this.libraryService.getProduct(this.storeId, this.productId).subscribe({
            next: (product) => {
                this.product = product;
                if (this.product?.options)
                    this.selectedOptions = this.product.options.reduce((selectedOptions, option) => {
                        selectedOptions[option.name] = option.default
                        return selectedOptions;
                    }, {} as Record<string, string>);
                else
                    this.selectedOptions = {};
                this.updateFilter();
                this.pageStatus = PageStatus.loaded;
            },
            error: err => {
                this.pageError = err;
                this.pageStatus = PageStatus.loadError;
            }
        })
    }

    updateFilter() {
        if (!this.product){
            return;
        }
        if (!this.product.themes){
            return;
        }
        if (this.filter) {
            this.filteredThemes = this.product.themes.filter(p =>
                p.id.includes(this.filter) || p.label?.includes(this.filter)
            );
        } else {
            this.filteredThemes = this.product.themes;
        }
    }

    getGitlabLink(productId: string) {
        return this.gitLabUrlBuilder.GetResourceUrl(productId);
    }

    previewUrl(theme: Theme): string | undefined {
        if (!this.product){
            return undefined;
        }
        if (!this.product.module) {
            return undefined;
        }
        let productId = this.productId;
        if (this.product.options?.length) {
            productId = this.productId.substring(0, this.productId.indexOf('['));
            productId += '[';
            for (let option of this.product.options) {
                productId += `${option.name}:${this.selectedOptions[option.name]},`;
            }
            productId = productId.substring(0, productId.length - 1);
            productId += ']';
        }

        let url = new URL('/api/theme/preview', environment.hub.renderUrl);
        url.searchParams.append('module', this.product.module);
        url.searchParams.append('product', productId);
        url.searchParams.append('theme', theme.id);
        url.searchParams.append('width', '600');
        url.searchParams.append('height', '400');
        url.searchParams.append('data-storeId', this.storeId);

        return url.toString();
    }
}
