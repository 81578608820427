import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { AppSecret, CreateAppSecret, Fulfiller, FulfillerPermission } from 'app/shared/graph';
import { ActivatedRoute } from '@angular/router';
import { PromptDialogService } from 'app/shared/dialog/prompt-dialog.service';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { PageStatus } from '../../../layout/content/page-status';
import { AppSecretService } from 'app/shared/app-secret/app-secret.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatList, MatListItem } from '@angular/material/list';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ErrorComponent } from 'app/shared/components/error.component';
import { FulfillerService } from './fulfiller.service';

@Component({
    templateUrl: './fulfiller-secrets-page.component.html',
    styleUrls: ['./fulfiller-secrets-page.component.scss'],
    imports: [PageSimpleContentComponent, CopyToClipboardComponent, MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatList, MatListItem, MatButton, MatIcon, ErrorComponent]
})
export class FulfillerSecretsPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly fulfillerService = inject(FulfillerService);
    private readonly appSecretService = inject(AppSecretService);
    private readonly route = inject(ActivatedRoute);
    private readonly promptService = inject(PromptDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly authorizationService = inject(AuthorizationService);

    authorizationContext: AuthorizationContext;

    fulfillerId: string = '';
    secrets: AppSecret[] = [];
    fulfillerWithSecrets?: Fulfiller | null;
    lastCreatedSecret?: CreateAppSecret;

    ngOnInit(): void {
        this.registerSubscription(this.authorizationService.authorizationContext().subscribe(authorizationContext => {
            this.authorizationContext = authorizationContext;
        }));
        this.registerSubscription(this.route.params.subscribe(params => {
                const fulfillerId = params.fulfillerId;
                this.fulfillerId = fulfillerId;
                this.secrets = [];
                this.subscribeWithGenericLoadingErrorHandling(this.fulfillerService.getFulfillerSecrets(fulfillerId), (fulfillerWithSecrets) => {
                    this.fulfillerWithSecrets = fulfillerWithSecrets;
                    this.secrets = fulfillerWithSecrets?.secrets || [];
                });
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    revokeSecret(fulfiller: Fulfiller, secret: AppSecret) {
        if (!fulfiller.fulfillerAppId) {
            return;
        }
        this.confirmDialogService
            .confirm(`Revoke secret ${secret.label}`, `Do you really want to revoke the secret ${secret.label} for the fulfiller ${fulfiller.id} ?`, undefined, { yes: { warn: true } })
            .subscribe(_ => {
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.revokeSecret({ appId: fulfiller.fulfillerAppId!, id: secret.id }), () => {
                    const index = this.secrets.findIndex(s => s.id == secret.id);
                    if (index !== -1) {
                        this.secrets.splice(index, 1);
                    }
                });
            });
    }

    createSecret(fulfiller: Fulfiller) {
        if (!fulfiller.fulfillerAppId) {
            return;
        }
        this.promptService.prompt('Create a new secret', undefined, { defaultValue: this.secrets.length == 0 ? 'default' : undefined, fieldLabel: 'Label' })
            .subscribe(result => {
                this.pageStatus = PageStatus.saving;
                if (this.secrets.find(s => s.label == result)) {
                    this.pageError = new Error('This label is already used by another secret');
                    this.pageStatus = PageStatus.saveError;
                    return;
                }
                this.subscribeWithGenericSavinErrorHandling(this.appSecretService.createAppSecret({ appId: fulfiller.fulfillerAppId!, label: result }), secret => {
                    this.secrets.push({ id: secret.id, label: secret.label });
                    this.lastCreatedSecret = secret;
                });
            });
    }

    canManageSecrets(fulfiller: Fulfiller): boolean {
        return this.authorizationContext.hasFulfillerPermissions(fulfiller.id, FulfillerPermission.ManageFulfillerAppSecrets);
    }
}
