import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatCheckbox } from '@angular/material/checkbox';
import { StoreConfig } from 'app/shared/graph';
import { PageStatus } from '../../../layout/content/page-status';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatFormField, MatLabel, MatError, MatHint } from '@angular/material/form-field';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { StoreConfigService } from './store-config.service';

@Component({
    selector: 'admin-store-settings',
    templateUrl: './store-settings-page.component.html',
    styleUrls: ['./store-settings-page.component.scss'],
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatLabel, MatInput, MatError, MatHint, MatIcon, MatCheckbox, SaveButtonComponent, ErrorComponent]
})
export class StoreSettingsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly storeConfigService = inject(StoreConfigService);

    storeId: string;
    storeConfig?: StoreConfig;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeConfigService.getStoreConfig(this.storeId),
                store => {
                    this.storeConfig = store;
                });
        }))
    }


    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    save() {
        if (!this.storeConfig) {
            return;
        }

        this.pageStatus = PageStatus.saving;
        this.subscribeWithGenericSavinErrorHandling(this.storeConfigService.updateStoreConfig({
            storeId: this.storeId,
            defaultLibraryPackage: this.storeConfig.defaultLibraryPackage,
            allowAnonymousRequest: this.storeConfig.allowAnonymousRequest,
            isRecentUploadsEnabled: this.storeConfig.isRecentUploadsEnabled,
            isProofingEnabled: this.storeConfig.isProofingEnabled,
            uploadInBackgroundEnabled: this.storeConfig.uploadInBackgroundEnabled,
            enableThumbnailGeneration: this.storeConfig.enableThumbnailGeneration
        }));
    }
}
