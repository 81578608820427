import { Component } from '@angular/core';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './store-fulfiller-enable-log-dialog.component.html',
    styleUrls: ['./store-fulfiller-enable-log-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatSuffix, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class StoreFulfillerEnableLogDialogComponent {
    duration: number = 7;

}
