<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Library: Product: ' + (product?.label || productId)"
    [icon]="'card_giftcard'"
>
    @if (product) {
        <div class="content">
            <div>
                <h2>Product Information</h2>
                <div class="info">
                    <div class="admin-field">
                        <div class="label">Label</div>
                        <div class="value">
                            <span adminCopyToClipboard>{{ product.label }}</span>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Module</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ product.module }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">ID</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ product.id }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">PLU</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ product.plu }}</code>
                        </div>
                    </div>
                    @if (product.options) {
                        <div class="admin-field">
                            <div class="label">Options</div>
                            <div class="value">
                                <ul>
                                    @for (option of product.options; track option) {
                                        <li>
                                            {{ option.label }} (<code adminCopyToClipboard>{{ option.name }}</code
                                        >)
                                        </li>
                                        <ul>
                                            @for (value of option.values; track value) {
                                                <li>
                                                    {{ value.label }} (<code adminCopyToClipboard>{{ value.name }}</code
                                                >)
                                                    @if (value.name === option.default) {
                                                        <span> - Default</span>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                    <div class="admin-field">
                        <div class="label">Links</div>
                        <div class="value"><a target="_blank" [href]="getGitlabLink(productId)">GitLab</a></div>
                    </div>
                </div>

                <h2>Themes</h2>
                <div class="themes-header">
                    <mat-form-field class="dense-2">
                        <mat-label>Filter</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput [(ngModel)]="filter" (keydown)="updateFilter()" (keyup)="updateFilter()" />
                    </mat-form-field>
                    <mat-checkbox [(ngModel)]="showPreview">Show Previews</mat-checkbox>
                    @if (product.options && showPreview) {
                        @for (option of product.options; track option) {
                            <mat-form-field>
                                <mat-label>{{ option.label }}</mat-label>
                                <mat-select [(ngModel)]="selectedOptions[option.name]">
                                    @for (value of option.values; track value) {
                                        <mat-option [value]="value.name"
                                        >{{ value.label }} ({{ value.name }})
                                        </mat-option
                                        >
                                    }
                                </mat-select>
                            </mat-form-field>
                        }
                    }
                </div>

                <div class="themes">
                    @for (theme of filteredThemes; track theme) {
                        <div class="theme">
                            <div class="thumbnail-column">
                                @if (theme.thumbnail) {
                                    <img
                                        [attr.alt]="theme.label"
                                        loading="lazy"
                                        class="theme-thumbnail"
                                        [attr.src]="theme.thumbnail"
                                    />
                                }
                            </div>
                            <div class="info">
                                <div class="admin-field">
                                    <div class="label">ID</div>
                                    <div class="value">
                                        <code adminCopyToClipboard>{{ theme.id }}</code>
                                    </div>
                                </div>
                                <div class="admin-field">
                                    <div class="label">Label</div>
                                    <div class="value">
                                        <span adminCopyToClipboard>{{ theme.label }}</span>
                                    </div>
                                </div>
                                <div class="admin-field">
                                    <div class="label">Links</div>
                                    <div class="value">
                                        <a target="_blank" [href]="getGitlabLink(theme.id)">GitLab</a>
                                    </div>
                                </div>
                                @if (showPreview) {
                                    <div class="admin-field">
                                        <div class="label"></div>
                                        <div class="value">
                                            <img class="preview" loading="lazy" [src]="previewUrl(theme)" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    } @else {
        <p>Product not found</p>
    }
</admin-page-simple-content>
