import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Store, StoreLogUnion } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { StoreLogsGroupComponent } from './store-logs-group.component';
import { MatIcon } from '@angular/material/icon';
import { StoreLogsService } from './store-logs.service';

export interface GroupedLogs {
    timestampUtc: string;
    storeLogs: StoreLogUnion[];
}

@Component({
    templateUrl: './store-logs-page.component.html',
    styleUrls: ['./store-logs-page.component.scss'],
    imports: [PageSimpleContentComponent, MatTabGroup, MatTab, StoreLogsGroupComponent, MatIcon]
})
export class StoreLogsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly storeLogsService = inject(StoreLogsService);

    storeId: string;
    hubStoreLogs: GroupedLogs[] = [];
    shopifyStoreLogs: GroupedLogs[] = [];
    store: Store;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(forkJoin([
                this.storeLogsService.getHubStoreLogs(this.storeId),
                this.storeLogsService.getShopifyStoreLogs(this.storeId)
            ]), ([hubStoreLogs, shopifyStoreLogs]) => {
                this.hubStoreLogs = this.groupLogsByDay(hubStoreLogs);
                this.shopifyStoreLogs = this.groupLogsByDay(shopifyStoreLogs);
            })
        }));
    }

    groupLogsByDay(storeLogs: StoreLogUnion[]) {
        const timestamp = new Map<string, StoreLogUnion[]>();

        for (let storeLog of storeLogs) {
            const group = timestamp.get(storeLog.timestampUtc) || [];
            group.push(storeLog);
            timestamp.set(storeLog.timestampUtc, group);
        }

        return [...timestamp.entries()].map(([timestampUtc, storeLogs]) => ({
            timestampUtc,
            storeLogs
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }
}
