import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BuiltInExternalPhotoSourceConfig,
    BuiltInExternalPhotoSourceDefinition,
    CreateBuiltInExternalPhotoSourceInput,
    CreateDirectPhotoSourceInput,
    CreateGenericExternalPhotoSourceInput,
    DeleteBuiltInExternalPhotoSourceInput,
    DeleteGenericExternalPhotoSourceInput,
    DirectPhotoSource,
    GenericExternalPhotoSourceConfig,
    StockPhotoSourceConfig,
    UpdateBuiltInExternalPhotoSourceInput,
    UpdateDirectPhotoSourceInput,
    UpdateGenericExternalPhotoSourceInput
} from 'app/shared/graph';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class ExternalPhotosConfigService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    // region External Photo Source

    loadExternalPhotoSources(storeId: string): Observable<{
        builtInExternalPhotoSources: Array<BuiltInExternalPhotoSourceConfig>,
        genericExternalPhotoSources: Array<GenericExternalPhotoSourceConfig>,
        stockPhotoSources: Array<StockPhotoSourceConfig>
    }> {
        return this.graphDotnetService.query(`
                query loadExternalPhotoSources($storeId: String!) {
                    store(id: $storeId) {
                        builtInExternalPhotoSources {
                            store {
                                id
                            }
                            photoSourceId
                            label
                            details
                            status
                            icon
                            appId
                            data {
                                key
                                value
                            }
                        }
                        genericExternalPhotoSources {
                            store {
                                id
                            }
                            photoSourceId
                            label
                            status
                            icon
                            appId
                            scopes
                            authorizationUrl
                            tokenUrl
                            albumUrl
                            photoUrl
                        }
                        stockPhotoSources {
                            id
                            label
                            thumbnailUrl
                            photosEndpoint {
                              url
                            }
                            albumsEndpoint {
                              url
                            }
                        }
                    }
                }
            `, { storeId: storeId }
        ).pipe(map((result) => ({
            builtInExternalPhotoSources: result.store!.builtInExternalPhotoSources,
            genericExternalPhotoSources: result.store!.genericExternalPhotoSources,
            stockPhotoSources: result.store!.stockPhotoSources
        })));
    }

    // endregion

    // region Generic External Photo Source

    loadGenericExternalPhotoSource(storeId: string, photoSourceId: string): Observable<GenericExternalPhotoSourceConfig | undefined> {
        return this.graphDotnetService.query(`
                query loadGenericExternalPhotoSource($storeId: String!) {
                    store(id: $storeId) {
                        genericExternalPhotoSources {
                            store {
                                id
                            }
                            photoSourceId
                            label
                            status
                            icon
                            appId
                            scopes
                            authorizationUrl
                            tokenUrl
                            albumUrl
                            photoUrl
                        }
                    }
                }
            `, { storeId: storeId }
        ).pipe(
            map((result) => result.store!.genericExternalPhotoSources.find(s => s.photoSourceId == photoSourceId))
        );
    }


    createGenericExternalPhotoSource(input: CreateGenericExternalPhotoSourceInput): Observable<GenericExternalPhotoSourceConfig> {
        return this.graphDotnetService.mutate(`
          mutation createGenericExternalPhotoSource($input: CreateGenericExternalPhotoSourceInput!) {
            createGenericExternalPhotoSource(input: $input) {
              store {
                id
              }
              photoSourceId
              label
              details
              icon
              status
              appId
              scopes
              authorizationUrl
              tokenUrl
              albumUrl
              photoUrl
            }
          }
        `, {
            input
        }).pipe(
            map(m => m.createGenericExternalPhotoSource!)
        );
    }

    updateGenericExternalPhotoSource(input: UpdateGenericExternalPhotoSourceInput): Observable<GenericExternalPhotoSourceConfig> {
        return this.graphDotnetService.mutate(`
          mutation updateGenericExternalPhotoSource($input: UpdateGenericExternalPhotoSourceInput!) {
            updateGenericExternalPhotoSource(input: $input) {
              store {
                id
              }
              photoSourceId
              label
              details
              icon
              status
              appId
              scopes
              authorizationUrl
              tokenUrl
              albumUrl
              photoUrl
            }
          }
        `, {
            input
        }).pipe(
            map(m => m.updateGenericExternalPhotoSource!)
        );
    }

    deleteGenericExternalPhotoSource(input: DeleteGenericExternalPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate(`
          mutation deleteGenericExternalPhotoSource($input: DeleteGenericExternalPhotoSourceInput!) {
            deleteGenericExternalPhotoSource(input: $input)
          }
        `, {
            input
        }).pipe(
            map(m => m.deleteGenericExternalPhotoSource!)
        );
    }

    // endregion

    // region Built-In External Photo Source

    loadBuiltInExternalPhotoSourceDefinitions(): Observable<Array<BuiltInExternalPhotoSourceDefinition>> {
        return this.graphDotnetService.query(`
                query {
                    builtInExternalPhotoSourceDefinitions {
                        label
                        providerId
                        iconUrl
                    }
                }
            `
        ).pipe(map((result) => result.builtInExternalPhotoSourceDefinitions));
    }

    loadBuiltInExternalPhotoSource(storeId: string, photoSourceId: string) {
        return this.graphDotnetService.query(`
                query loadBuiltInExternalPhotoSources($storeId: String!) {
                    store(id: $storeId) {
                        builtInExternalPhotoSources {
                            store {
                                id
                            }
                            photoSourceId
                            label
                            status
                            icon
                            appId
                            data {
                                key
                                value
                            }
                        }
                    }
                }
            `, { storeId: storeId }
        ).pipe(
            map((result) => result.store!.builtInExternalPhotoSources.find(s => s.photoSourceId == photoSourceId))
        );
    }

    createBuiltInExternalPhotoSource(input: CreateBuiltInExternalPhotoSourceInput): Observable<BuiltInExternalPhotoSourceConfig> {
        return this.graphDotnetService.mutate(`
          mutation createBuiltInExternalPhotoSource($input: CreateBuiltInExternalPhotoSourceInput!) {
            createBuiltInExternalPhotoSource(input: $input) {
              store {
                id
              }
              photoSourceId
              label
              details
              icon
              status
              appId
              data {
                key
                value
              }
            }
          }
        `, {
            input
        }).pipe(
            map(m => m.createBuiltInExternalPhotoSource!)
        );
    }

    updateBuiltInExternalPhotoSource(input: UpdateBuiltInExternalPhotoSourceInput): Observable<BuiltInExternalPhotoSourceConfig> {
        return this.graphDotnetService.mutate(`
          mutation updateBuiltInExternalPhotoSource($input: UpdateBuiltInExternalPhotoSourceInput!) {
            updateBuiltInExternalPhotoSource(input: $input) {
              store {
                id
              }
              photoSourceId
              label
              details
              icon
              status
              appId
              data {
                key
                value
              }
            }
          }
        `, {
            input
        }).pipe(
            map(m => m.updateBuiltInExternalPhotoSource!)
        );
    }

    deleteBuiltInExternalPhotoSource(input: DeleteBuiltInExternalPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate(`
          mutation deleteBuiltInExternalPhotoSource($input: DeleteBuiltInExternalPhotoSourceInput!) {
            deleteBuiltInExternalPhotoSource(input: $input)
          }
        `, {
            input
        }).pipe(
            map(m => m.deleteBuiltInExternalPhotoSource!)
        );
    }

    // endregion

    // region Direct Photo Source

    loadDirectPhotoSources(storeId: string): Observable<DirectPhotoSource[]> {
        return this.graphDotnetService.query(`
            query loadDirectPhotoSources($storeId: String!) {
                store(id: $storeId) {
                  directPhotoSources {
                    name
                    photoEndpoint {
                      url
                      headers {
                        name
                        value
                      }
                    }
                  }
                }
            }
        `, { storeId: storeId }
        ).pipe(
            map((result) => result.store!.directPhotoSources)
        );
    }

    loadDirectPhotoSource(storeId: string, directPhotoSourceId: string): Observable<DirectPhotoSource | undefined> {
        return this.loadDirectPhotoSources(storeId).pipe(map(sources => sources.find(s => s.name == directPhotoSourceId)));
    };

    createDirectPhotoSource(input: CreateDirectPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate(`
          mutation createDirectPhotoSource($input: CreateDirectPhotoSourceInput!) {
            createDirectPhotoSource(input: $input)
          }
        `, {
            input
        }).pipe(
            map(m => m.createDirectPhotoSource!)
        );
    }

    updateDirectPhotoSource(input: UpdateDirectPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate(`
          mutation updateDirectPhotoSource($input: UpdateDirectPhotoSourceInput!) {
            updateDirectPhotoSource(input: $input)
          }
        `, {
            input
        }).pipe(
            map(m => m.createDirectPhotoSource!)
        );
    }

    // endregion
}
