<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Request Logger'"
    [icon]="'bug_report'"
>
    <div class="content">
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
        <button mat-stroked-button (click)="loadRules()" class="reload-action">
            <mat-icon>refresh</mat-icon>
            Reload
        </button>
        @for (appName of appNames; track appName) {
            @if (configurationsByAppName[appName]) {
                <mat-card class="app-config">
                    <mat-card-header>
                        <mat-card-title>{{ appName }}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="config">
                            <mat-form-field class="dense-2">
                                <mat-label>Log expiration delay</mat-label>
                                <input
                                    matInput
                                    [(ngModel)]="configurationsByAppName[appName].logsExpirationInSeconds"
                                    disabled
                                />
                                <span matTextSuffix>s</span>
                            </mat-form-field>
                        </div>
                        <div class="rules">
                            @for (rule of configurationsByAppName[appName].rules; track rule) {
                                <div class="rule">
                                    <mat-divider></mat-divider>
                                    <h4>Rule</h4>
                                    <div class="rule-config">
                                        @if (rule.id) {
                                            <div class="field">
                                                <span class="label">Rule ID</span>
                                                <span class="value"
                                                ><code adminCopyToClipboard>{{ rule.id }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (rule.routePrefix) {
                                            <div class="field">
                                                <span class="label">Route Prefix</span>
                                                <span class="value"
                                                ><code>{{ rule.routePrefix }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (rule.statusCode) {
                                            <div class="field">
                                                <span class="label">Status Code</span>
                                                <span class="value"
                                                ><code>{{ rule.statusCode }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (rule.storeId) {
                                            <div class="field">
                                                <span class="label">Store ID</span>
                                                <span class="value"
                                                ><code>{{ rule.storeId }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (rule.maximumCaptureCount) {
                                            <div class="field">
                                                <span class="label">Maximum Capture Count</span>
                                                <span class="value"
                                                ><code>{{ rule.maximumCaptureCount }}</code></span
                                                >
                                            </div>
                                        }
                                        @if (rule.logsExpirationInSeconds) {
                                            <div class="field">
                                                <span class="label">Logs Expiration In Seconds</span>
                                                <span class="value"
                                                ><code>{{ rule.logsExpirationInSeconds }}</code></span
                                                >
                                            </div>
                                        }
                                    </div>
                                    <h4>Logs</h4>
                                    <div>
                                        <button mat-stroked-button (click)="loadLogs(rule.id)">
                                            <mat-icon>refresh</mat-icon>
                                            Check logs
                                        </button>
                                        <div class="logs">
                                            @for (log of logsPerRuleId[rule.id]; track log) {
                                                <div class="log mat-elevation-z4">
                                                    <div class="field">
                                                        <span class="label">Date</span>
                                                        <span class="value"
                                                        ><code>{{ log.date | date: "full" }}</code
                                                        ><span> ({{ log.date | fromNow }})</span></span
                                                        >
                                                    </div>
                                                    <div class="field">
                                                        <span class="label">URL</span>
                                                        <span class="value"
                                                        ><code>{{ log.url }}</code></span
                                                        >
                                                    </div>
                                                    <div class="field">
                                                        <span class="label">Status Code</span>
                                                        <span class="value"
                                                        ><code>{{ log.statusCode }}</code></span
                                                        >
                                                    </div>
                                                    @if (log.headers) {
                                                        <div class="field">
                                                            <span class="label">Headers</span>
                                                            <span class="value">
                                                                <ul>
                                                                    @for (header of log.headers; track header) {
                                                                        <li>
                                                                            <code>{{ header.name }}</code> :
                                                                            <code>{{ header.value }}</code>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </span>
                                                        </div>
                                                    }
                                                    @if (log.body) {
                                                        <div>
                                                            Body
                                                            <pre class="body code-block" adminCopyToClipboard>{{
                                                                    log.body
                                                                }}</pre>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <mat-card-actions align="end">
                                        <button mat-button color="warn" (click)="deleteRule(appName, rule.id)">
                                            <mat-icon>delete</mat-icon>
                                            Delete rule
                                        </button>
                                    </mat-card-actions>
                                </div>
                            }
                        </div>
                        <mat-divider></mat-divider>
                    </mat-card-content>
                    <mat-card-actions>
                        <button
                            mat-button
                            color="primary"
                            [disabled]="pageStatus === 'saving'"
                            (click)="addNewRule(appName)"
                        >
                            <mat-icon>add</mat-icon>
                            Add rule
                        </button>
                    </mat-card-actions>
                </mat-card>
            }
        }
    </div>
</admin-page-simple-content>
