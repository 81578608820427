import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { StoreConfig } from 'app/shared/graph';
import { ActivatedRoute } from '@angular/router';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { StoreEndpointComponent } from './store-endpoint.component';
import { MatIcon } from '@angular/material/icon';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { StoreConfigService } from './store-config.service';

@Component({
    selector: 'admin-store-endpoints',
    templateUrl: './store-endpoints-page.component.html',
    styleUrls: ['./store-endpoints-page.component.scss'],
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatLabel, MatInput, MatError, MatHint, StoreEndpointComponent, MatIcon, SaveButtonComponent, ErrorComponent]
})
export class StoreEndpointsPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly storeConfigService = inject(StoreConfigService);

    storeId: string;
    storeConfig?: StoreConfig;

    urlPattern = /^https?:\/\/.+$/;
    invalidUrlMessage = 'This url is not valid.';

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeConfigService.getStoreConfig(this.storeId), storeConfig => {
                let storeConfigNoNull = { ...storeConfig };
                if (!storeConfigNoNull.addToCartEndpoint) {
                    storeConfigNoNull.addToCartEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.getProductsPriceEndpoint) {
                    storeConfigNoNull.getProductsPriceEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.getPriceEndpoint) {
                    storeConfigNoNull.getPriceEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.onProjectDeletedEndpoint) {
                    storeConfigNoNull.onProjectDeletedEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.statusUpdateEndpoint) {
                    storeConfigNoNull.statusUpdateEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.getProductQuantitiesEndpoint) {
                    storeConfigNoNull.getProductQuantitiesEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.getAlternateProductsEndpoint) {
                    storeConfigNoNull.getAlternateProductsEndpoint = { headers: [] };
                }
                if (!storeConfigNoNull.getProductDisplaySettingsEndpoint) {
                    storeConfigNoNull.getProductDisplaySettingsEndpoint = { headers: [] };
                }
                this.storeConfig = storeConfigNoNull;
            });
        }));
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    save() {
        if (!this.storeConfig) {
            return;
        }

        this.subscribeWithGenericSavinErrorHandling(this.storeConfigService.updateStoreConfig({
            storeId: this.storeId,
            cartRedirectionPage: this.storeConfig.cartRedirectionPage,
            exitPage: this.storeConfig.exitPage,
            loginPage: this.storeConfig.loginPage,
            correctionsCompletedPage: this.storeConfig.correctionsCompletedPage,
            getPriceEndpoint: this.storeConfig.getPriceEndpoint,
            addToCartEndpoint: this.storeConfig.addToCartEndpoint,
            getProductsPriceEndpoint: this.storeConfig.getProductsPriceEndpoint,
            statusUpdateEndpoint: this.storeConfig.statusUpdateEndpoint,
            onProjectDeletedEndpoint: this.storeConfig.onProjectDeletedEndpoint,
            getProductQuantitiesEndpoint: this.storeConfig.getProductQuantitiesEndpoint,
            getAlternateProductsEndpoint: this.storeConfig.getAlternateProductsEndpoint,
            getProductDisplaySettingsEndpoint: this.storeConfig.getProductDisplaySettingsEndpoint
        }));
    }
}
