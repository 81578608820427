<h2 mat-dialog-title>Create a new store</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Label</mat-label>
            <input matInput name="label" [(ngModel)]="label" required />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Environment</mat-label>
            <mat-select name="environment" [(ngModel)]="environment" required>
                @for (env of environments; track env) {
                    <mat-option [value]="env">{{ env }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Designer branch</mat-label>
            <mat-select name="designerBranchId" [(ngModel)]="designerReleaseChannel" required>
                @for (designerBranch of designerReleaseChannels; track designerBranch) {
                    <mat-option [value]="designerBranch.id">{{ designerBranch.id }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput name="appId" [(ngModel)]="appId" pattern="[-a-zA-Z0-9_]+" placeholder="appId" />
            <mat-hint>Optional, if empty one will be automatically generated</mat-hint>
            <mat-error>Only <code>a-z</code>, <code>0-9</code>, <code>_</code> and <code>-</code> characters</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CANCEL</button>

    <button mat-button color="primary" (click)="valid()" [disabled]="!form.valid">CREATE</button>
</mat-dialog-actions>
