import { ChangeDetectionStrategy, Component, Input, forwardRef, inject } from '@angular/core';

import { MediaclipError } from './mediaclip-error';
import { GraphDotnetError } from '../graph/graph-dotnet-service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';

import { TruncatePipe } from '../utilities/truncate.pipe';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'admin-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    imports: [MatButton, MatIcon, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, forwardRef(() => ErrorComponent), TruncatePipe]
})
export class ErrorComponent {
    private readonly clipboard = inject(Clipboard);

    @Input() showTitle = true;
    code?: string;
    message: string;
    details: string | undefined;
    stack?: string;
    innerError?: Error;
    failedQuery?: string;
    responseBody?: string;

    @Input()
    set error(value: MediaclipError | GraphDotnetError | Error | undefined) {
        if (!value) {
            this.code = 'null error';
            this.message = 'An error was dispatched but no error data is available';
            this.details = undefined;
            this.stack = undefined;
            return;
        }

        if (value instanceof MediaclipError) {
            this.message = value.message;
            this.innerError = value.innerError;
            this.stack = value.innerError?.stack;
            return;
        }

        if (value instanceof GraphDotnetError) {
            this.message = value.message;
            this.innerError = value.innerError;
            this.failedQuery = value.query;
            this.responseBody = value.responseBody;
            return;
        }

        if (value instanceof Error) {
            this.message = value.message;
            this.details = value.stack;
            return;
        }
    }

    removeLeadingWhitespace(text: string) {
        let lines = text.split('\n').filter(l => l.length > 0);
        if (!text) {
            return text;
        }
        let minSpaceCount = lines[0].length;
        for (let line of lines) {
            line = line.replace('\r', '');
            for (let i = 0; i < line.length && i < minSpaceCount; i++) {
                if (line.charAt(i) == ' ') {
                    continue;
                }
                minSpaceCount = i;
                break;
            }
        }

        let result = '';
        for (let line of lines) {
            result += line.substring(minSpaceCount) + '\n';
        }

        return result;
    }

    copyErrorInfo() {
        let toCopy = '';
        if (this.message) {
            toCopy += '## Message\n\n````\n' + this.message + '\n```\n\n';
        }
        if (this.failedQuery) {
            toCopy += '## Failed query\n\n```gql\n' + this.failedQuery + '\n```\n\n';
        }
        if (this.responseBody) {
            toCopy += '## Response\n\n```json\n' + this.responseBody + '\n```\n\n';
        }
        if (this.stack) {
            toCopy += '## Stack\n\n````\n' + this.stack + '\n```\n\n';
        }
        if (this.details) {
            toCopy += '## Details\n\n```\n`' + this.details + '\n```\n\n';
        }
        this.clipboard.copy(toCopy);
    }
}
