import { Component, Input, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { GroupedLogs } from './store-logs-page.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GlobalPermission } from 'app/shared/graph';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatChipSet, MatChip } from '@angular/material/chips';
import { NgClass, DatePipe } from '@angular/common';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { FromNowPipe } from '../../../shared/utilities/from-now.pipe';
import { TruncatePipe } from '../../../shared/utilities/truncate.pipe';
import { QuoteToCodePipe } from './quote-to-code.pipe';

@Component({
    templateUrl: './store-logs-group.component.html',
    styleUrls: ['./store-logs-group.component.scss'],
    selector: 'admin-store-logs-group',
    imports: [MatTooltip, MatIcon, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatChipSet, MatChip, NgClass, CopyToClipboardComponent, DatePipe, FromNowPipe, TruncatePipe, QuoteToCodePipe]
})
export class StoreLogsGroupComponent extends AdminComponentBase implements OnInit {
    private readonly observer = inject(BreakpointObserver);
    private readonly authService = inject(AuthorizationService);

    @Input() storeLogs: GroupedLogs[] = [];
    authContext?: AuthorizationContext;

    maxMessageLength = 100;
    maxMessageLengthByScreenSize = new Map([
        [Breakpoints.XSmall, 15],
        [Breakpoints.Small, 40],
        [Breakpoints.Medium, 90],
        [Breakpoints.Large, 110],
        [Breakpoints.XLarge, 130]
    ]);

    ngOnInit(): void {
        this.registerSubscription(this.observer.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge
        ]).subscribe(result => {
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                    this.maxMessageLength = this.maxMessageLengthByScreenSize.get(query) ?? 100;
                }
            }
        }));

        this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
    }

    canReadApplicationInsightsLogs() {
        return this.authContext?.hasGlobalPermissions(GlobalPermission.ReadApplicationInsightsLogs);
    }
}
