import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
    templateUrl: './direct-photo-source-add-dialog.component.html',
    styleUrls: ['./direct-photo-source-add-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class DirectPhotoSourceAddDialogComponent {
    dialogRef = inject<MatDialogRef<DirectPhotoSourceAddDialogComponent, string>>(MatDialogRef);

    public photoSourceIdControl = new UntypedFormControl('', [
        Validators.required,
        Validators.max(255),
        Validators.pattern(/^[a-z0-9]+$/),
    ]);

    create(photoSourceId: string): void {
        this.dialogRef.close(photoSourceId);
    }
}
