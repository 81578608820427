<admin-page-simple-content
    id="store-fulfillers"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Order: ' + order?.id"
    [icon]="'attach_money'"
>
    @if (order) {
        <div class="content">
            <div class="section">
                <h2>
                    <mat-icon>assignment</mat-icon>
                    Order Information
                    @if (order.__typename === "ArchivedOrder") {
                        <span> (Archived)</span>
                    }
                </h2>
                <table class="order-info">
                    <div class="admin-field">
                        <div class="label">Store ID</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ order.store.id }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Creation date</div>
                        <div class="value">
                            <code>{{ order.dateCreatedUtc | date: "medium" }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Hub Order ID</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ order.id }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Store Order ID</div>
                        <div class="value">
                            <code adminCopyToClipboard>{{ order.storeOrderId }}</code>
                        </div>
                    </div>
                    <div class="admin-field">
                        <div class="label">Lines count</div>
                        <div class="value">
                            <code>{{ order.lines?.length }}</code>
                        </div>
                    </div>
                    @if (order.__typename === "Order" && order.properties?.length) {
                        <div class="admin-field">
                            <div class="label">Properties</div>
                            <div class="value">
                                <ul>
                                    @for (property of order.properties; track property) {
                                        <li>
                                            <code adminCopyToClipboard>{{ property.name }}</code
                                            >: <code adminCopyToClipboard>{{ property.value }}</code>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                </table>
            </div>
            @if (isOrderRetryable(order) || canViewOriginalCheckoutJson(order)) {
                <div class="section">
                    <h3>
                        <mat-icon>view_module</mat-icon>
                        Actions
                    </h3>
                    <div class="actions">
                        @if (isOrderRetryable(order)) {
                            <button
                                mat-stroked-button
                                color="primary"
                                [disabled]="isRenderInProgress(order)"
                                (click)="retry(order.id)"
                            >
                                <mat-icon>replay</mat-icon>
                                Retry all failed lines
                            </button>
                        }
                        @if (canViewOriginalCheckoutJson(order)) {
                            <button mat-stroked-button color="primary" (click)="showOriginalCheckoutJson(order)">
                                <mat-icon>code</mat-icon>
                                View Original Checkout Json
                            </button>
                        }

                        @if (canManageOrderData(order)) {
                            <div class="separator"></div>
                            <button mat-icon-button [matMenuTriggerFor]="advanceOrderActionMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #advanceOrderActionMenu>
                                @if (order.rawData.value) {
                                    <button mat-menu-item (click)="showRawCheckoutJson(order)" class="edit-button">
                                        <mat-icon>edit</mat-icon>
                                        View Raw Order Data
                                    </button>
                                }
                                @if (order.rawData.backupValue) {
                                    <button
                                        mat-menu-item
                                        (click)="showBackupRawCheckoutJson(order)"
                                        class="edit-button"
                                    >
                                        <mat-icon>edit</mat-icon>
                                        View Backup Raw Order Data
                                    </button>
                                }
                            </mat-menu>
                        }
                    </div>
                </div>
            }
            <mat-divider></mat-divider>
            @if (loadingLine && !selectedLine) {
                <div>
                    <mat-spinner></mat-spinner>
                </div>
            }
            @if (selectedLine) {
                <div class="section">
                    <h3 class="lines-header">
                        <mat-icon>assignment</mat-icon>
                        <div class="line-header-content">
                            @if (order.lines?.length > 1) {
                                <mat-form-field>
                                    <mat-select
                                        [value]="selectedLine.storeOrderLineNumber"
                                        (valueChange)="selectLine($event)"
                                        [disabled]="loadingLine"
                                    >
                                        <mat-select-trigger>
                                            Line {{ selectedLine.fulfillerHubLineNumber || "..." }} ({{
                                                selectedLine.storeOrderLineNumber || "..."
                                            }})
                                        </mat-select-trigger>
                                        @for (line of lines; track line) {
                                            <mat-option [value]="line.storeOrderLineNumber">
                                                <span class="line-selector-element">
                                                    <span class="line-number">
                                                        Line {{ line.fulfillerHubLineNumber || "..." }} ({{
                                                            line.storeOrderLineNumber || "..."
                                                        }})
                                                    </span>
                                                    @if (line.status) {
                                                        <span class="line-status">
                                                            <admin-line-status
                                                                [status]="line.status.value"
                                                                [flags]="line.status.flags"
                                                            ></admin-line-status>
                                                        </span>
                                                    }
                                                    <span class="line-id secondary-text">
                                                        {{ line.id }}
                                                    </span>
                                                </span>
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            }
                            @if (lines.length === 1) {
                                <h2>
                                    Line {{ lines[0].fulfillerHubLineNumber }} ({{ lines[0].storeOrderLineNumber }})
                                </h2>
                            }
                            @if (selectedLine.status) {
                                <admin-line-status
                                    [status]="selectedLine.status.value"
                                    [flags]="selectedLine.status.flags"
                                ></admin-line-status>
                            }
                        </div>
                    </h3>
                    @if (selectedLine) {
                        <div class="section">
                            <h3>
                                <mat-icon>view_module</mat-icon>
                                Actions
                            </h3>
                            @if (!loadingLine) {
                                <div class="actions">
                                    <!-- TODO: We should add a dirty property on orders, which disables any functionality until an udpate has been received -->
                                    <button mat-stroked-button extended (click)="refresh(order!, selectedLine!.id)">
                                        <mat-icon>autorenew</mat-icon>
                                        Reload
                                    </button>
                                    @if (selectedLine.__typename === "OrderLine") {
                                        @if (selectedLine.projectVersion && hasPermission(order, "ReadProjects")) {
                                            <button
                                                mat-stroked-button
                                                [routerLink]="[
                                                    '/projects',
                                                    selectedLine.projectVersion.project.id,
                                                    'versions',
                                                    selectedLine.projectVersion.id,
                                                ]"
                                            >
                                                <mat-icon>code</mat-icon>
                                                Open project's information
                                            </button>
                                        }
                                        @if (hasPermission(order, "ManageOrders")) {
                                            <button
                                                mat-stroked-button
                                                (click)="updateLineStatus(selectedLine, hasAllowSetOrderToAnyStatus())"
                                            >
                                                <mat-icon>call_split</mat-icon>
                                                Change status
                                            </button>
                                        }
                                        @if (canForceRerender(order, selectedLine)) {
                                            <button
                                                mat-stroked-button
                                                (click)="forceRerender(order!, selectedLine!.id)"
                                            >
                                                <mat-icon>replay</mat-icon>
                                                Force re-render
                                            </button>
                                        }
                                        @if (hasPermission(order, "ManageOrders") &&
                                        (selectedLine.status?.value === "WaitingForCorrections" ||
                                            selectedLine.status?.value === "TransferredToCustomerSupport")) {
                                            <a
                                                mat-stroked-button
                                                rel="external"
                                                target="_blank"
                                                (click)="startDesigner(selectedLine)"
                                            >
                                                <mat-icon>edit</mat-icon>
                                                Edit Project Current Version
                                            </a>
                                        }
                                    }
                                </div>
                            }
                        </div>
                        <div>
                            @if (pageStatus === "saveError" && pageError) {
                                <admin-error [error]="pageError"></admin-error>
                            }
                        </div>
                        @if (loadingLine) {
                            <mat-spinner matSuffix></mat-spinner>
                        }
                        @if (!loadingLine) {
                            <div class="section pb-48">
                                <h3>
                                    <mat-icon>assignment</mat-icon>
                                    Line Information ({{ getLineSequenceNumber(order.lines, selectedLine) }} /
                                    {{ order.lines.length }})
                                </h3>
                                <div class="line-info">
                                    <div class="admin-field">
                                        <div class="label">Status</div>
                                        <div class="value">
                                            <admin-line-status
                                                [status]="selectedLine.status.value"
                                                [flags]="selectedLine.status.flags"
                                            ></admin-line-status>
                                            since {{ selectedLine.status.effectiveDateUtc | date: "medium" }}
                                        </div>
                                    </div>
                                    @if (selectedLine.status.details) {
                                        <div class="admin-field">
                                            <div class="label">Status Details</div>
                                            <div class="value">{{ selectedLine.status.details }}</div>
                                        </div>
                                    }
                                    @if (selectedLine.price) {
                                        @if (selectedLine.price.value) {
                                            <div class="admin-field">
                                                <div class="label">Price</div>
                                                <div class="value">
                                                    <admin-currency
                                                        [value]="selectedLine.price.value"
                                                        [currency]="selectedLine.price.currency"
                                                    ></admin-currency>
                                                    <a
                                                        [attr.href]="
                                                            'https://www.google.com/search?q=' +
                                                            selectedLine.price.value +
                                                            '%20' +
                                                            selectedLine.price.currency +
                                                            '%20in'
                                                        "
                                                        rel="external"
                                                        target="_blank"
                                                    >(other currencies)</a
                                                    >
                                                </div>
                                            </div>
                                        }
                                        @if (selectedLine.price.originalLinePrice) {
                                            <div class="admin-field">
                                                <div class="label">OriginalLinePrice</div>
                                                <div class="value">
                                                    <admin-currency
                                                        [value]="selectedLine.price.originalLinePrice"
                                                        [currency]="selectedLine.price.currency"
                                                    ></admin-currency>
                                                    <a
                                                        [attr.href]="
                                                            'https://www.google.com/search?q=' +
                                                            selectedLine.price.value +
                                                            '%20' +
                                                            selectedLine.price.currency +
                                                            '%20in'
                                                        "
                                                        rel="external"
                                                        target="_blank"
                                                    >(other currencies)</a
                                                    >
                                                </div>
                                            </div>
                                        }
                                        @if (selectedLine.price.finalLinePrice) {
                                            <div class="admin-field">
                                                <div class="label">FinalLinePrice</div>
                                                <div class="value">
                                                    <admin-currency
                                                        [value]="selectedLine.price.finalLinePrice"
                                                        [currency]="selectedLine.price.currency"
                                                    ></admin-currency>
                                                    <a
                                                        [attr.href]="
                                                            'https://www.google.com/search?q=' +
                                                            selectedLine.price.value +
                                                            '%20' +
                                                            selectedLine.price.currency +
                                                            '%20in'
                                                        "
                                                        rel="external"
                                                        target="_blank"
                                                    >(other currencies)</a
                                                    >
                                                </div>
                                            </div>
                                        }
                                    }
                                    <div class="admin-field">
                                        <div class="label">Quantity</div>
                                        <div class="value">
                                            <code>{{ selectedLine.quantity }}</code>
                                        </div>
                                    </div>
                                    @if (selectedLine.fulfiller) {
                                        <div class="admin-field">
                                            <div class="label">Fulfiller</div>
                                            <div class="value">
                                                <code adminCopyToClipboard>{{ selectedLine.fulfiller.id }}</code>
                                            </div>
                                        </div>
                                    }
                                    <div class="admin-field">
                                        <div class="label">Store Line Number</div>
                                        <div class="value">
                                            <code adminCopyToClipboard>{{ selectedLine.storeOrderLineNumber }}</code>
                                        </div>
                                    </div>
                                    @if (selectedLine.storeOrderLineId) {
                                        <div class="admin-field">
                                            <div class="label">Store Order Line Id</div>
                                            <div class="value">
                                                <code adminCopyToClipboard>{{ selectedLine.storeOrderLineId }}</code>
                                            </div>
                                        </div>
                                    }
                                    <div class="admin-field">
                                        <div class="label">Hub Fulfiller Line Number</div>
                                        <div class="value">
                                            <code adminCopyToClipboard>{{ selectedLine.fulfillerHubLineNumber }}</code>
                                        </div>
                                    </div>
                                    <div class="admin-field">
                                        <div class="label">Line ID</div>
                                        <div class="value">
                                            <code adminCopyToClipboard>{{ selectedLine.id }}</code>
                                        </div>
                                    </div>
                                    @if (selectedLine.__typename === "OrderLine") {
                                        @if (!!selectedLine.projectVersion) {
                                            <div class="admin-field">
                                                <div class="label">Project ID</div>
                                                <div class="value">
                                                    <code adminCopyToClipboard>{{
                                                            selectedLine.projectVersion.project.id
                                                        }}</code>
                                                    version
                                                    <code adminCopyToClipboard>{{
                                                            selectedLine.projectVersion.id
                                                        }}</code>
                                                </div>
                                            </div>
                                        }
                                    }
                                    @if (selectedLine.storeProductId) {
                                        <div class="admin-field">
                                            <div class="label">Product ID</div>
                                            <div class="value">
                                                <code adminCopyToClipboard>{{ selectedLine.storeProductId }}</code>
                                            </div>
                                        </div>
                                    }
                                    @if (selectedLine.projectVersion && selectedLine.projectVersion.sheetCount) {
                                        <div class="admin-field">
                                            <div class="label">Photobook Sheets</div>
                                            <div class="value">
                                                <code>{{ selectedLine.projectVersion.sheetCount }}</code> ({{
                                                    selectedLine.projectVersion.sheetCount -
                                                    (selectedLine.projectVersion.additionalSheetCount ?? 0)
                                                }}
                                                + {{ selectedLine.projectVersion.additionalSheetCount }})
                                            </div>
                                        </div>
                                    }
                                    @if (selectedLine.__typename === "OrderLine") {
                                        @if (selectedLine.shipping) {
                                            <div class="admin-field">
                                                <div class="label">Shipping Information</div>
                                                <div class="value">
                                                    <code>{{ selectedLine.shipping.carrier }}</code
                                                    >:
                                                    <a [attr.href]="selectedLine.shipping.trackingUrl">{{
                                                            selectedLine.shipping.trackingNumber
                                                        }}</a>
                                                </div>
                                            </div>
                                        }
                                        @if (selectedLine.properties?.length) {
                                            <div class="admin-field">
                                                <div class="label">Properties</div>
                                                <div class="value">
                                                    @for (item of selectedLine.properties; track item) {
                                                        <div>
                                                            <code>{{ item.name }}</code> - <code>{{ item.value }}</code>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                            @if (selectedLine.__typename === "OrderLine") {
                                <div>
                                    <h3>
                                        <mat-icon>image</mat-icon>
                                        <div>Files ({{ selectedLine.files?.length || 0 }})</div>
                                    </h3>
                                    @if (!selectedLine.files) {
                                        <p>No files available yet</p>
                                    }
                                    @if (selectedLine.files) {
                                        <table class="files-table" mat-table [dataSource]="selectedLine.files">
                                            <mat-header-row
                                                *matHeaderRowDef="['links', 'id', 'plu', 'productId', 'quantity']"
                                            ></mat-header-row>
                                            <mat-row
                                                *matRowDef="
                                                    let file;
                                                    columns: ['links', 'id', 'plu', 'productId', 'quantity']
                                                "
                                            ></mat-row>
                                            <ng-container matColumnDef="links">
                                                <mat-header-cell *matHeaderCellDef>Links</mat-header-cell>
                                                <mat-cell *matCellDef="let file">
                                                    <a
                                                        [attr.href]="file.url"
                                                        target="_blank"
                                                        rel="external"
                                                        mat-stroked-button
                                                        matTooltip="Download"
                                                    >
                                                        <mat-icon>cloud_download</mat-icon>
                                                    </a>
                                                    @if (file.error) {
                                                        <mat-icon matTooltip="file.error">error</mat-icon>
                                                    }
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="plu">
                                                <mat-header-cell *matHeaderCellDef>PLU</mat-header-cell>
                                                <mat-cell *matCellDef="let file">
                                                    <code>{{ file.plu }}</code>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="id">
                                                <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
                                                <mat-cell *matCellDef="let file">
                                                    <code>{{ file.id }}</code>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="productId">
                                                <mat-header-cell *matHeaderCellDef>Product</mat-header-cell>
                                                <mat-cell *matCellDef="let file">
                                                    <a
                                                        [routerLink]="['/stores', order.store.id, 'library', 'product']"
                                                        [queryParams]="{ id: file.productId }"
                                                    >
                                                        {{ file.productId }}
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="quantity">
                                                <mat-header-cell *matHeaderCellDef>Quantity</mat-header-cell>
                                                <mat-cell *matCellDef="let file">{{ file.quantity }}</mat-cell>
                                            </ng-container>
                                        </table>
                                    }
                                </div>
                            }
                            <div class="section">
                                <h3>
                                    <mat-icon>access_time</mat-icon>
                                    <div>Logs</div>
                                </h3>
                                @if (logs === undefined && !loadingLogs) {
                                    <button mat-stroked-button (click)="loadLogs(selectedLine!.id)">
                                        <mat-icon>autorenew</mat-icon>
                                        Load logs
                                    </button>
                                }
                                @if (loadingLogs) {
                                    <mat-progress-bar mode="buffer"></mat-progress-bar>
                                }
                                @if (loadingLogError) {
                                    <admin-error [error]="loadingLogError"></admin-error>
                                }
                                @if (selectedLine.logs && !selectedLine.logs.length) {
                                    <p>No logs available for this line</p>
                                }
                                @if (logs?.length) {
                                    <div class="logs">
                                        <div
                                            class="header"
                                            [class.log]="selectedLine.__typename === 'OrderLine'"
                                            [class.archived-log]="selectedLine.__typename === 'ArchivedOrderLine'"
                                        >
                                            @if (selectedLine.__typename === "OrderLine") {
                                                <div>Source</div>
                                            }
                                            <div>Message</div>
                                            <div>Timestamp</div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        @for (log of logs; track log) {
                                            <div>
                                                @switch (log.__typename) {
                                                    @case ("OrderLineLog") {
                                                        <div class="log">
                                                            <span class="source">
                                                                {{ log.source }}
                                                            </span>
                                                            <span class="message">
                                                                {{ log.message }}
                                                            </span>
                                                            <span class="timestamp" [matTooltip]="log.timestampUtc">
                                                                {{ log.timestampUtc | date: "short" }} ({{
                                                                    log.timestampUtc | fromNow
                                                                }})
                                                            </span>
                                                            @if (log.details) {
                                                                <div class="details details-simple">
                                                                    <td colspan="2">
                                                                        <pre><code
                                                                            [innerHTML]="log.details | removeHtml:'\n** HTML content was stripped' | linkify"></code></pre>
                                                                    </td>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    @case ("OrderLineApplicationInsightsLog") {
                                                        <div class="log">
                                                            <span class="source">
                                                                {{ log.cloudRoleName }}
                                                                {{ log.cloudRoleInstance }}
                                                            </span>
                                                            <span class="message">
                                                                @if (log.success) {
                                                                    <div>Render completed in {{ log.duration }} ms</div>
                                                                }
                                                                @if (!log.success) {
                                                                    <div class="error-message">
                                                                        <mat-icon color="warn">warning</mat-icon> Render
                                                                        failed after {{ log.duration }} ms
                                                                    </div>
                                                                }
                                                            </span>
                                                            <span [matTooltip]="log.timestampUtc" class="timestamp">
                                                                {{ log.timestampUtc | date: "short" }} ({{
                                                                    log.timestampUtc | fromNow
                                                                }})
                                                            </span>
                                                            <div class="details">
                                                                Exceptions:
                                                                @if (log.exceptions.length === 0) {
                                                                    <span>No exceptions found</span>
                                                                }
                                                                @for (exception of log.exceptions; track exception) {
                                                                    <div class="exception">
                                                                        <span class="severityLevel">{{
                                                                                exception.severityLevel
                                                                            }}</span>
                                                                        <pre><code
                                                                            [innerHTML]="exception.outerMessage | removeHtml:'\n** HTML content was stripped' | linkify"></code></pre>
                                                                        @if (canViewApplicationInsights() &&
                                                                        exception.openQueryUrl) {
                                                                            <button
                                                                                matTooltip="Open query in Application insights"
                                                                                mat-icon-button
                                                                                (click)="
                                                                                    openApplicationInsightsForLog(
                                                                                        exception.openQueryUrl
                                                                                    )
                                                                                "
                                                                            >
                                                                                <mat-icon>open_in_new</mat-icon>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    @case ("ArchivedOrderLineLog") {
                                                        <div class="archived-log">
                                                            <span class="message">
                                                                <div>
                                                                    Status changed to
                                                                    <span class="status">{{ log.value }}</span>
                                                                </div>
                                                                <div>
                                                                    {{ log.message }}
                                                                </div>
                                                            </span>
                                                            <span class="timestamp" [matTooltip]="log.timestampUtc">
                                                                {{ log.timestampUtc | date: "short" }} ({{
                                                                    log.timestampUtc | fromNow
                                                                }})
                                                            </span>
                                                        </div>
                                                    }
                                                }
                                            </div>
                                            <mat-divider></mat-divider>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    }
                </div>
            }
        </div>
    }
</admin-page-simple-content>
