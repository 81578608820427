import { AuthorizationContext } from 'app/shared/authentication';
import {
    NavCategory,
    NavCategoryTypes,
    Navigation,
    NavigationCategoryIdValues,
    NavigationPageIdValues,
    NavPage,
    NavPageType
} from './navigation-types';
import {
    ViewContext,
    ViewContextFulfiller,
    ViewContextLibrary,
    ViewContextNone,
    ViewContextStore,
    ViewContextSubscription
} from './view-context.service';
import {
    FulfillerPermission,
    GlobalPermission,
    LibraryPermission,
    StorePermission,
    SubscriptionPermission
} from 'app/shared/graph';

function buildNoneNavigation(_viewContext: ViewContextNone, _auth: AuthorizationContext): Navigation {
    let navigation: Navigation = [];

    navigation.push({
        id: NavigationCategoryIdValues.general,
        type: NavCategoryTypes.group,
        title: 'General',
        children: []
    });

    return navigation;
}

function buildSubscriptionNavigation(viewContext: ViewContextSubscription, auth: AuthorizationContext): Navigation {
    let navigation: Navigation = [];

    navigation.push({
        id: NavigationCategoryIdValues.subscription,
        title: 'Subscription',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [{
            id: NavigationPageIdValues.manageSubscription,
            title: 'Manage Subscription',
            type: NavPageType.item,
            icon: 'receipt',
            exactMatch: true,
            subscriptionPermission: SubscriptionPermission.ReadSubscriptionMetadata,
            url: `/subscriptions/${viewContext.subscriptionId}`
        }, {
            id: NavigationPageIdValues.subscriptionAuthorizedUsers,
            title: 'Authorized Users',
            type: NavPageType.item,
            icon: 'perm_identity',
            exactMatch: true,
            subscriptionPermission: SubscriptionPermission.ManageAdminUsers,
            url: `/subscriptions/${viewContext.subscriptionId}/authorizedUsers`
        }, {
            id: NavigationPageIdValues.subscriptionAuthorizedUsers,
            title: 'Billing',
            type: NavPageType.item,
            icon: 'receipt_long',
            exactMatch: true,
            subscriptionPermission: SubscriptionPermission.ReadInvoices,
            url: `/subscriptions/${viewContext.subscriptionId}/billing`
        }, {
            id: NavigationPageIdValues.subscriptionCleanup,
            title: 'Cleanup',
            type: NavPageType.item,
            icon: 'cleaning_services',
            exactMatch: true,
            subscriptionPermission: SubscriptionPermission.ReadSubscriptionMetadata,
            url: `/subscriptions/${viewContext.subscriptionId}/cleanup`
        }, {
            id: NavigationPageIdValues.generatedImagesSubscription,
            title: 'Generated Image Definitions',
            type: NavPageType.item,
            icon: 'qr_code',
            exactMatch: true,
            subscriptionPermission: SubscriptionPermission.ReadGeneratedImages,
            url: `/subscriptions/${viewContext.subscriptionId}/generated-images`
        }
        ])
    });
    return navigation;
}

function buildFulfillerNavigation(viewContext: ViewContextFulfiller, auth: AuthorizationContext): Navigation {
    let navigation: Navigation = [];

    navigation.push({
        id: NavigationCategoryIdValues.dashboards,
        title: 'Fulfiller: ' + viewContext.fulfillerId,
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [{
            id: NavigationPageIdValues.fulfillerConfig,
            title: 'Manage Fulfiller',
            type: NavPageType.item,
            icon: 'print',
            exactMatch: true,
            fulfillerPermission: FulfillerPermission.ManageFulfillerConfig,
            url: `/fulfillers/${viewContext.fulfillerId}`
        }, {
            id: NavigationPageIdValues.fulfillerAuthorizedUsers,
            title: 'Authorized Users',
            type: NavPageType.item,
            icon: 'perm_identity',
            exactMatch: true,
            fulfillerPermission: FulfillerPermission.ReadAdminUsers,
            url: `/fulfillers/${viewContext.fulfillerId}/authorizedUsers`
        }, {
            id: NavigationPageIdValues.fulfillerSecrets,
            title: 'API Secrets',
            type: NavPageType.item,
            icon: 'vpn_key',
            url: `/fulfillers/${viewContext.fulfillerId}/secrets`,
            fulfillerPermission: FulfillerPermission.ManageFulfillerAppSecrets
        }
        ])
    });

    return navigation;
}

function buildLibraryNavigation(viewContext: ViewContextLibrary, auth: AuthorizationContext): Navigation {
    let navigation: Navigation = [];

    navigation.push({
        id: NavigationCategoryIdValues.dashboards,
        title: 'Library: ' + viewContext.libraryOwner,
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [{
            id: NavigationPageIdValues.manageLibrary,
            title: 'Manage Library',
            type: NavPageType.item,
            icon: 'assignment',
            exactMatch: true,
            libraryPermission: LibraryPermission.ReadLibrary,
            url: `/libraries/${viewContext.libraryOwner}`
        }, {
            id: NavigationPageIdValues.libraryAuthorizedUsers,
            title: 'Authorized Users',
            type: NavPageType.item,
            icon: 'perm_identity',
            exactMatch: true,
            libraryPermission: LibraryPermission.ReadAdminUsers,
            url: `/libraries/${viewContext.libraryOwner}/authorizedUsers`
        }])
    });

    return navigation;
}

function buildStoreNavigation(viewContext: ViewContextStore, auth: AuthorizationContext): Navigation {
    let navigation: Navigation = [];

    navigation.push({
        id: NavigationCategoryIdValues.dashboards,
        title: 'Dashboards',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [{
            id: NavigationPageIdValues.dashboardsOverview,
            title: 'Overview',
            type: NavPageType.item,
            icon: 'dashboard',
            url: `/stores/${viewContext.storeId}/dashboards/overview`
        }, {
            storePermission: StorePermission.ReadStoreLogs,
            id: NavigationPageIdValues.storeLogs,
            title: 'Store Logs',
            type: NavPageType.item,
            icon: 'list_alt',
            url: `/stores/${viewContext.storeId}/logs`
        }])
    });

    navigation.push({
        id: NavigationCategoryIdValues.orders,
        title: 'Orders',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [
            {
                id: NavigationPageIdValues.ordersRecent,
                title: 'Recent Orders',
                type: NavPageType.item,
                icon: 'inbox',
                url: `/stores/${viewContext.storeId}/lists/recent`,
                storePermission: StorePermission.ReadOrders
            },
            {
                id: NavigationPageIdValues.ordersActionRequired,
                title: 'Action Required',
                type: NavPageType.item,
                icon: 'warning',
                url: `/stores/${viewContext.storeId}/lists/action-required`,
                storePermission: StorePermission.ReadOrders
            },
            {
                id: NavigationPageIdValues.ordersActionRequired,
                title: 'Create Order',
                type: NavPageType.item,
                icon: 'add_shopping_cart',
                url: `/stores/${viewContext.storeId}/create-order`,
                storePermission: StorePermission.ManageOrders
            }
        ])
    });

    navigation.push({
        id: NavigationCategoryIdValues.library,
        title: 'Library',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [
            {
                id: NavigationPageIdValues.libraryProducts,
                title: 'Products',
                type: NavPageType.item,
                icon: 'card_giftcard',
                url: `/stores/${viewContext.storeId}/library/products`,
                badge: null,
                storePermission: StorePermission.ReadStoreLibrary
            }
        ])
    });

    navigation.push({
        id: NavigationCategoryIdValues.subscription,
        title: 'Subscription',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [
            {
                id: NavigationPageIdValues.subscription,
                title: 'Subscription',
                type: NavPageType.item,
                icon: 'workspaces',
                url: `/subscriptions/${viewContext.subscriptionId}`,
                subscriptionPermission: SubscriptionPermission.ManageSubscriptionMetadata
            }
        ])
    });

    navigation.push({
        id: NavigationCategoryIdValues.settings,
        title: 'Settings',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [
            {
                id: NavigationCategoryIdValues.settings,
                title: 'Store Settings',
                type: NavCategoryTypes.collapsable,
                icon: 'settings',
                children: filterVisibleItems(auth, viewContext, [
                    {
                        id: NavigationPageIdValues.settingsStatus,
                        title: 'Mediaclip Management',
                        type: NavPageType.item,
                        icon: 'business_center',
                        url: `/stores/${viewContext.storeId}/settings/status`,
                        storePermission: StorePermission.ManageStoreMetadata
                    },
                    {
                        id: NavigationPageIdValues.settingsGeneral,
                        title: 'General',
                        type: NavPageType.item,
                        icon: 'assignment',
                        url: `/stores/${viewContext.storeId}/settings/general`,
                        storePermission: StorePermission.ManageStoreConfigs
                    },
                    {
                        id: NavigationPageIdValues.settingsEndpoints,
                        title: 'Pages & Endpoints',
                        type: NavPageType.item,
                        icon: 'settings_ethernet',
                        url: `/stores/${viewContext.storeId}/settings/endpoints`,
                        storePermission: StorePermission.ManageStoreConfigs
                    },
                    {
                        id: NavigationPageIdValues.settingsPhotosSources,
                        title: 'Photos Sources',
                        type: NavPageType.item,
                        icon: 'image',
                        url: `/stores/${viewContext.storeId}/settings/external-photo-sources`,
                        storePermission: StorePermission.ManagePhotoSources
                    },
                    {
                        id: NavigationPageIdValues.settingsPhotosUpscaling,
                        title: 'Photos Upscaling',
                        type: NavPageType.item,
                        icon: 'photo_size_select_large',
                        url: `/stores/${viewContext.storeId}/settings/photos-upscaling`,
                        storePermission: StorePermission.ManageStoreUpscaling
                    },
                    {
                        id: NavigationPageIdValues.settingsGeneratedText,
                        title: 'Generated Texts',
                        type: NavPageType.item,
                        icon: 'text_fields',
                        url: `/stores/${viewContext.storeId}/settings/generated-text`,
                        storePermission: StorePermission.ManageGeneratedText
                    },
                    {
                        id: NavigationPageIdValues.storeFulfillers,
                        title: 'Fulfillers',
                        type: NavPageType.item,
                        icon: 'print',
                        url: `/stores/${viewContext.storeId}/settings/fulfillers`,
                        storePermission: StorePermission.ReadStoreFulfillers
                    },
                    {
                        id: NavigationPageIdValues.setttingsAuthorizedUsers,
                        title: 'Authorized Users',
                        type: NavPageType.item,
                        icon: 'perm_identity',
                        url: `/stores/${viewContext.storeId}/settings/authorized-users`,
                        globalPermission: GlobalPermission.ManageAdminUsers
                    },
                    {
                        id: NavigationPageIdValues.settingsSecrets,
                        title: 'API Secrets',
                        type: NavPageType.item,
                        icon: 'vpn_key',
                        url: `/stores/${viewContext.storeId}/settings/secrets`,
                        storePermission: StorePermission.ManageStoreSecrets
                    }
                ])
            },
            {
                id: NavigationCategoryIdValues.settings,
                title: 'Designer Settings',
                type: NavCategoryTypes.collapsable,
                icon: 'settings',
                children: filterVisibleItems(auth, viewContext, [
                    {
                        id: NavigationPageIdValues.customJavascript,
                        title: 'Custom Javascript',
                        type: NavPageType.item,
                        icon: 'description',
                        url: `/stores/${viewContext.storeId}/designer/custom-javascript`,
                        storePermission: StorePermission.ReadDesignerCustomizations
                    },
                    {
                        id: NavigationPageIdValues.customCss,
                        title: 'Custom CSS',
                        type: NavPageType.item,
                        icon: 'description',
                        url: `/stores/${viewContext.storeId}/designer/custom-css`,
                        storePermission: StorePermission.ReadDesignerCustomizations
                    },
                    {
                        id: NavigationPageIdValues.customLocales,
                        title: 'Custom Locales',
                        type: NavPageType.item,
                        icon: 'translate',
                        url: `/stores/${viewContext.storeId}/designer/custom-locales`,
                        storePermission: StorePermission.ReadDesignerCustomizations
                    },
                    {
                        id: NavigationPageIdValues.designerLogo,
                        title: 'Logo',
                        type: NavPageType.item,
                        icon: 'insert_photo',
                        url: `/stores/${viewContext.storeId}/designer/logo`,
                        storePermission: StorePermission.ReadDesignerCustomizations
                    },
                    {
                        id: NavigationPageIdValues.builtInFilters,
                        title: 'Built-in Filters',
                        type: NavPageType.item,
                        icon: 'insert_photo',
                        url: `/stores/${viewContext.storeId}/designer/photo-filters`,
                        storePermission: StorePermission.ManageLocalFilters
                    },
                    {
                        id: NavigationPageIdValues.remoteFilters,
                        title: 'Remote Filters',
                        type: NavPageType.item,
                        icon: 'blur_on',
                        url: `/stores/${viewContext.storeId}/designer/remote-filters`,
                        storePermission: StorePermission.ManageRemoteFilters
                    },
                    {
                        id: NavigationPageIdValues.filterCategories,
                        title: 'Filter Categories',
                        type: NavPageType.item,
                        icon: 'folder',
                        url: `/stores/${viewContext.storeId}/designer/filter-categories`,
                        storePermission: StorePermission.ReadFilterCategories
                    },
                    {
                        id: NavigationPageIdValues.settingsDesignerReleaseChannels,
                        title: 'Designer Release Channel',
                        type: NavPageType.item,
                        icon: 'merge_type',
                        url: `/stores/${viewContext.storeId}/designer/release-channel`,
                        storePermission: StorePermission.ManageDesignerBranchRules
                    },
                    {
                        id: NavigationPageIdValues.settingsDesignerReleaseChannels,
                        title: 'Generated Images',
                        type: NavPageType.item,
                        icon: 'qr_code',
                        url: `/stores/${viewContext.storeId}/designer/generated-images`,
                        storePermission: StorePermission.ManageGeneratedImages
                    }
                ])
            }
        ])
    });

    return navigation;
}

export function buildNavigation(viewContext: ViewContext, auth: AuthorizationContext): Navigation {
    let navigation: Navigation;
    switch (viewContext.type) {
        case 'store':
            navigation = buildStoreNavigation(viewContext, auth);
            break;
        case 'subscription':
            navigation = buildSubscriptionNavigation(viewContext, auth);
            break;
        case 'fulfiller':
            navigation = buildFulfillerNavigation(viewContext, auth);
            break;
        case 'library':
            navigation = buildLibraryNavigation(viewContext, auth);
            break;
        case 'none':
            navigation = buildNoneNavigation(viewContext, auth);
            break;
        default:
            navigation = [];
            break;
    }

    let othersCategory = {
        id: NavigationCategoryIdValues.superadmin,
        title: 'Other',
        type: NavCategoryTypes.group,
        children: [] as NavPage[]
    };
    navigation.push(othersCategory);
    if (auth.authenticatedUser.libraries.length > 0 || auth.hasGlobalPermissions(GlobalPermission.ListLibraries)) {
        othersCategory.children.push({
            id: NavigationPageIdValues.libraries,
            title: 'Libraries',
            type: NavPageType.item,
            icon: 'library_books',
            exactMatch: true,
            url: `/libraries/`
        });
    }

    if (auth.authenticatedUser.fulfillers.length > 0 || auth.hasGlobalPermissions(GlobalPermission.ListFulfillers)) {
        othersCategory.children.push({
            id: NavigationPageIdValues.fulfillers,
            title: 'Fulfillers',
            type: NavPageType.item,
            icon: 'print',
            exactMatch: true,
            url: `/fulfillers`
        });
    }

    if (auth.authenticatedUser.subscriptionPermissions.length > 0 || auth.hasGlobalPermissions(GlobalPermission.ListSubscriptions)) {
        othersCategory.children.push({
            id: NavigationPageIdValues.subscriptions,
            title: 'Subscriptions',
            type: NavPageType.item,
            icon: 'receipt',
            exactMatch: true,
            url: `/subscriptions/`
        });
    }

    navigation.push({
        id: NavigationCategoryIdValues.superadmin,
        title: 'Superadmin',
        type: NavCategoryTypes.group,
        children: filterVisibleItems(auth, viewContext, [
            {
                id: NavigationPageIdValues.anomalies,
                title: 'Anomalies',
                type: NavPageType.item,
                icon: 'report_problem',
                url: `/anomalies`,
                globalPermission: GlobalPermission.SystemAnomalies
            },
            {
                id: NavigationPageIdValues.manageDesignerBranch,
                title: 'Manage Designer Branches',
                type: NavPageType.item,
                icon: 'public',
                exactMatch: true,
                url: `/manage-designer-release-channel-version`,
                globalPermission: GlobalPermission.ManageDesignerBranch
            },
            {
                id: NavigationPageIdValues.generatedImagesGlobal,
                title: 'Generated Image Definitions',
                type: NavPageType.item,
                icon: 'qr_code',
                exactMatch: true,
                url: `/generated-images`,
                globalPermission: GlobalPermission.ManageGeneratedImages
            },
            {
                id: NavigationPageIdValues.stockPhotoSources,
                title: 'Stock Photo Sources',
                type: NavPageType.item,
                icon: 'image',
                exactMatch: true,
                url: `/stock-photo-sources`,
                globalPermission: GlobalPermission.ListStockPhotoSources
            },
            {
                id: NavigationPageIdValues.requestLogger,
                title: 'Request Logger',
                type: NavPageType.item,
                icon: 'bug_report',
                exactMatch: true,
                url: `/request-logger`,
                globalPermission: GlobalPermission.ManageRequestLogger
            },
            {
                id: NavigationPageIdValues.globalAuthorizedUsers,
                title: 'Users',
                type: NavPageType.item,
                icon: 'group',
                exactMatch: true,
                url: `/admin-users`,
                globalPermission: GlobalPermission.ManageAdminUsers
            }
        ])
    });

    return navigation.filter(x => x.children.length > 0);
}

interface RequiredPermission {
    storePermission?: StorePermission;
    subscriptionPermission?: SubscriptionPermission;
    globalPermission?: GlobalPermission;
    libraryPermission?: LibraryPermission;
    fulfillerPermission?: FulfillerPermission;
}

function filterVisibleItems(
    auth: AuthorizationContext,
    viewContext: ViewContext,
    items: ((NavPage | NavCategory) & RequiredPermission)[]
): (NavPage | NavCategory)[] {
    return items.filter(item => {
        switch (viewContext.type) {
            case 'store': {
                if (item.storePermission) {
                    return auth.hasStorePermissions(viewContext.storeId, viewContext.subscriptionId, item.storePermission);
                }
                if (item.subscriptionPermission) {
                    return auth.hasSubscriptionPermissions(viewContext.subscriptionId, item.subscriptionPermission);
                }
                if (item.globalPermission) {
                    return auth.hasGlobalPermissions(item.globalPermission);
                }
                break;
            }
            case 'subscription': {
                if (item.subscriptionPermission) {
                    return auth.hasSubscriptionPermissions(viewContext.subscriptionId, item.subscriptionPermission);
                }
                if (item.globalPermission) {
                    return auth.hasGlobalPermissions(item.globalPermission);
                }
                break;
            }
            case 'library': {
                if (item.libraryPermission) {
                    return auth.hasLibraryPermissions(viewContext.libraryOwner, item.libraryPermission);
                }
                if (item.globalPermission) {
                    return auth.hasGlobalPermissions(item.globalPermission);
                }
                break;
            }
            case 'fulfiller': {
                if (item.fulfillerPermission) {
                    return auth.hasFulfillerPermissions(viewContext.fulfillerId, item.fulfillerPermission);
                }
                if (item.globalPermission) {
                    return auth.hasGlobalPermissions(item.globalPermission);
                }
                break;
            }
            case 'none': {
                if (item.globalPermission) {
                    return auth.hasGlobalPermissions(item.globalPermission);
                }
            }
        }
        return true;
    });
}
