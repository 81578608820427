import { Component, Input, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'admin-secret-input-toggle',
    templateUrl: './secret-input-toggle.component.html',
    styleUrls: ['./secret-input-toggle.component.scss'],
    imports: [MatIconButton, MatIcon]
})
export class SecretInputToggleComponent implements OnInit {
    @Input()
    secretInput: HTMLInputElement;

    ngOnInit() {
        this.secretInput.type = 'password';
    }

    get isHidden() {
        return this.secretInput.type === 'password';
    }

    get icon() {
        return this.isHidden ? 'visibility_off' : 'visibility';
    }

    toggle($event: MouseEvent) {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        $event.stopPropagation();
        if (this.isHidden) {
            this.secretInput.type = 'text';
        } else {
            this.secretInput.type = 'password';
        }
    }
}
