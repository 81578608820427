<h2 mat-dialog-title>Add culture</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>culture</mat-label>
            <input matInput name="culture" [(ngModel)]="culture" pattern="^[a-zA-Z]{2}(-[a-zA-Z]{2})?$" required />
            <mat-hint>Culture code like <code>en-ca</code> or <code>en</code></mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">CANCEL</button>
    <button mat-button color="primary" [mat-dialog-close]="culture" [disabled]="!form.valid">OK</button>
</mat-dialog-actions>
