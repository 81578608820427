import { Component } from '@angular/core';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

@Component({
    templateUrl: './root-page.component.html',
    styleUrls: ['./root-page.component.scss'],
    imports: [FusePerfectScrollbarDirective]
})
export class RootPageContainerComponent {
}
