import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalPhotoSourceStatus, BuiltInExternalPhotoSourceConfig, BuiltInExternalPhotoSourceDataInput } from 'app/shared/graph';
import { GooglePhotoFilterDefinitions } from './google-photos-filters';
import { v4 as uuidv4 } from 'uuid';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SecretInputToggleComponent } from 'app/shared/components/secret-input-toggle.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { PageStatus } from '../../../layout/content/page-status';
import { ExternalPhotosConfigService } from './external-photos-config.service';

@Component({
    templateUrl: './built-in-external-photo-source-edit-page.component.html',
    styleUrls: ['./built-in-external-photo-source-edit-page.component.scss'],
    animations: fuseAnimations,
    imports: [PageSimpleContentComponent, FormsModule, MatFormField, MatLabel, MatInput, SecretInputToggleComponent, MatSuffix, MatHint, MatCheckbox, MatError, CodemirrorModule, SaveButtonComponent, ErrorComponent]
})
export class BuiltInExternalPhotoSourceEditPageComponent implements OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly photosConfigService = inject(ExternalPhotosConfigService);
    private readonly router = inject(Router);

    public mode: 'edit' | 'create';
    public title = '';
    public storeId: string;
    public photoSourceId: string;
    public pageStatus: PageStatus = PageStatus.loading;
    public pageError?: Error;

    public builtInExternalSource?: BuiltInExternalPhotoSourceConfig;
    public appSecret ?: string;
    private redirectTimerId?: number;

    // region Google photos

    public googlePhotosFilters ?: string;
    public googleLoginRedirectUrl ?: string;

    // endregion

    constructor() {
        this.storeId = this.route.snapshot.params.storeId;
        this.photoSourceId = this.route.snapshot.params.photoSourceId;
        this.mode = this.route.snapshot.data.mode;
        if (this.mode === 'edit') {
            this.title = 'Edit BuiltIn External Photo Source';
            this.photosConfigService.loadBuiltInExternalPhotoSource(this.storeId, this.photoSourceId)
                .subscribe({
                    next: source => {
                        if (!source) {
                            this.pageStatus = PageStatus.loadError;
                            this.pageError = new Error('Photo source not found');
                            return;
                        }

                        this.builtInExternalSource = source;
                        this.initSpecificFromData(source);
                        this.pageStatus = PageStatus.loaded;
                    },
                    error: err => {
                        this.pageStatus = PageStatus.loadError;
                        this.pageError = err;
                    }
                });
        } else {
            this.title = 'Add New BuiltIn External Photo Source';
            this.photosConfigService.loadBuiltInExternalPhotoSourceDefinitions()
                .subscribe({
                    next: sources => {
                        const source = sources.find(s => s.providerId == this.photoSourceId);
                        if (!source) {
                            this.pageStatus = PageStatus.loadError;
                            this.pageError = new Error('Photo source not found');
                            return;
                        }

                        this.builtInExternalSource = {
                            icon: source.iconUrl,
                            label: source.label,
                            photoSourceId: source.providerId,
                            appId: '',
                            data: [],
                            status: ExternalPhotoSourceStatus.Disabled,
                            store: { id: this.storeId } as any
                        };
                        this.initSpecificFromData(this.builtInExternalSource);
                        this.pageStatus = PageStatus.loaded;
                    },
                    error: err => {
                        this.pageStatus = PageStatus.loadError;
                        this.pageError = err;
                    }
                });
            this.pageStatus = PageStatus.loaded;
        }

    }

    save() {
        if (!this.builtInExternalSource) {
            return;
        }
        if (this.mode == 'edit') {
            this.photosConfigService.updateBuiltInExternalPhotoSource({
                storeId: this.storeId,
                photoSourceId: this.photoSourceId,
                appId: this.builtInExternalSource.appId,
                appSecret: this.appSecret,
                data: this.getSpecificData()
            }).subscribe({
                next: this.completeSave.bind(this),
                error: this.saveError.bind(this)
            });
        } else {
            if (!this.appSecret) {
                return;
            }

            let input = {
                storeId: this.storeId,
                photoSourceId: this.photoSourceId,
                appId: this.builtInExternalSource.appId,
                appSecret: this.appSecret,
                data: this.getSpecificData()
            };
            this.photosConfigService.createBuiltInExternalPhotoSource(input).subscribe({
                next: this.completeSave.bind(this),
                error: this.saveError.bind(this)
            });
        }

        this.pageStatus = PageStatus.saving;
    }

    private saveError(err: Error) {
        this.pageStatus = PageStatus.loadError;
        this.pageError = err;
    }

    private completeSave(source: BuiltInExternalPhotoSourceConfig) {
        this.pageStatus = PageStatus.loaded;
        this.builtInExternalSource = source;
        this.appSecret = undefined;
        this.redirectTimerId = window.setTimeout(() => {
            this.router.navigate(['stores', this.storeId, 'settings', 'external-photo-sources']);
        }, 500);
    }

    private initSpecificFromData(source: BuiltInExternalPhotoSourceConfig) {
        if (source.photoSourceId === 'google-photos') {
            let googleLoginRedirectUrlData = source.data.find(s => s.key === 'GoogleLoginRedirectUrl');
            if (googleLoginRedirectUrlData) {
                this.googleLoginRedirectUrl = googleLoginRedirectUrlData.value;
            }
            let googlePhotosFiltersData = source.data.find(s => s.key === 'GooglePhotosFilters');
            if (googlePhotosFiltersData) {
                this.googlePhotosFilters = JSON.stringify(JSON.parse(googlePhotosFiltersData.value), null, 4);
            } else {
                this.googlePhotosFilters = JSON.stringify(this.initGooglePhotosFilters(), null, 4);
            }
        }
    }

    private getSpecificData(): BuiltInExternalPhotoSourceDataInput[] {
        const data: BuiltInExternalPhotoSourceDataInput[] = [];

        if (this.photoSourceId === 'google-photos') {
            if (this.googleLoginRedirectUrl) {
                data.push({
                    key: 'GoogleLoginRedirectUrl',
                    value: this.googleLoginRedirectUrl
                });
            }
            if (this.googlePhotosFilters) {
                data.push({
                    key: 'GooglePhotosFilters',
                    value: JSON.stringify(JSON.parse(this.googlePhotosFilters))
                });
            }
        }
        return data;
    }

    private initGooglePhotosFilters() {
        const peopleId = uuidv4();
        const petId = uuidv4();
        const travelId = uuidv4();
        const weddingId = uuidv4();

        return {
            definitions: {
                [peopleId]: {
                    localesLabel: { en: 'People' },
                    googleFilterRequest: {
                        contentFilter: { includedContentCategories: ['PEOPLE'] }
                    }
                },
                [petId]: {
                    localesLabel: { en: 'Pets' },
                    googleFilterRequest: {
                        contentFilter: { includedContentCategories: ['PETS'] }
                    }
                },
                [travelId]: {
                    localesLabel: { en: 'Travel' },
                    googleFilterRequest: {
                        contentFilter: { includedContentCategories: ['TRAVEL'] }
                    }
                },
                [weddingId]: {
                    localesLabel: { en: 'Wedding' },
                    googleFilterRequest: {
                        contentFilter: { includedContentCategories: ['WEDDINGS'] }
                    }
                }
            }
        } as GooglePhotoFilterDefinitions;
    }

    ngOnDestroy(): void {
        if (this.redirectTimerId) {
            clearTimeout(this.redirectTimerId);
            this.redirectTimerId = undefined;
        }
    }
}
