import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Order, Store, StoreFulfiller } from 'app/shared/graph';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatIcon } from '@angular/material/icon';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatButton, MatIconAnchor } from '@angular/material/button';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { OrdersService } from './orders.service';
import { StoreFulfillersService } from './store-fulfillers.service';

@Component({
    templateUrl: './create-order.component.html',
    styleUrls: ['./create-order.component.scss'],
    imports: [PageSimpleContentComponent, MatIcon, CodemirrorModule, FormsModule, CopyToClipboardComponent, MatIconAnchor, RouterLink, MatButton, ErrorComponent, MatCard, MatCardHeader, MatCardTitle, MatCardContent]
})
export class CreateOrderComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly storeFulfillersService = inject(StoreFulfillersService);
    private readonly ordersService = inject(OrdersService);

    storeFulfillers?: Array<StoreFulfiller>;
    createOrderRequest?: CreateOrderRequest;
    checkoutJson: string = '';
    createdOrder?: Order;

    storeId: string;

    ngOnInit() {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.storeFulfillersService.getStoreFulfillers(this.storeId), storeFulfillers => {
                this.storeFulfillers = storeFulfillers;
                this.updatePreview();
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    updatePreview() {
        try {
            this.createOrderRequest = JSON.parse(this.checkoutJson);
        } catch (e) {
            this.createOrderRequest = undefined;
        }
    }

    createOrder() {
        if (!this.storeFulfillers) {
            return;
        }

        this.createdOrder = undefined;
        this.subscribeWithGenericSavinErrorHandling(this.ordersService.createOrder({
            checkoutJson: this.checkoutJson,
            storeId: this.storeId,
            release: true
        }), (order) => {
            this.createdOrder = order;
        });
    }
}

export interface CreateOrderRequest {
    orderRequestHeader: OrderRequestHeaderRequest;
    itemOut: ItemOutRequest[];
}

export interface ItemOutRequest {
    lineNumber: number;
    itemId: ItemIDRequest;
    quantity: number;
    itemDetail: ItemDetailRequest;
    supplierId: ItemSupplierIdRequest;
}

export interface ItemSupplierIdRequest {
    domain: string;
    value: string;
}

export interface ItemDetailRequest {
    unitPrice: UnitPriceRequest;
}

export interface UnitPriceRequest {
    money: MoneyRequest;
}

export interface MoneyRequest {
    currency: string;
    value: number;
}

export interface ItemIDRequest {
    buyerPartId: string;
    supplierPartAuxiliaryId: string;
}

export interface OrderRequestHeaderRequest {
    orderID: string;
    shipTo: ShipToRequest;
    billTo: BillToRequest;
    shipping: ShippingRequest;
    contacts: ContactRequest[];
}

export interface BillToRequest {
    address: AddressRequest;
}

export interface AddressRequest {
    postalAddress: PostalAddressRequest;
    email: DescriptionRequest;
    phone: PhoneRequest;
}

export interface DescriptionRequest {
    value: string;
}

export interface PhoneRequest {
    number: string;
}

export interface PostalAddressRequest {
    deliverTo: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: CountryRequest;
}

export interface CountryRequest {
    isoCountryCode: string;
    value: string;
}

export interface ContactRequest {
    role: string;
    idReference: IDReferenceRequest[];
    postalAddress?: PostalAddressRequest;
    email?: DescriptionRequest;
    phone?: PhoneRequest;
}

export interface IDReferenceRequest {
    identifier: string;
    domain: string;
}

export interface ShipToRequest {
    address: AddressRequest;
    carrierIdentifier: CarrierIdentifierRequest[];
}

export interface CarrierIdentifierRequest {
    domain: string;
    value: string;
}

export interface ShippingRequest {
    money: MoneyRequest;
    description: DescriptionRequest;
}
