<admin-page-simple-content
    id="store-photos-upscaling"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Photos Upscaling'"
    [icon]="'photo_size_select_large'"
>
    <div class="content">
        <p>
            This page allows you to configure the photos upscaling during the rendering of an order. See the
            <a href="https://doc.mediaclip.ca/hub/features/upscale/" target="_blank" class="external">
                documentation
                <mat-icon>open_in_new</mat-icon>
            </a>
            for more information.
        </p>
        @if (hasConfigDefined) {
            <div>
                <h2>Actions</h2>
                <button mat-stroked-button color="warn" (click)="deleteConfig()" [disabled]="pageStatus === 'saving'">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            </div>
        }

        <h2>Configuration</h2>

        <form #form="ngForm" (ngSubmit)="save()">
            <mat-checkbox name="enable" [(ngModel)]="currentUpscalingConfig.enabled"> Enabled</mat-checkbox>

            <mat-form-field class="dense-2">
                <mat-label>Upscaler Provider</mat-label>

                <mat-select name="type" [(ngModel)]="currentUpscalingConfig.type" placeholder="Type" required>
                    <mat-option [value]="UpscalerType.Picsart">Picsart</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="dense-2">
                <mat-label>API Key</mat-label>
                <input
                    matInput
                    #secretInput
                    name="apiKey"
                    [(ngModel)]="apiKey"
                    autocomplete="off"
                    [required]="!hasConfigDefined"
                />
                <admin-secret-input-toggle matSuffix [secretInput]="secretInput" />
            </mat-form-field>

            @if (!enableUpscalingByProduct) {
                <mat-form-field class="dense-2">
                    <mat-label>Min. DPI Upscale</mat-label>
                    <input
                        matInput
                        name="minDpiUpscaleConfiguration"
                        [(ngModel)]="currentUpscalingConfig.minDpiUpscaleConfiguration"
                        type="number"
                        [min]="0"
                        autocomplete="off"
                    />
                    <mat-hint> Only positive number is allowed. (default: 300)</mat-hint>
                </mat-form-field>
            }
            <mat-checkbox (change)="selectedUpscaleOption($event)" value="-1" [checked]="enableUpscalingByProduct">
                Apply upscaling on specific products only
            </mat-checkbox>

            <admin-save-button (save)="save()" [disabled]="!form.valid" [pageStatus]="pageStatus"></admin-save-button>
        </form>
    </div>

    <div>
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
    </div>
</admin-page-simple-content>
