<admin-page-simple-content
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Settings: API Secrets'"
    [icon]="'key'"
>
    @if (fulfillerWithSecrets) {
        <div class="content">
            @if (!fulfillerWithSecrets.fulfillerAppId) {
                <h2>Information</h2>
                <p>This fulfiller is not linked to a fulfiller app yet.</p>
            }
            @if (fulfillerWithSecrets.fulfillerAppId) {
                <h2>Information</h2>
                <p>
                    This is the list of secrets used to
                    <a href="https://doc.mediaclip.ca/hub/api/authorization/store"
                    >authenticate your fulfiller against Mediaclip Hub</a
                    >.
                </p>
                <p>
                    If you revoke a secret all the call using it will fail immediately, so if you want to change the
                    secret, start by creating a new secret, then revoke the old one once you've updated the usage.
                </p>
                <p>
                    To authenticate requests when calling the api, use the AppKey:
                    <code adminCopyToClipboard>{{ fulfillerWithSecrets.fulfillerAppId }}</code>
                </p>
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Secrets</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-list>
                            @for (secret of secrets; track secret) {
                                <mat-list-item>
                                    <div class="secret">
                                        {{ secret.id }} - {{ secret.label }}
                                        @if (canManageSecrets(fulfillerWithSecrets)) {
                                            <button
                                                mat-stroked-button
                                                color="warn"
                                                (click)="revokeSecret(fulfillerWithSecrets, secret)"
                                            >
                                                <mat-icon>delete</mat-icon>
                                                REVOKE
                                            </button>
                                        }
                                    </div>
                                </mat-list-item>
                            }
                        </mat-list>
                        <div>
                            @if (canManageSecrets(fulfillerWithSecrets)) {
                                <button mat-stroked-button (click)="createSecret(fulfillerWithSecrets)">
                                    <mat-icon>add</mat-icon>
                                    CREATE NEW SECRET
                                </button>
                            }
                        </div>
                    </mat-card-content>
                </mat-card>
                @if (lastCreatedSecret) {
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Generated secret</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p>
                                A new secret was successfully generated. It will be shown only once, you'll have to
                                generate a new one if you lose this.
                            </p>
                            <p>Label: {{ lastCreatedSecret.label }}</p>
                            <p>
                                Secret: <code adminCopyToClipboard>{{ lastCreatedSecret.secret }}</code>
                            </p>
                        </mat-card-content>
                    </mat-card>
                }
                <div>
                    @if (pageStatus === "saveError" && pageError) {
                        <admin-error [error]="pageError"></admin-error>
                    }
                </div>
            }
        </div>
    }
</admin-page-simple-content>
