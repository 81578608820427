import { Component, HostBinding, Input } from '@angular/core';
import { NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    imports: [NgClass, RouterLink, RouterLinkActive, NgTemplateOutlet, MatIcon, NgStyle]
})
export class FuseNavHorizontalItemComponent
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;

    /**
     * Constructor
     */
    constructor()
    {

    }
}
