import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ViewContextService } from './view-context.service';
import { inject } from '@angular/core';

export const NoStore = '_';

export const viewContextGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : UrlTree | boolean => {
    const router = inject(Router);
    const viewContextService = inject(ViewContextService);

    if (route.paramMap.has('storeId')) {
        let storeId = route.paramMap.get('storeId');
        if (storeId === NoStore) {
            let path = route.firstChild?.url.map((u) => u.path).join('/');
            if (path) {
                localStorage.setItem('routeRedirectAfterStoreSelect', path);
            } else {
                localStorage.removeItem('routeRedirectAfterStoreSelect');
            }
            return router.parseUrl('/select-store');
        } else {
            // FIXME: if store id does not exists, redirect to select-store ?
            viewContextService.selectStore(storeId);
        }
    } else if (route.paramMap.has('subscriptionId')) {
        let subscriptionId = route.paramMap.get('subscriptionId');
        if (subscriptionId === NoStore) {
            let path = route.firstChild?.url.map((u) => u.path).join('/');
            if (path) {
                localStorage.setItem('routeRedirectAfterSubscriptionSelect', path);
            } else {
                localStorage.removeItem('routeRedirectAfterSubscriptionSelect');
            }
            return router.parseUrl('/select-subscription');
        } else {
            viewContextService.selectSubscription(subscriptionId);
        }
    } else if (route.paramMap.has('libraryOwner')) {
        let libraryOwner = route.paramMap.get('libraryOwner');
        viewContextService.selectLibrary(libraryOwner);
    } else if (route.paramMap.has('fulfillerId')) {
        let fulfillerId = route.paramMap.get('fulfillerId');
        viewContextService.selectFulfiller(fulfillerId);
    }
    return true;
};
