import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { GeneratedImagesService } from './generated-images.service';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { GeneratedImageStoreAssociation, Store } from 'app/shared/graph';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatDivider } from '@angular/material/divider';
import { TruncatePipe } from '../../../shared/utilities/truncate.pipe';

@Component({
    templateUrl: './generated-image-store-associations-page.component.html',
    styleUrls: ['./generated-image-store-associations-page.component.scss'],
    imports: [PageSimpleContentComponent, RouterLink, MatAnchor, MatIcon, ErrorComponent, CopyToClipboardComponent, MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, MatDivider, TruncatePipe]
})
export class GeneratedImageStoreAssociationsPageComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly generatedImagesService = inject(GeneratedImagesService);
    private readonly authorizationService = inject(AuthorizationService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    storeId: string;
    authContext: AuthorizationContext;
    storeWithGeneratedImages?: Store;

    ngOnInit() {
        this.authorizationService.authorizationContext().subscribe((authContext) => {
            this.authContext = authContext;
        });
        this.registerSubscription(
            this.route.params.subscribe(params => {
                this.storeId = params.storeId;

                this.subscribeWithGenericLoadingErrorHandling(this.generatedImagesService.listStoreAssociations(this.storeId), (storeWithGeneratedImages) => {
                    this.storeWithGeneratedImages = storeWithGeneratedImages;
                });

            }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    delete(association: GeneratedImageStoreAssociation) {
        this.confirmDialogService.confirm(
            'Delete Generated Image'
            , `<p><strong>Deleting this may break existing projects.</strong></p>
<p>Be sure to provide an alternative version with the same alias to keep them working.</p>
 <p>Are you sure you want to delete the generated image association with the alias: <code>${association.alias}</code> ?</p>`,
            undefined,
            {
                yes: {
                    label: 'Delete',
                    warn: true
                }
            })
            .subscribe(() => {
                this.subscribeWithGenericSavinErrorHandling(this.generatedImagesService.deleteGeneratedImageAssociation(association.id));
                let indexToRemove = this.storeWithGeneratedImages?.generatedImageAssociations.findIndex(x => x.id === association.id);
                this.storeWithGeneratedImages?.generatedImageAssociations.splice(indexToRemove!, 1);
            });
    }
}
