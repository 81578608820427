import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export interface EditInvoiceDialogData {
    value: number,
    currency: string,
}

export interface EditInvoiceDialogResult {
    value: string,
}

@Component({
    templateUrl: './edit-invoice-dialog.component.html',
    styleUrls: ['./edit-invoice-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatFormField, MatPrefix, MatInput, FormsModule, MatDialogActions, MatButton, MatDialogClose]
})
export class EditInvoiceDialogComponent {
    dialogRef = inject<MatDialogRef<EditInvoiceDialogData, EditInvoiceDialogResult>>(MatDialogRef);
    data = inject<EditInvoiceDialogData>(MAT_DIALOG_DATA);

    invoiceAmount: number;

    constructor() {
        const data = this.data;

        this.invoiceAmount = data.value;
    }

    valid() {
        this.dialogRef.close({
            value: this.invoiceAmount.toString()
        });
    }
}
