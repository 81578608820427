<admin-page-simple-content [pageStatus]="$any('loaded')" [icon]="'insert_photo'" [title]="'Designer: Logo'">
    <div class="content">
        <div class="info">
            <p>You can upload an image that will be displayed as your logo in the designer.</p>
        </div>
        <div class="actions">
            <input
                #file
                type="file"
                accept="image/svg+xml, image/png, image/jpeg, image/jpg"
                (change)="onFilesChanged($any($event.target).files)"
                style="display: none"
            />
            @if (logoUrl) {
                <button mat-stroked-button color="warn" (click)="deleteLogo()" [disabled]="pageStatus !== PageStatus.loaded">
                    <mat-icon>delete</mat-icon>
                    Delete
                </button>
            }
            <admin-save-button [pageStatus]="pageStatus" (save)="file.click()" [useContent]="true">
                <mat-icon>cloud_upload</mat-icon>&nbsp; Upload
            </admin-save-button>
            @if (pageStatus === "saveError" && pageError) {
                <admin-error [error]="pageError"></admin-error>
            }
        </div>
        <div class="logo">
            <img #logo [src]="logoUrl" />
        </div>
    </div>
</admin-page-simple-content>
