import { Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';
import { OrderLineStatusValues } from '../graph/schema-dotnet';

const colorsMap: { [name: string]: string } = {
    [OrderLineStatusValues.Error]: 'red-900',
    [OrderLineStatusValues.WaitingForRelease]: 'purple-800',
    [OrderLineStatusValues.SentToRendering]: 'blue-900',
    [OrderLineStatusValues.WaitingForReview]: 'pink-900',
    [OrderLineStatusValues.WaitingForCorrections]: 'purple-900',
    [OrderLineStatusValues.AvailableForDownload]: 'green-900',
    [OrderLineStatusValues.SentToFulfillment]: 'green-900',
    [OrderLineStatusValues.TrackingInformationAvailable]: 'green-900',
    [OrderLineStatusValues.Shipped]: 'green-900',
    [OrderLineStatusValues.OnHold]: 'orange-900',
    [OrderLineStatusValues.Cancelled]: 'grey-700',
    [OrderLineStatusValues.WaitingForFulfillment]: 'purple-700',
    [OrderLineStatusValues.Undefined]: 'grey-700',
};

@Directive({ selector: '[adminStatusColor]' })
export class StatusColorComponentDirective {
    private elementRef = inject(ElementRef);
    private renderer = inject(Renderer2);

    private previous: string | null = null;

    @Input('adminStatusColor')
    set status(value: OrderLineStatusValues) {
        if (this.previous) {
            this.renderer.removeClass(this.elementRef.nativeElement, this.previous);
        }
        const classToAdd = colorsMap[value] || 'grey-700';
        this.renderer.addClass(this.elementRef.nativeElement, classToAdd);
        this.previous = classToAdd;
    }
}
