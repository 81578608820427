import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Maybe, StorePermission, User } from 'app/shared/graph';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/dialog/confirm-dialog.component';
import { UserService } from './user.service';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ViewContextService } from '../../../navigation/view-context.service';
import { ConsolidateUserDialogComponent, ConsolidateUserDialogData, ConsolidateUserDialogResult } from './consolidate-user-dialog.component';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { MatDivider } from '@angular/material/divider';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './user-page.component.html',
    styleUrls: ['./user-page.component.scss'],
    imports: [PageSimpleContentComponent, MatTabGroup, MatTab, FusePerfectScrollbarDirective, MatButton, MatIcon, ErrorComponent, CopyToClipboardComponent, RouterLink, MatDivider, DatePipe]
})
export class UserPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly userService = inject(UserService);
    private readonly authService = inject(AuthorizationService);
    private readonly matDialog = inject(MatDialog);
    private readonly viewContextService = inject(ViewContextService);

    userId: string;
    user?: Maybe<User>;

    authContext: AuthorizationContext;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.userId = params.userId;
            this.subscribeWithGenericLoadingErrorHandling(this.userService.getUser(this.userId), (user) => {
                if (user) {
                    this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
                    this.user = user;
                    this.viewContextService.selectStore(user.storeId);
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    deleteUser(user: User) {
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirmation',
                message: `Do you really want to delete this user?`
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.userService.deleteUser({ id: user.id }), () => {
                this.subscribeWithGenericLoadingErrorHandling(this.userService.getUser(this.userId), (user) => {
                    this.user = user;
                });
            });
        });
    }

    canManageUser(user: User) {
        return this.authContext.hasStorePermissions(user.store.id, user.store.subscriptionId, StorePermission.ManageStoreUsers);
    }

    openConsolidateDialog(user: User) {
        let dialogRef = this.matDialog.open<ConsolidateUserDialogComponent, ConsolidateUserDialogData, ConsolidateUserDialogResult>(
            ConsolidateUserDialogComponent, {
                data: {
                    anonymousStoreUSerId: user.storeUserId
                }
            });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            this.subscribeWithGenericSavinErrorHandling(this.userService.consolidateUser({
                storeId: user.storeId,
                anonymousStoreUserId: user.storeUserId,
                identifiedStoreUserId: result.targetStoreUserId
            }), consolidatedUser => {
                this.router.navigate(['..', consolidatedUser.identifiedHubUserId], { relativeTo: this.route }).then();
            });
        });
    }
}
