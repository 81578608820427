import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthorizationContext, AuthorizationService } from 'app/shared/authentication';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Maybe, SharedProject, StorePermission } from 'app/shared/graph';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { ConfirmDialogService } from 'app/shared/dialog/confirm-dialog-service';
import { ViewContextService } from '../../../navigation/view-context.service';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CopyToClipboardComponent } from '../../../shared/utilities/copy-to-clipboard.component';
import { DatePipe } from '@angular/common';
import { SharedProjectsService } from './shared-projects.service';

@Component({
    templateUrl: './shared-project-page.component.html',
    styleUrls: ['./shared-project-page.component.scss'],
    imports: [PageSimpleContentComponent, ErrorComponent, MatButton, RouterLink, MatIcon, CopyToClipboardComponent, DatePipe]
})
export class SharedProjectPageContainerComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly authService = inject(AuthorizationService);
    private readonly sharedProjectsService = inject(SharedProjectsService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly viewContextService = inject(ViewContextService);

    sharedProjectId: string;

    sharedProject?: Maybe<SharedProject>;
    authContext?: AuthorizationContext;

    constructor() {
        super();
        this.sharedProjectId = this.route.snapshot.params.sharedProjectId;
    }

    ngOnInit(): void {
        this.loadSharedProject();
    }

    ngOnDestroy(): void {
        super.unsubscribeSubscriptions();
    }

    canReadProject(sharedProject: SharedProject) {
        return this.authContext?.hasStorePermissions(sharedProject.project.store.id, sharedProject.project.store.subscriptionId, StorePermission.ReadProjects);
    }

    canManageProject(sharedProject: SharedProject) {
        return this.authContext?.hasStorePermissions(sharedProject.project.store.id, sharedProject.project.store.subscriptionId, StorePermission.ManageProjects);
    }

    openRevokeSharing(sharedProject: SharedProject) {
        this.confirmDialogService.confirm(
            'Revoke project sharing',
            'Do you really want to revoke this project sharing?',
            undefined,
            {
                yes: {
                    label: 'Revoke',
                    warn: true
                },
                no: {
                    label: 'Cancel'
                }
            }).subscribe(() => {
            this.subscribeWithGenericSavinErrorHandling(this.sharedProjectsService.revokeSharedProject({ id: sharedProject.id }), () => {
                this.loadSharedProject();
            });
        });
    }


    private loadSharedProject() {
        this.subscribeWithGenericLoadingErrorHandling(this.sharedProjectsService.getSharedProject(this.sharedProjectId), (sharedProject) => {
            this.authService.authorizationContext().subscribe(authContext => this.authContext = authContext);
            this.sharedProject = sharedProject;
            if (sharedProject) {
                this.viewContextService.selectStore(sharedProject.storeId);
            }
        });
    }

    protected readonly Date = Date;

    hasExpired() {
        if (!this.sharedProject?.expiration)
            return;

        return Date.now() - Date.parse(this.sharedProject.expiration) > 0
    }
}
