@if (availableContexts.length > 10) {
    <mat-card>
        <mat-card-title> Select a store to configure</mat-card-title>
        <mat-card-content>
            <mat-form-field class="store-selector">
                <mat-label>Choose your store</mat-label>
                <input
                    type="text"
                    #selectedStoreId
                    aria-label="Select store"
                    [(ngModel)]="contextFilterText"
                    (change)="updateSelectedStore(selectedStoreId.value)"
                    (keydown)="updateFilter()"
                    matInput
                    [matAutocomplete]="storeAutocomplete"
                />
                <mat-autocomplete
                    #storeAutocomplete="matAutocomplete"
                    (optionSelected)="updateSelectedStore(selectedStoreId.value)"
                >
                    @for (context of filteredContexts; track context) {
                        <mat-option [value]="context.storeId">
                            <span class="store-info">
                                <span class="store-env" [ngClass]="['store-env-' + context.storeEnvironment]">
                                    {{ context.storeEnvironment | shortStoreEnv }}
                                </span>
                                <span class="store-display-name">{{ context.storeLabel }}</span>
                                <span class="store-id">{{ context.storeId }}</span>
                            </span>
                        </mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
            <button
                mat-button
                (click)="selectStore(selectedStore)"
                [disabled]="!selectedStore"
                class="select-button"
                color="primary"
            >
                SELECT
            </button>
        </mat-card-actions>
    </mat-card>
} @else {
    <div>
        <h2 class="mat-h2">Select a store to configure</h2>
        @for (store of filteredContexts; track store) {
            <admin-selectable-store-element [store]="store" (selectStore)="selectStore($event)">
            </admin-selectable-store-element>
        }
    </div>
}
