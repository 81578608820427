import { Injectable, inject } from '@angular/core';
import { CustomerSupportToken, GenerateCustomerSupportTokenInput, GraphDotnetService, MediaclipMutationGenerateCustomerSupportTokenArgs } from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HubTokenService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    generateCustomerSupportToken(
        input: GenerateCustomerSupportTokenInput
    ): Observable<CustomerSupportToken> {
        return this.graphDotnetService.mutate<MediaclipMutationGenerateCustomerSupportTokenArgs>(`
                mutation generateCustomerSupportToken($input: GenerateCustomerSupportTokenInput!) {
                    generateCustomerSupportToken(input: $input) {
                        token
                        scheme
                    }
                }
            `,
            { input }
        ).pipe(map((result) => result.generateCustomerSupportToken));
    }
}
