import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'app/shared/graph';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class StoreSecretsService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getStoreSecrets(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(`
                query getStoreSecrets($storeId: String!) {
                    store(id: $storeId) {
                        id
                        subscriptionId
                        secrets {
                            id
                            label
                        }
                    }
                }
            `,
            { storeId }
        ).pipe(map(queryResult => queryResult.store));
    }
}
